import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import langConstant from "_lang";
import PolicyDataTable from "./component/PolicyDataTable";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import PolicyServices from "./services";
import moment from "moment";
import sessionService from "_services/session.service";
// import PolicyUploadPopup from "./component/PolicyUploadPopup";
import ExecuteComparisonPopUp from "./component/ExecuteComparisonPopUp";
import PolicyUpload from "./component/NewPolicyUpload";
import { resetStepper } from "_components/Stepper/action";
import PolicyPreview from "./component/PolicyPreview";
import ChatBot from "_components/ChatBot";

const PolicyExplorer = () => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();

  const [newPolicyShow, setNewPolicyShow] = useState(false);
  const [comparisonPopUp, setComparisonPopup] = useState(false);
  const [policiesDetailToCompare, setPoliciesDetailToCompare] = useState([]);
  const [policyReRun, setPolicyReRun] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [policyPreview, setPolicyPreview] = useState({
    isOpen: false,
    data: {},
  });

  const selectedPolicies = useSelector(
    (state) => state.policyReducer.selectedPolicies
  );
  const customerID = useSelector(
    (state) => state.authentication.detailedInfo.CUSTOMER_ID
  );

  useEffect(() => {
    dispatch(resetStepper());
    if (userID) {
      dispatch(PolicyServices.getAvailablePolicies(userID));
    }
  }, [userID]);

  useEffect(() => {
    if (customerID) {
      dispatch(PolicyServices.getAllUserErrorMessages(customerID));
    }
  }, [customerID]);

  const columns = [
    { field: "POLICY_ID", headerName: "ID", minWidth: 50 },
    {
      field: "POLICY_NAME_WITH_INFO",
      headerName: langConstant.POLICY_NAME,
      minWidth: 50,
    },
    // {field: "POLICY_FILE_NAME_LINK", headerName: langConstant.POLICY_FILE_NAME, minWidth: 50},
    // {field: "NATIONAL_LANGUAGE", headerName: langConstant.LANGUAGE , minWidth: 50},
    // {field: "Extraction_Status_Icon", headerName:langConstant.EXTRACTION_STATUS, minWidth: 50},
    // {field: "DOCUMENT_OWNER_DATA", headerName: langConstant.DOCUMENT_OWNER, minWidth: 50},
    // {field: "DOCUMENT_TYPE_DATA", headerName: langConstant.DOCUMENT_TYPES, minWidth: 50},
    // {field: "ARCHIVED_STATUS", headerName: langConstant.ARCHIVED, minWidth: 50 },
    {
      field: "UPLOADED_BY",
      headerName: langConstant.UPLOADED_BY,
      minWidth: 50,
    },
    {
      field: "CREATE_DATETIME",
      headerName: langConstant.UPLOADED_DATE_TIME,
      minWidth: 50,
      format: (value) =>
        value ? moment(value).format("DD-MMM-YY h:mm A") : "",
    },
    {
      field: "ACTION",
      headerName: langConstant.ACTIONS,
      minWidth: 50,
      sticky: true,
    },
  ];

  const runComparison = () => {
    if (selectedPolicies.length === 2) {
      if (
        policiesDetailToCompare[0]["LANGUAGE_CODE"] ===
        policiesDetailToCompare[1]["LANGUAGE_CODE"]
      ) {
        setComparisonPopup(true);
      } else {
        dispatch(
          alertActions.error(
            langConstant.SOURCE_AND_TARGET_COMPARISON_LANGUAGE_NEED_TO_BE_THE_SAME
          )
        );
      }
    } else {
      if (selectedPolicies.length) {
        dispatch(alertActions.error(langConstant.PLEASE_SELECT_TARGET_POLICY));
      } else {
        dispatch(alertActions.error(langConstant.PLEASE_SELECT_TWO_POLICY));
      }
    }
  };

  return (
    <Box>
      {newPolicyShow ? (
        <PolicyUpload
          setShow={setNewPolicyShow}
          metaData={metaData}
          policyReRun={policyReRun}
          setPolicyReRun={setPolicyReRun}
        />
      ) : (
        <Grid container>
          <Grid
            item
            md={policyPreview.isOpen ? 9 : 12}
            xs={policyPreview.isOpen ? 6 : 12}
          >
            {/* <Grid container spacing={3}>
                            <Grid item xs={9}>
                                <h1>{langConstant.POLICY_EXPLORER}</h1>
                            </Grid>
                        </Grid> */}
            <PolicyDataTable
              columns={columns}
              selectedPolicies={selectedPolicies}
              userID={userID}
              setPoliciesDetailToCompare={setPoliciesDetailToCompare}
              policiesDetailToCompare={policiesDetailToCompare}
              runComparison={runComparison}
              setNewPolicyShow={setNewPolicyShow}
              setPolicyReRun={setPolicyReRun}
              setMetaData={setMetaData}
              setPolicyPreview={setPolicyPreview}
              policyPreview={policyPreview}
            />
            {comparisonPopUp && (
              <ExecuteComparisonPopUp
                show={comparisonPopUp}
                setShow={setComparisonPopup}
                userID={userID}
              />
            )}
          </Grid>
          {policyPreview.isOpen && (
            <Grid item md={3} xs={6} style={{ position: "relative" }}>
              <PolicyPreview
                policyPreview={policyPreview}
                setPolicyPreview={setPolicyPreview}
                userID={userID}
              />
            </Grid>
          )}
        </Grid>
      )}
      {/* <ChatBot entitlement="AI_ASSIST_PM_POLICY"/> */}
    </Box>
  );
};

export default PolicyExplorer;
