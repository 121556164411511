import React, { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useSelector } from "react-redux";
import { fetchFile } from "_helpers/utils";
import { Grid } from "@mui/material";

const DocumentViewer = ({
  height = "650px",
  documentClass,
  fileName,
  filePath,
  fileType,
}) => {
  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  const [docURL, setDocURL] = useState(null);

  useEffect(() => {
    (async () => {
      if (filePath)
        await fetchFile(
          filePath,
          fileName,
          docStorageType,
          docStorageIP,
          docStorageURL,
          setDocURL
        );
    })();
  }, [filePath]);

  return (
    <>
      {docURL && (
        <DocViewer
          style={{ minHeight: height, maxHeight: height }}
          documents={[{ uri: docURL, fileType: "pdf" }]}
          pluginRenderers={DocViewerRenderers}
          className={documentClass}
        />
      )}
    </>
  );
};

DocumentViewer.propTypes = {
  height: PropTypes.string,
  documentClass: PropTypes.object,
  fileName: PropTypes.string,
  filePath: PropTypes.string,
  fileType: PropTypes.string,
};

export default DocumentViewer;
