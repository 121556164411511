export const MasterAssessmentConstants = {
    GET_COMMENTS: "GET_COMMENTS",
    ERROR_COMMENTS: "ERROR_COMMENTS",
    GET_RFI_FILTER: "GET_RFI_FILTER",
    SET_COMMENT_LOADER: "SET_COMMENT_LOADER",
    GET_QUESTION_DATA_POINTS: "GET_QUESTION_DATA_POINTS",
    GET_MASTER_EVIDENCES: "GET_MASTER_EVIDENCES",
    ERROR_EVIDENCES: "ERROR_EVIDENCES",
    GET_MASTER_DATA_ASSETS: "GET_MASTER_DATA_ASSETS",
    ERROR_DATA_ASSETS: "ERROR_DATA_ASSETS",
    GET_RFI_DIALOG_EVIDENCES : "GET_RFI_DIALOG_EVIDENCES",
    GET_RFI_DIALOG_DATA_ASSETS : "GET_RFI_DIALOG_DATA_ASSETS",
    SET_ACTIONS_LIST:"SET_ACTIONS_LIST",
};

export const QUESTION_STATUS_TYPES = {
  OPEN:"OPEN",
  COMPLETED: "COMPLETED",
  DRAFT: "DRAFT",
  PENDING_DATA: "PENDING_DATA",
  READY_FOR_REVIEW: "READY_FOR_REVIEW",
  FINAL: "FINAL",
  COMPLETED_AND_LOCKED: "COMPLETED_AND_LOCKED",
  RFI_SENT: "RFI_SENT",
  RFI_PENDING: "RFI_PENDING",
  RFI_OVERDUE: "RFI_OVERDUE",
  RFI_RESOLVED: "RFI_RESOLVED",
  SENT_BACK: "SENT_BACK",
};