import React, { useState } from "react";
import { Drawer, Box, makeStyles, Button } from "@material-ui/core";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import PropTypes from "prop-types";
import AssessmentNavigator from "./components/AssessmentNavigator";

const useStyles = makeStyles(() => ({
  drawerPaper: {
    overflow: "visible",
  },
}));

const NavigatorDrawer = ({ QuestionList, DrawerItemClick }) => {
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  const setPageNumber = (pageNumber) => {
    setDrawerState(false);
    DrawerItemClick(pageNumber);
  };

  const findQuestionIndexById = (id) => {
    let index = QuestionList?.findIndex((i) => i?.QUESTION_ID == id);
    if (index !== -1) setPageNumber(index);
  };

  return (
    <Box>
      <Button
        onClick={toggleDrawer(true)}
        style={{
          position: "fixed",
          right: "0",
          top: "50vh",
          backgroundColor: "rgba(240, 20, 47, 1)",
          color: "white",
          borderRadius: "6px 0px 0px 6px",
          zIndex: "100",
          minWidth: "50px ",
        }}
      >
        <ExploreOutlinedIcon />
      </Button>
      <Drawer
        anchor={"right"}
        open={drawerState}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <AssessmentNavigator
          questionList={QuestionList}
          onClose={() => setDrawerState(false)}
          setPageNumber={(questionId) => {
            findQuestionIndexById(questionId);
          }}
          style={{ position: "relative", overflow: "visible" }}
        />
      </Drawer>
    </Box>
  );
};

NavigatorDrawer.propTypes = {
  QuestionList: PropTypes.array,
  DrawerItemClick: PropTypes.func,
};

export default NavigatorDrawer;
