import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  Grid,
  Box,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import moment from "moment";
import langConstant from "_lang";
import { getLevelColor } from "_helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import actions from "../actions";
import ProgressBarLarge from "_components/ProgressBarLarge";
import DialogBox from "_components/DialogBox";
import RfiFilterTable from "_components/RfiFilterTable";
import { alertActions } from "_actions";
import OpenCalendarPopup from "_components/OpenCalendarPopup/OpenCalendarPopup";
import sessionService from "_services/session.service";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import EvidenceLockerDialogBox from "../../UploadEvidences/components/EvidenceLockerDialog";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";
import { DashboardCustomize } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "3px 3px 10px rgba(0, 0, 0,0.2)",
    marginBottom: theme.spacing(2),
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      border: "2px solid transparent",
    },
  },
  panel: {
    borderTop: "1px solid #d6d6d6",
    padding: theme.spacing(3),
  },
  heading: {
    fontSize: 14,
    marginBottom: theme.spacing(1),
    color: "#8E8E8E",
  },
  content: {
    fontSize: 22,
    marginBottom: theme.spacing(2),
    fontWeight: "700",
  },
  linkActive: {
    color: "#fff",
    fontSize: "20px",
    cursor: "pointer",
  },
  linkDisable: {
    color: "#fff",
    fontSize: "20px",
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
}));
const AssessmentListItem = ({ data = {} }) => {
  const themeDetails = useSelector(getThemeDetails);
  const userID = sessionService.getUserId();
  const classes = useStyles();
  const dispatch = useDispatch();
  const rfiColumns = [
    {
      field: "REGULATORS_QUESTION_REF",
      headerName: langConstant.QUESTION_REF,
      minWidth: 135,
    },
    { field: "Status_label", headerName: langConstant.STATUS },
    {
      field: "RFI_DESCRIPTION",
      headerName: langConstant.COVER_NOTE,
      minWidth: 350,
    },
    {
      field: "RFI_DUE_DATE",
      headerName: langConstant.DUE_DATE,
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
      // format: (value) => (value ? value.substring(0, 10) : ""),
      minWidth: 120,
    },
  ];
  const initialDetail = {
    NO_OF_QUESTIONS: 0,
    RFI_SENT: 0,
    DRAFT: 0,
    RFI_PENDING: 0,
    COMPLETED: 0,
    RFI_OVERDUE: 0,
    RFI_LIST: [],
    percentage: 0,
  };
  const [detail, setDetail] = useState(initialDetail);
  const [open, setOpen] = useState(false);
  const [openRFIDialog, setOpenRFIDialog] = useState(false);
  const [EvidenceDialogData, setEvidenceDialogData] = useState({ open: false });
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);

  const onActionClick = (event) => {
    event?.preventDefault();
    // setSelectedRow(data);
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const getDetails = (e, expanded) => {
    if (expanded) {
      actions
        .getAssessmentById(data.SUBMISSION_ID, dispatch, userID)
        .then((response) => {
          if (response.data.length > 0) {
            const detail = { ...initialDetail };
            detail.NO_OF_QUESTIONS = response.data[0].ALL_QUESTION;
            detail.DRAFT = response.data[0].DRAFT;
            detail.COMPLETED = response.data[0].COMPLETED;
            detail.RFI_SENT = response.data[0].RFI_SENT;
            detail.RFI_PENDING = response.data[0].RFI_PENDING;
            detail.RFI_OVERDUE = response.data[0].RFI_OVERDUE;

            detail.RFI_LIST = response.RFI_LIST;
            detail.percentage =
              response.data[0].COMPLETED > 0
                ? Number(
                    (
                      (response.data[0].COMPLETED * 100) /
                      response.data[0].ALL_QUESTION
                    ).toFixed(2)
                  )
                : 0;
            setDetail(detail);
            setOpen(true);
          } else {
            let errorMessage = langConstant.NO_RECORD_FOUND;

            if (
              data.STATUS == "ASSIGNED" ||
              data.STATUS == "PENDING_ASSIGNMENT"
            ) {
              errorMessage = langConstant.NO_ASSESSMENT_IS_AVAILABLE;
            }
            dispatch(alertActions.error(errorMessage));
            setDetail(initialDetail);
          }
        });
    } else {
      setOpen(false);
    }
  };
  const toggleRFIDialog = (value) => {
    setOpenRFIDialog(value);
  };

  useEffect(() => {
    setOpen(false);
  }, [data]);

  const EvidenceLockerClickHandler = (row) => {
    setEvidenceDialogData({
      open: true,
      submissionId: row.SUBMISSION_ID,
      regulationId: row.REGULATION_ID,
    });
  };

  const handleDialogClose = () => {
    setEvidenceDialogData({ open: false });
  };

  const actionMenuItems = [
    {
      tooltipLabel: langConstant.VIEW,
      icon: VisibilityIcon,
      linkTo: {
        pathname: "/manage-assignments/" + data.SUBMISSION_ID,
        state: {
          regulationName: data.REGULATION_NAME,
          legalEntityName: data.LEGAL_ENTITY_NAME,
        },
      },
      iconProps: {
        className: classes.linkActive,
      },
    },
    {
      tooltipLabel: langConstant.CHECK_RFI,
      icon: DraftsOutlinedIcon,
      color: detail.RFI_LIST.length > 0 ? "#0e9439" : "#bcbcbc",
      isDisabled: !detail.RFI_LIST.length,
      onClick: () => detail.RFI_LIST.length > 0 && toggleRFIDialog(true),
      iconProps: {
        className:
          detail.RFI_LIST.length > 0 ? classes.linkActive : classes.linkDisable,
      },
    },
    {
      component: (
        <OpenCalendarPopup
          submissionId={data.SUBMISSION_ID}
          width="28px"
          height="28px"
        />
      ),
    },
    {
      tooltipLabel: "Re-assign",
      icon: AssignmentIcon,
      linkTo: "/manage-assignments/assign-assignments/" + data.SUBMISSION_ID,
      color: "#eaa327",
      iconProps: {
        className: classes.linkActive,
      },
    },
    {
      tooltipLabel: "Evidence Locker",
      onClick: () => EvidenceLockerClickHandler(data),
      icon: EnhancedEncryptionOutlinedIcon,
      color: "#C13636",
    },
    {
      tooltipLabel: "Dashboard",
      icon: DashboardCustomize,
      linkTo: `dashboard-view/${data?.SUBMISSION_ID}/${data?.REGULATION_ID}`,
      color: "orange",
    },
  ];

  return (
    <>
      <Accordion
        className={classes.root}
        expanded={open}
        TransitionProps={{ unmountOnExit: true }}
        onChange={(e, expanded) => {
          getDetails(e, expanded);
        }}
        style={{ borderColor: `rgb(${getLevelColor(data.PRIORITY)})` }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={langConstant.ASSESSMENT}
          onClick={() => {
            getDetails();
          }}
        >
          <Grid container alignItems="center">
            {" "}
            <Grid item md={2}>
              <Grid container alignItems="center">
                <Grid item>
                  <Avatar
                    style={{
                      color: `rgb(${getLevelColor(data.PRIORITY)})`,
                      backgroundColor: `rgba(${getLevelColor(
                        data.PRIORITY
                      )},0.17)`,
                      fontWeight: "600",
                    }}
                  >
                    {data.PRIORITY}
                  </Avatar>
                </Grid>
                <Grid
                  item
                  xs
                  zeroMinWidth
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                >
                  {data.LEGAL_ENTITY_NAME} - <br />
                  {data.REGULATION_NAME}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Box mr={3} style={{ maxWidth: "300px" }}>
                <Grid container alignItems="center" spacing={0}>
                  <Grid item md={2}>
                    <CalendarTodayIcon style={{ marginLeft: "5px" }} />
                  </Grid>
                  <Grid item md={4}>
                    {data.DATE_INITIATED
                      ? moment(data.DATE_INITIATED).format("DD-MMM-YY")
                      : "NA"}
                  </Grid>
                  <Grid item md={2} style={{ paddingLeft: "10px" }}>
                    <ArrowForwardIcon />
                  </Grid>
                  <Grid item md={4}>
                    {data.SUBMISSION_DATE
                      ? moment(data.SUBMISSION_DATE).format("DD-MMM-YY")
                      : "NA"}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={2}>
              {data.EFFORT_LOGGED}/{data.EFFORT_EXPECTED}{" "}
              <span style={{ paddingLeft: "10px" }}>
                {data.EFFORT_EXPECTED
                  ? "(" +
                    Number(
                      (
                        (data.EFFORT_LOGGED * 100) /
                        data.EFFORT_EXPECTED
                      ).toFixed(2)
                    ) +
                    "%)"
                  : "(0%)"}
              </span>
            </Grid>
            <Grid item md={2}>
              {data.END_DATE ? moment(data.END_DATE).format("DD-MMM-YY") : "NA"}
            </Grid>
            <Grid
              item
              md={1}
              style={{ maxWidth: "10.333333%", flexBasis: "10.333333%" }}
            >
              {data.END_DATE
                ? data.STATUS != "SUBMITTED_TO_REGULATOR"
                  ? moment(data.END_DATE).diff(moment().startOf("day"), "days")
                  : 0
                : "NA"}
            </Grid>
            <Grid
              item
              md={2}
              style={{ maxWidth: "14.666667%", flexBasis: "14.666667%" }}
            >
              {" "}
              <span
                className={`${data.STATUS} status-badge`}
                style={{ minWidth: "auto", maxWidth: "184px", width: " 100%" }}
              >
                {data.DISPLAY}
              </span>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.panel}>
          <Grid container spacing={1} align="stretch">
            <Grid item md={2}>
              <Typography className={classes.heading}>
                {langConstant.NO_OF_QUESTIONS}
              </Typography>
              <Typography className={classes.content}>
                {detail.NO_OF_QUESTIONS}
              </Typography>
              <Typography className={classes.heading}>
                {langConstant.RFI_SENT}
              </Typography>
              <Typography className={classes.content}>
                {detail.RFI_SENT}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.heading}>
                {langConstant.DRAFT}
              </Typography>
              <Typography className={classes.content}>
                {detail.DRAFT}
              </Typography>
              <Typography className={classes.heading}>
                {langConstant.RFI_PENDING}
              </Typography>
              <Typography className={classes.content}>
                {detail.RFI_PENDING}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.heading}>
                {langConstant.COMPLETED}
              </Typography>
              <Typography className={classes.content}>
                {detail.COMPLETED}
              </Typography>
              <Typography className={classes.heading}>
                {langConstant.RFI_OVERDUE}
              </Typography>
              <Typography className={classes.content}>
                {detail.RFI_OVERDUE}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography className={classes.heading}>
                {langConstant.PROGRESS}
              </Typography>
              <Box style={{ width: "200px", paddingBottom: 14 }}>
                <ProgressBarLarge
                  percentage={detail.percentage}
                  size={"small"}
                  color={detail.percentage ? "" : "red"}
                />
              </Box>

              <Typography className={classes.heading}>
                {langConstant.TARGET_COMPLETION_DATE}
              </Typography>
              <Typography className={classes.content}>
                {data.TARGET_COMPLETION_DATE
                  ? moment(data.TARGET_COMPLETION_DATE).format("DD-MMM-YY")
                  : "NA"}
              </Typography>
            </Grid>
            <Grid item md={2}>
              <Typography className={classes.heading}>
                {langConstant.ACTIONS}
              </Typography>
              <ActionIcon onClick={onActionClick} />
              {openMenu && (
                <ActionMenu
                  anchorMenuEl={anchorMenuEl}
                  onClose={onActionClick}
                  isOpen={openMenu}
                  menuItems={actionMenuItems}
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {EvidenceDialogData.open && (
        <EvidenceLockerDialogBox
          identifier={false}
          handleDialogClose={handleDialogClose}
          dialogOpen={EvidenceDialogData.open}
          submissionId={EvidenceDialogData?.submissionId}
          regulationId={EvidenceDialogData?.regulationId}
        />
      )}

      <DialogBox
        maxWidth={"lg"}
        title={langConstant.RFI_DETAILS}
        open={openRFIDialog}
        handleClose={() => toggleRFIDialog(false)}
      >
        <RfiFilterTable
          rows={detail.RFI_LIST}
          columns={rfiColumns}
          className="data-table"
        />
      </DialogBox>
    </>
  );
};
AssessmentListItem.propTypes = {
  data: PropTypes.object,
};

export default AssessmentListItem;
