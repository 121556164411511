import React, { useEffect, useState } from "react";
import { Typography, Box, makeStyles, Badge, Tooltip, DialogActions, Button, } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PropTypes from "prop-types";
import Divider from '@mui/material/Divider';
import rootStyles from "rootStyles";
import SendIcon from '@mui/icons-material/Send';
import langConstant from "_lang";
import ActionBar from "../component/ActionBar";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSlider from "../component/SliderGraph"
import { loadingActions } from "_actions";
import IconButton from "@material-ui/core/IconButton";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CommentSection from "_components/CommentSection";
import DialogBox from "_components/DialogBox";
import { setAuditQuestions } from "containers/cyber-assessment/Audits/action";
import AssessmentTabs from "../component/AssessmentTabs";
import EvidenceLockerDialogBox from "containers/cyber-assessment/UploadEvidences/components/EvidenceLockerDialog";
import { UpdateAuditorQuestionStatus, UpdateAuditorQuestionStatusToFinal, updateAssessmentStatus, updateAssignedStatus } from "../utils/actions"
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { postComment } from "../utils/actions"
import sessionService from "_services/session.service";
import Checkbox from '@mui/material/Checkbox';
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { confirmationDialogActions } from "_actions/confirmationDialog.action";
import { useLocation, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress'
import { resetStepper } from "_components/Stepper/action"
import { Fragment } from "react";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import BlockIcon from "@material-ui/icons/Block";
import { setReviewManagerQuestions } from "containers/cyber-assessment/ManageAssessments/NewReviewAssessment/utils/actions"
import commentsAction from "_components/CommentSection/actions";
import MailIcon from "@material-ui/icons/Mail";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LockIcon from "@material-ui/icons/Lock";
import RestorePageIcon from "@material-ui/icons/RestorePage";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import moment from "moment";
import AprroversDialog from "./components/ApproversDialog";


const useStyles = makeStyles(() => ({
  height100: {
    height: "100%"
  },
  width100: {
    width: "100%"
  },
  marginTop: {
    marginTop: "10px"
  },
  QuestionBox: {
    display: "flex",
    justifyContent: "space-between",
    height: "130px",
    width: "100%",
    marginTop: "5px"
  },
  questionBoxWidth: {
    width: "65%"
  },
  answerBoxWidth: {
    width: "34%"
  },
  customcard: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    borderWidth: "1px",
    padding: "5px"
  },
  borderBottom: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderWidth: "1px",
  },
  boxshadow: {
    padding: "5px",
    margin: "5px"
  },
  filedTitle: {
    color: "#707070",
    marginBottom: "5px",
    fontWeight: "500",
    padding: "0 5px",
  },
  iconContainers: {
    marginEnd: "15px"
  },
  statusicon: {
    fontSize: 20,
    marginRight: "5px",

    '&.COMPLETED': {
      color: "rgba(0, 159, 26, 1)",
    },

    '&.RFI_SENT': {
      color: "#105BA1",
    },

    '&.RFI_RESOLVED': {
      color: "#1a751a",
    },

    '&.RFI_PENDING': {
      color: "#895514",
    },

    'RFI_OVERDUE': {
      color: "#d62000",
    },

    '&.SENT_BACK': {
      color: "rgba(240, 20, 47, 1)",
    },

    '&.FINAL': {
      color: "rgba(0, 159, 26, 1)",
    },

    '&.PENDING_DATA': {
      color: "rgba(187, 174, 0, 1)",
    },

    '&.READY_FOR_REVIEW': {
      color: "rgba(1, 148, 25, 1)",
    },

    '&.COMPLETED_AND_LOCKED': {
      color: "rgba(0, 159, 26, 1)",
    },
  }
}));

const TopTabsArray = [
  { name: langConstant.ALL, count: 0, isCount: true, id: 0 },
  { name: langConstant.SENT_BACK, count: 0, isCount: true, id: 1 },
  { name: langConstant.REVIEWED, count: 0, isCount: true, id: 2 },
];

const ReviewAssessmentStep = ({ QuestionList, ReviewScreenStatus, observationList, trustScoreList, identifier, RfiitemsList }) => {
  const history = useHistory();
  const { sid, rid, id } = useParams();
  const classes = useStyles();
  const rootClasses = rootStyles();
  const userID = sessionService.getUserId();
  const dispatch = useDispatch();
  const location = useLocation();
  const [TotalQuestionList, setTotalQuestionList] = useState([]);
  const [flatFilteredList, setflatFilteredList] = useState([]);
  const [HeaderArray, setHeaderArray] = useState([]);
  const [IsPageLoading, setIsPageLoading] = useState(true);
  const [CommentDialogBox, setCommentDialogBox] = useState({ visible: false, data: null });
  const [EvidenceDialogBox, setEvidenceDialogBox] = useState({ visible: false, data: null });
  const [SendBackDialogBox, setSendBackDialogBox] = useState({ visible: false, data: null });
  const [RejectAssessmentDialogBox, setRejectAssessmentDialogBox] = useState({ visible: false, data: null });
  const [TopTabs, setTopTabs] = useState(TopTabsArray);
  const [TopSelectedTab, setTopSelectedTab] = useState(TopTabsArray[0]);
  const [filterBy, setFilterBy] = useState({ control: "", tabs: "All" });
  const [SelectedQuestionFinalList, setSelectedQuestionFinalList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [approversDialog, setApproversDialog] = useState(false)
  const TotalFinalStatusQuestions = [...QuestionList].filter((el) => el.QUESTION_STATUS === ReviewScreenStatus["Reviewed"]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const calculatePageCount = (rows) => {
    return rows.length > 0 ? Math.ceil(Math.min(rows.length / rowsPerPage)) : 0;
  };
  const [pageCount, setPageCount] = useState(calculatePageCount(TotalQuestionList));

  const MyassessmnetObj = useSelector((state) => state?.MyAssessmnetReducer?.resultSet2);

  const StatusTagObject = {
    RFI_SENT: { statusBackground: "rgba(123, 192, 255, 1)", statusTag: langConstant.RFI_SENT, icon: <MailIcon className={`${classes.statusicon} RFI_SENT`} /> },
    RFI_PENDING: { statusBackground: "rgba(255, 248, 156, 1)", statusTag: langConstant.RFI_PENDING, icon: <HelpOutlineIcon className={`${classes.statusicon} RFI_PENDING`} /> },
    RFI_OVERDUE: { statusBackground: "rgba(255, 188, 188, 1)", statusTag: langConstant.RFI_OVERDUE, icon: <ReportProblemIcon className={`${classes.statusicon} RFI_OVERDUE`} /> },
    RFI_RESOLVED: { statusBackground: "rgba(175, 243, 187, 0.8)", statusTag: langConstant.RFI_RESOLVED, icon: <CheckOutlinedIcon className={`${classes.statusicon} RFI_RESOLVED`} /> },
    SENT_BACK: { statusBackground: "rgba(255, 188, 188, 1)", statusTag: langConstant.SENT_BACK, icon: <ReplyAllIcon className={`${classes.statusicon} SENT_BACK`} /> },
    FINAL: { statusBackground: "rgba(175, 243, 187, 1)", statusTag: langConstant.FINAL, icon: <ThumbUpAltIcon className={`${classes.statusicon} FINAL`} /> },
    PENDING_DATA: { statusBackground: "rgba(255, 248, 156, 1)", statusTag: langConstant.PENDING_DATA, icon: <RestorePageIcon className={`${classes.statusicon} PENDING_DATA`} /> },
    READY_FOR_REVIEW: { statusBackground: "rgba(175, 243, 187, 0.7)", statusTag: langConstant.READY_FOR_REVIEW, icon: <FlashOnIcon className={`${classes.statusicon} READY_FOR_REVIEW`} /> },
    COMPLETED_AND_LOCKED: { statusBackground: "rgba(175, 243, 187, 1)", statusTag: langConstant.COMPLETED_AND_LOCKED, icon: <LockIcon className={`${classes.statusicon} COMPLETED_AND_LOCKED`} /> },
    COMPLETED: { statusBackground: "rgba(175, 243, 187, 1)", statusTag: langConstant.COMPLETED, icon: <LockIcon className={`${classes.statusicon} COMPLETED`} /> }
  }

  const validationSchema = Yup.object().shape({
    REMARKS: Yup.string().trim().nullable().required("Required"),
  });

  const pageSetter = (sortedData) => {
    const totalrows = calculatePageCount(sortedData)
    if (page + 1 > totalrows) {
      setPage(0);
    }
  }

  const setRefreshedData = async () => {
    return new Promise(resolve => {
      setTimeout(() => {
        const updatedData = filterFunction();
        setflatFilteredList(updatedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
        setPageCount(calculatePageCount(updatedData));
        resolve('resolved');
      }, 0)
    })

  }

  useEffect(() => {

    (async () => {
      setIsLoading(true);
      await setRefreshedData();
      setIsLoading(false);
    })();

  }, [page, filterBy]);

  useEffect(() => {

    (async () => {
      await setRefreshedData();
    })();

  }, [TotalQuestionList]);

  const tabsCountSetter = (QueData) => {

    let allCount = QueData.length;
    let sendBackCount = 0;
    let reviewedCount = 0;
    [...QueData].forEach((question) => {
      if (question.QUESTION_STATUS === ReviewScreenStatus["Sent Back"]) {
        sendBackCount = sendBackCount + 1;
      }

      if (question.QUESTION_STATUS === ReviewScreenStatus["Reviewed"]) {
        reviewedCount = reviewedCount + 1;
      }

    });

    let tempTabs = [
      { name: langConstant.ALL, count: allCount, isCount: true, id: 0 },
      { name: langConstant.SENT_BACK, count: sendBackCount, isCount: true, id: 1 },
      { name: langConstant.REVIEWED, count: reviewedCount, isCount: true, id: 2 },
    ];

    setTopTabs(tempTabs)

  }

  const filterFunction = () => {

    let data = [...TotalQuestionList];

    if (filterBy.control !== "") {
      data = data.filter((el) => el["CISO_CONTROL_NAME"] === filterBy.control);
      pageSetter(data);
      tabsCountSetter(data)
    }

    if (filterBy.control === "") {
      data = [...TotalQuestionList];
      pageSetter(data);
      tabsCountSetter(data);
    }

    if (filterBy.tabs === "Sent Back") {
      data = data.filter((el) => el["QUESTION_STATUS"] === ReviewScreenStatus["Sent Back"]);
      pageSetter(data)
    }

    if (filterBy.tabs === "Reviewed") {
      data = data.filter((el) => el["QUESTION_STATUS"] === ReviewScreenStatus["Reviewed"]);
      pageSetter(data)
    }

    return data
  }

  const DataSetter = async (queList) => {
    return await new Promise(resolve => {
      setTimeout(async () => {

        let allCount = queList.length;
        let sendBackCount = 0;
        let reviewedCount = 0

        const data = [...queList].map((question, index) => {
          let OBSERVATION;
          let TRUST_SCORE;
          let obs = [...observationList].find((observ) => question.QUESTION_ID === observ.QUESTION_ID);
          let tustscore = [...trustScoreList].find((trust) => question.QUESTION_ID === trust.QUESTION_ID);

          if (obs === undefined) {
            OBSERVATION = {}
          } else {
            OBSERVATION = obs
          }

          if (tustscore === undefined) {
            TRUST_SCORE = 0
          } else {
            TRUST_SCORE = tustscore.TRUST_SCORE
          }

          if (question.QUESTION_STATUS === ReviewScreenStatus["Sent Back"]) {
            sendBackCount = sendBackCount + 1;
          }

          if (question.QUESTION_STATUS === ReviewScreenStatus["Reviewed"]) {
            reviewedCount = reviewedCount + 1;
          }

          return { ...question, OBSERVATION: OBSERVATION, TRUST_SCORE: TRUST_SCORE }
        }).sort((a, b) => a.CISO_CONTROL_NAME?.localeCompare(b.CISO_CONTROL_NAME));
        const sortedControl = [...data].map((el) => el.CISO_CONTROL_NAME);
        const headerArr = [];
        const temparray = [];
        await data.forEach((que, index) => {
          let firstindex = sortedControl.indexOf(que.CISO_CONTROL_NAME);
          if (index === firstindex) {
            headerArr.push({ id: que.CISO_CONTROL_NAME + "-dataheader", label: que.CISO_CONTROL_NAME });
            temparray.push({ ...que, isHeader: true });
          } else {
            temparray.push({ ...que, isHeader: false });
          }
        });
        setTotalQuestionList(temparray);
        setHeaderArray(headerArr);

        let tempTabs = [
          { name: langConstant.ALL, count: allCount, isCount: true, id: 0 },
          { name: langConstant.SENT_BACK, count: sendBackCount, isCount: true, id: 1 },
          { name: langConstant.REVIEWED, count: reviewedCount, isCount: true, id: 2 },
        ];

        setTopTabs(tempTabs)
        resolve('resolved');
      }, 0);
    });

  }

  useEffect(() => {
    (async () => {
      dispatch(loadingActions.start());
      setIsLoading(true);
      await DataSetter(QuestionList);
      setIsLoading(false);
      dispatch(loadingActions.end());
      setIsPageLoading(false);
    })();
  }, []);

  const onChangePage = (value) => {
    setPage(value);
  };

  const handleCommentDialogClose = () => {
    setCommentDialogBox({ visible: false, data: null })
  }

  const handleEvidenceDialogClose = () => {
    setEvidenceDialogBox({ visible: false, data: null });
  }

  const handleSendBackDialogClose = () => {
    setSendBackDialogBox({ visible: false, data: null });
  }

  const handleRejectAssessmentDialogClose = () => {
    setRejectAssessmentDialogBox({ visible: false, data: null });
  }


  const UpdateCommentCount = async (question) => {

    const data = [...QuestionList].map((que) => {
      if (`${question.CISO_CONTROL_NAME}_${question.QUESTION_ID}_${question.REGULATORS_QUESTION_REF}` === `${que.CISO_CONTROL_NAME}_${que.QUESTION_ID}_${que.REGULATORS_QUESTION_REF}`) {
        return { ...que, NUM_OF_COMMENTS: que.NUM_OF_COMMENTS === null ? 1 : que.NUM_OF_COMMENTS + 1 }
      }
      return que
    });

    handleCommentDialogClose();

    if (identifier === "audit") {
      dispatch(setAuditQuestions(data));
    }

    if (identifier === "reviewmanager") {
      dispatch(setReviewManagerQuestions(data))
    }

    await DataSetter(data);

  }

  const setdata = (data) => {
    DataSetter(data);
  }

  const onMarkAsReviewed = () => {
    const selectedIDs = [...SelectedQuestionFinalList].map((el) => el.QUESTION_ID)
    const finalset = new Set([...selectedIDs]);
    const finalselectedIDs = [...finalset];

    let dataobj = {
      i_QUESTION_ID: finalselectedIDs.join(),
      i_SUBMISSION_ID: SelectedQuestionFinalList[0]?.SUBMISSION_ID,
      i_QUESTION_STATUS: ReviewScreenStatus["Reviewed"],
      i_USER_ID: userID
    }

    dispatch(UpdateAuditorQuestionStatusToFinal(dataobj, QuestionList, finalselectedIDs, setdata, identifier));
    setSelectedQuestionFinalList([]);
  }

  const QuestionStatusUpdater = (status, selectedQuestion, updateCommentCount, ScreenIdentifer) => {

    let dataobj = {
      i_QUESTION_ID: selectedQuestion?.QUESTION_ID,
      i_SUBMISSION_ID: selectedQuestion?.SUBMISSION_ID,
      i_QUESTION_STATUS: status,
      i_USER_ID: userID
    }
    dispatch(UpdateAuditorQuestionStatus(dataobj, QuestionList, selectedQuestion, setdata, updateCommentCount, ScreenIdentifer));
  }

  const OnSendBackConfirm = (values, { resetForm }) => {
    dispatch(postComment(SendBackDialogBox.data.QUESTION_ID, SendBackDialogBox.data.SUBMISSION_ID, userID, values.REMARKS, false));
    QuestionStatusUpdater(ReviewScreenStatus["Sent Back"], SendBackDialogBox.data, true, identifier);
    resetForm();
    handleSendBackDialogClose();
  }

  const onTopTabChange = (e, value) => {
    setTopSelectedTab(TopTabs[value]);
    setFilterBy((prev) => ({ ...prev, tabs: TopTabs[value].name }))
    setPage(0);
  }

  const OnrejectRedirectFunc = () => {
    history.push("/manage-assignments");
  }

  const OnRejectAssessmentConfirm = (values, { resetForm }) => {

    dispatch(commentsAction.postComment(null, id, userID, values.REMARKS));

    dispatch(updateAssessmentStatus(
      id,
      null,
      ReviewScreenStatus["Reject_status"],
      OnrejectRedirectFunc,
      langConstant.REJECT_ASSESSMENT_MESSAGE_FN(
        ReviewScreenStatus["Return_To_Name"], location?.state?.legalEntityName + " " + location?.state?.regulationName
      )
    ))
  }

  const GetRfiResolvedDate = (selectedQuestion) => {
    const currentRfi = RfiitemsList?.find((el) => el?.REGULATORS_QUESTION_REF === selectedQuestion?.REGULATORS_QUESTION_REF);
    if (currentRfi !== undefined) {
      let date =  moment(currentRfi?.RFI_RESOLVED_DATE?.slice[(0, 10)]).format("DD-MMM-YY");
      return date;
    } else {
      return (<></>)
    }
  }

  const ReviewToolbar = (selectedQuestion) => {
    return (
      <Box style={{ display: "flex", justifyContent: "space-around" }}>

        <Tooltip title="Remarks" placement="top">
          <Box className={classes.iconContainers}>
            <Badge badgeContent={selectedQuestion.NUM_OF_COMMENTS} color="secondary">
              <IconButton
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  fontSize: "22px",
                  color: "white",
                  backgroundColor: "#007BFF"
                }}
                onClick={() => { setCommentDialogBox({ visible: true, data: selectedQuestion }) }}
              >
                <QuestionAnswerIcon color="inherit" fontSize="inherit" />
              </IconButton>
            </Badge>
          </Box>
        </Tooltip>

        <Tooltip title={identifier === "myassessment" ? " View Evidence Locker" : "View Attachments"} placement="top">
          <Box className={classes.iconContainers}>
            <Badge badgeContent={selectedQuestion.NUM_OF_EVIDENCE} color="secondary">
              <IconButton
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  fontSize: "22px",
                  color: "white",
                  backgroundColor: identifier === "myassessment" ? "#ff0000" : "#007BFF"
                }}
                onClick={() => { setEvidenceDialogBox({ visible: true, data: selectedQuestion }) }}
              >
                {
                  identifier === "myassessment" && (<EnhancedEncryptionIcon color="inherit" fontSize="inherit" />)
                }

                {
                  identifier !== "myassessment" && (<AttachmentIcon color="inherit" fontSize="inherit" />)
                }
              </IconButton>
            </Badge>
          </Box>
        </Tooltip>

        {
          (identifier === "myassessment") && (selectedQuestion.QUESTION_STATUS != "DRAFT") && (

            <Box
              style={{
                padding: "10px 10px",
                borderRadius: "4px",
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
                backgroundColor: StatusTagObject[selectedQuestion.QUESTION_STATUS]?.statusBackground,
              }}
            >
              {StatusTagObject[selectedQuestion.QUESTION_STATUS]?.icon}
              <Typography variant="body2" style={{ color: "black" }}>
                {StatusTagObject[selectedQuestion.QUESTION_STATUS]?.statusTag.toUpperCase()}
              </Typography>
            </Box>
          )
        }

        {
          (identifier === "myassessment") && (selectedQuestion.QUESTION_STATUS === "RFI_RESOLVED") && (
            <Box style={{ display: "flex", alignItems: "center",marginLeft:"10px" }}>
              <Typography style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)", marginRight: "6px", }}>
                {langConstant.RESOLVED_ON}
              </Typography>
              <Typography style={{ fontSize: "14px", color: "rgba(0,0,0,0.9)" }}>
                {GetRfiResolvedDate(selectedQuestion)}
              </Typography>
            </Box>
          )
        }

        {
          identifier !== "myassessment" && (
            <Tooltip title="Send Back" placement="top">
              <Box className={classes.iconContainers}>
                <IconButton
                  style={{
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    fontSize: "22px",
                    color: "white",
                    backgroundColor: selectedQuestion.QUESTION_STATUS == ReviewScreenStatus["Sent Back"] ? "#B1B1B1" : "#ff0000"

                  }}
                  disabled={selectedQuestion.QUESTION_STATUS == ReviewScreenStatus["Sent Back"]}
                  onClick={() => { setSendBackDialogBox({ visible: true, data: { ...selectedQuestion, refId: selectedQuestion.REGULATORS_QUESTION_REF } }); }}
                >
                  <ReplyAllIcon color="inherit" fontSize="inherit" />
                </IconButton>
              </Box>
            </Tooltip>
          )
        }





      </Box>
    )
  }

  const scrolledContent = (data) => {
    return (
      <PerfectScrollbar >
        <div style={{ fontSize: "15px" }}>
          <div>{data}</div>
        </div>
      </PerfectScrollbar>
    )
  }

  const SelectAllFunc = (checked) => {
    if (checked) {
      setSelectedQuestionFinalList([...QuestionList]);
    } else {
      setSelectedQuestionFinalList([]);
    }
  }

  const SelectAllCheckerFunc = () => {

    if (SelectedQuestionFinalList.length === QuestionList.length) {
      return true;
    }

    return false;
  }

  const QuestionClickHandler = (currentQuestion) => {
    const data = [...SelectedQuestionFinalList];
    const index = data.findIndex((que) => `${que.CISO_CONTROL_NAME}_${que.QUESTION_ID}_${que.REGULATORS_QUESTION_REF}` === `${currentQuestion.CISO_CONTROL_NAME}_${currentQuestion.QUESTION_ID}_${currentQuestion.REGULATORS_QUESTION_REF}`);

    if (index > -1) {
      data.splice(index, 1);
      setSelectedQuestionFinalList(data);
    } else {
      data.push(currentQuestion);
      setSelectedQuestionFinalList(data);
    }

  }


  const customCardBox = (selectedQuestion, index) => {
    return (
      <Box key={selectedQuestion.REGULATORS_QUESTION_REF + "_" + index + "_" + "datakey" + "_" + selectedQuestion.QUESTION_ID} style={{ display: "flex", flexDirection: "column" }}>
        <Box className={classes.boxshadow} style={{ display: "flex", justifyContent: "space-between", padding: "5px 30px 5px 0px" }}>
          <Box style={{ width: "12%", display: "flex" }}>

            <Box>
              {
                identifier !== "myassessment" && (
                  <>
                    {selectedQuestion.QUESTION_STATUS === ReviewScreenStatus["Reviewed"] && <DoneAllIcon style={{ margin: "9px", color: "green" }} />}

                    {
                      selectedQuestion.QUESTION_STATUS !== ReviewScreenStatus["Reviewed"] && (
                        <Checkbox
                          checked={SelectedQuestionFinalList.findIndex((que) => `${que.CISO_CONTROL_NAME}_${que.QUESTION_ID}_${que.REGULATORS_QUESTION_REF}` === `${selectedQuestion.CISO_CONTROL_NAME}_${selectedQuestion.QUESTION_ID}_${selectedQuestion.REGULATORS_QUESTION_REF}`) > -1}
                          color="success"
                          onClick={() => { QuestionClickHandler(selectedQuestion) }}
                        />
                      )
                    }

                  </>
                )
              }

            </Box>

            <Box style={{ wordBreak: "break-word", marginLeft: identifier === "myassessment" ? "15px" : "" }}>
              <Typography className={classes.filedTitle} style={{ fontSize: "15px", marginTop: "10px" }}>
                {selectedQuestion.REGULATORS_QUESTION_REF}
              </Typography>
            </Box>

          </Box>
          <Box className={`${classes.customcard}`} style={{ width: "55%", height: "160px", }}>

            <Box style={{ height: "33%" }} className={classes.borderBottom}>
              {scrolledContent(selectedQuestion.QUESTION_TEXT)}
            </Box>
            <Box style={{ height: "66%" }}>
              {scrolledContent(selectedQuestion.CURRENT_ANSWER_SELECTED_RESPONSE ? selectedQuestion.CURRENT_ANSWER_SELECTED_RESPONSE : "No Answer")}
            </Box>

          </Box>
          <Box className={`${classes.customcard}`} style={{ width: "32%", height: "160px", }}>
            <Box style={{ height: "33%" }} className={classes.borderBottom} >
              {scrolledContent(selectedQuestion.OBSERVATION.EXPECTATIONS ? selectedQuestion.OBSERVATION.EXPECTATIONS : "No Observation")}
            </Box>
            <Box style={{ height: "33%" }} className={classes.borderBottom}>
              {scrolledContent(selectedQuestion.OBSERVATION.FINDINGS ? selectedQuestion.OBSERVATION.FINDINGS : "No Findings")}
            </Box>
            <Box style={{ height: "33%" }}>
              {scrolledContent(selectedQuestion.OBSERVATION.RECOMMENDATIONS ? selectedQuestion.OBSERVATION.RECOMMENDATIONS : "No Recommendations")}
            </Box>
          </Box>
        </Box>
        <Box style={{ height: "65px", padding: "0px 50px 0px 70px", display: "flex", justifyContent: "space-between", width: "91%", alignSelf: "flex-end" }}>
          <Box className={classes.marginTop}>
            {ReviewToolbar(selectedQuestion)}
          </Box>
          <Box style={{ width: "25%" }}>
            <Box style={{ height: "60px", display: "flex", alignItems: "flex-end", width: "100%", }}>
              <CustomizedSlider score={Math.round(selectedQuestion.TRUST_SCORE)} />
            </Box>
          </Box>
        </Box>
        <Divider variant="middle" />
      </Box>
    )
  }

  const CallBack = () => {
    history.push("/my-audits");
  }

  const updateAssessmentStatusHandler = () => {
    dispatch(updateAssessmentStatus(sid, rid, "AUDITOR_REVIEWED", CallBack, "The Assessmnet is sent for Review"));
    dispatch(resetStepper());
  };

  const rejectAssessmentDialogHandler = () => {
    setRejectAssessmentDialogBox({ visible: true, data: {} })
  }

  const ReviewManagerFinalHandler = () => {
    dispatch(updateAssessmentStatus(id, null, ReviewScreenStatus["Final_assessment_status"], OnrejectRedirectFunc, langConstant.ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY));
    dispatch(resetStepper());
  }

  const goToAssessment = () => {
    history.push("/my-assessments");
  };

  const MyAssessmentFinalHandler = () => {
    const assignedByUserID = MyassessmnetObj[0]?.USER_ID;
    dispatch(updateAssignedStatus(id, assignedByUserID, "PENDING_FOR_REVIEW", goToAssessment));
  }

  const ActionBarPrimaryBtnRenderer = () => {
    let PrimaryBtn;
    if (identifier === "audit") {
      PrimaryBtn = {
        btnicon: <SendIcon />,
        btntext: langConstant.SEND_FOR_REVIEW,
        onbtnclick: () => { updateAssessmentStatusHandler(); },
        btndisabled: (TotalFinalStatusQuestions.length !== QuestionList.length) && (QuestionList.length !== 0 || TotalFinalStatusQuestions.length !== 0)
      }
    }

    if (identifier === "reviewmanager") {
      PrimaryBtn = {
        btnicon: <SubdirectoryArrowRightIcon />,
        btntext: ReviewScreenStatus["Primary_btn_text"],
        // onbtnclick: () => { ReviewManagerFinalHandler(); },
        onbtnclick: () => {setApproversDialog(true)},
        btndisabled: (TotalFinalStatusQuestions.length !== QuestionList.length) && (QuestionList.length !== 0 || TotalFinalStatusQuestions.length !== 0)
        // btndisabled: false
      }
    }

    if (identifier === "myassessment") {
      PrimaryBtn = {
        btnicon: <SubdirectoryArrowRightIcon />,
        btntext: langConstant.SEND_FOR_REVIEW,
        onbtnclick: () => { MyAssessmentFinalHandler() },
        btndisabled: false
      }

    }
    return PrimaryBtn;
  }


  const isSecondaryActionBtnVisible = () => {
    if ((identifier === "audit") || (identifier === "myassessment")) {
      return false;
    }

    if (identifier === "reviewmanager") {
      return true;
    }

  }

  const ActionBarSecondaryBtnRenderer = () => {

    if ((identifier === "audit") || (identifier === "myassessment")) {
      return {}
    }

    if (identifier === "reviewmanager") {
      return {
        secbtnicon: <BlockIcon />,
        secbtntext: langConstant.REJECT_ASSESSMENT,
        seconbtnclick: () => { rejectAssessmentDialogHandler() },
        secbtndisabled: false,
        secbtnclasses: rootClasses.redButton
      }
    }

  }

  return (
    <Box>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "self-end" }}>
        <Box >
          {identifier !== "myassessment" && <AssessmentTabs selectedTab={TopSelectedTab} onChange={onTopTabChange} tabs={TopTabs} />}
        </Box>
        <Box style={{ width: "30%" }}>
          <Autocomplete
            size="small"
            className={`${classes.margintop}`}
            autoHighlight
            openOnFocus
            options={HeaderArray}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} placeholder="Search" />}
            onChange={(e, val) => {
              setPage(0);
              setTopSelectedTab(TopTabsArray[0]);
              if (val === null) {
                setFilterBy((prev) => ({ ...prev, control: "", tabs: "All" }));
              } else {
                setFilterBy((prev) => ({ ...prev, control: val.label }));

              }
            }}
            isOptionEqualToValue={(option, value) => option.label === value.label}
          />
        </Box>
      </Box>
      {
        ((TotalFinalStatusQuestions.length === QuestionList.length) && (QuestionList.length !== 0 || TotalFinalStatusQuestions.length !== 0)) || (TopSelectedTab.id !== 2) && (identifier !== "myassessment") && (
          <Box mb={2} mt={2} style={{ display: "flex", justifyContent: "space-between", }}>
            <Box style={{ width: "190px", display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={SelectAllCheckerFunc()}
                color="success"
                onClick={(e) => { SelectAllFunc(e.target.checked); }}
                disabled={(TotalFinalStatusQuestions.length === QuestionList.length) && (QuestionList.length !== 0 || TotalFinalStatusQuestions.length !== 0)}
              />
              <Typography>{langConstant.SELECT_ALL}</Typography>
            </Box>

            <Box>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                startIcon={<DoneAllIcon />}
                disabled={SelectedQuestionFinalList.length === 0}
                onClick={() => {
                  dispatch(
                    confirmationDialogActions.open({
                      message: langConstant.CONFIRM_REVIEWED_QUESTIONS_FN(),
                      onConfirm: onMarkAsReviewed,
                      title: "REVIEW QUESTION CONFIRMATION",
                      option1: langConstant.YES_PLEASE,
                      option2: langConstant.NO_THANKS,
                    })
                  );
                }}
              >
                {langConstant.MARK_AS_REVIEWED}
              </Button>
            </Box>

          </Box>

        )
      }
      <Box style={{ height: "75vh" }}>

        {
          IsPageLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
              <CircularProgress disableShrink={true} />
            </div>
          )
        }

        {
          !IsPageLoading && (
            <PerfectScrollbar >

              {
                isLoading === true && (
                  <div style={{ display: 'flex', justifyContent: 'center', height: "inherit", alignItems: "center" }}>
                    <CircularProgress disableShrink={true} />
                  </div>
                )
              }

              {
                isLoading !== true && (
                  <Box>
                    {
                      !flatFilteredList[0]?.isHeader && flatFilteredList.length !== 0 && (
                        <Box style={{ marginTop: "10px" }} key={`temp-1-dataheader`}>
                          <Typography className={rootClasses.catTitle} component="h2" >
                            {flatFilteredList[0]?.CISO_CONTROL_NAME}
                          </Typography>
                        </Box>

                      )
                    }

                    {flatFilteredList.map((ele, index) => {
                      if (ele.isHeader) {
                        return (
                          <Fragment key={`${ele.CISO_CONTROL_NAME}-dataheader-and-databox`}>
                            <Box style={{ marginTop: "10px" }} key={`${ele.CISO_CONTROL_NAME}-dataheader`}>
                              <Typography className={rootClasses.catTitle} component="h2" >
                                {ele.CISO_CONTROL_NAME}
                              </Typography>
                            </Box>
                            {customCardBox(ele, index)}
                          </Fragment>
                        );
                      } else {
                        return (customCardBox(ele, index));
                      }
                    })}
                  </Box>

                )
              }


            </PerfectScrollbar >

          )
        }

      </Box>
      {
        CommentDialogBox.visible && (
          <DialogBox
            title={langConstant.REMARKS_HISTORY.toUpperCase()}
            open={CommentDialogBox.visible}
            handleClose={handleCommentDialogClose}
            maxWidth={"md"}
          >
            <CommentSection
              direction={"column-reverse"}
              question_ID={CommentDialogBox.data.QUESTION_ID}
              submission_ID={CommentDialogBox.data.SUBMISSION_ID}
              remarksCount={() => UpdateCommentCount(CommentDialogBox.data)}
            />

          </DialogBox>

        )
      }
      {
        SendBackDialogBox.visible && (
          <DialogBox
            title={langConstant.SEND_BACK_QUESTION_CONFIRMATION}
            open={SendBackDialogBox.visible}
            handleClose={handleSendBackDialogClose}
            maxWidth={"sm"}
          >
            <Typography
              align="center"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: langConstant.CONFIRM_SENT_BACK_FN(SendBackDialogBox.data),
              }}
            ></Typography>

            <Formik
              enableReinitialize={true}
              initialValues={{ REMARKS: "" }}
              validationSchema={validationSchema}
              onSubmit={OnSendBackConfirm}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <Form>

                  <Box mt={2}>

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant={"body2"}>
                        {langConstant.ADD_REMARKS} <span>*</span>
                      </Typography>

                      <ErrorMessage name="REMARKS">
                        {(msg) => (
                          <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                        )}
                      </ErrorMessage>
                    </Box>

                    <Field name="REMARKS">
                      {({ field: { value } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          value={value}
                          onChange={(e) => setFieldValue("REMARKS", e.target.value)}
                          error={touched.REMARKS && Boolean(errors.REMARKS)}
                        />
                      )}
                    </Field>

                  </Box>
                  <Box align="center">
                    <DialogActions style={{ justifyContent: "center" }}>
                      <Button
                        color="primary"
                        size="medium"
                        autoFocus
                        type="submit"
                      >
                        {langConstant.YES_PLEASE}
                      </Button>
                      <Button
                        onClick={handleSendBackDialogClose}
                        color="primary"
                        size="medium"
                      >
                        {langConstant.NO_THANKS}
                      </Button>
                    </DialogActions>
                  </Box>
                </Form>
              )}
            </Formik>
          </DialogBox>

        )
      }
      { EvidenceDialogBox.visible &&
        <EvidenceLockerDialogBox
          identifier={true}
          handleDialogClose={handleEvidenceDialogClose}
          dialogOpen={EvidenceDialogBox.visible}
          submissionId={EvidenceDialogBox.data?.SUBMISSION_ID}
          questionId={EvidenceDialogBox.data?.QUESTION_ID}
        />
      }
      {
        identifier === "reviewmanager" && RejectAssessmentDialogBox.visible && (
          <DialogBox
            title={langConstant.REJECT_ASSESSMENT_CONFIRMATION}
            open={RejectAssessmentDialogBox.visible}
            handleClose={handleRejectAssessmentDialogClose}
            maxWidth={"sm"}
          >
            <Typography
              align="center"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: langConstant.CONFIRM_REJECT_ASSESSMENT_FN(location?.state?.legalEntityName +
                  " " +
                  location?.state?.regulationName
                ),
              }}
            ></Typography>
            <Formik
              enableReinitialize={true}
              initialValues={{ REMARKS: "" }}
              validationSchema={validationSchema}
              onSubmit={OnRejectAssessmentConfirm}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <Form>
                  <Box mt={2}>

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography variant={"body2"}>
                        {langConstant.ADD_REMARKS} <span>*</span>
                      </Typography>

                      <ErrorMessage name="REMARKS">
                        {(msg) => (
                          <Typography style={{ color: "red", fontSize: "12px" }}>{msg}</Typography>
                        )}
                      </ErrorMessage>
                    </Box>

                    <Field name="REMARKS">
                      {({ field: { value } }) => (
                        <TextField
                          size="small"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          value={value}
                          onChange={(e) => setFieldValue("REMARKS", e.target.value)}
                          error={touched.REMARKS && Boolean(errors.REMARKS)}
                        />
                      )}
                    </Field>

                  </Box>
                  <Box align="center">
                    <DialogActions style={{ justifyContent: "center" }}>
                      <Button
                        color="primary"
                        size="medium"
                        autoFocus
                        type="submit"
                      >
                        {langConstant.YES_PLEASE}
                      </Button>
                      <Button
                        onClick={handleRejectAssessmentDialogClose}
                        color="primary"
                        size="medium"
                      >
                        {langConstant.NO_THANKS}
                      </Button>
                    </DialogActions>
                  </Box>


                </Form>
              )}
            </Formik>
          </DialogBox>
        )
      }
      <ActionBar
        onChangePage={onChangePage}
        page={page}
        pageCount={pageCount}
        isPagination={true}
        PrimaryBtn={ActionBarPrimaryBtnRenderer()}
        isSecondaryBtn={isSecondaryActionBtnVisible()}
        SecondaryBtn={ActionBarSecondaryBtnRenderer()}
      />
      {approversDialog && 
        <AprroversDialog 
          TotalFinalStatusQuestions={TotalFinalStatusQuestions}
        />
      }
    </Box>
  )
}

ReviewAssessmentStep.propTypes = {
  QuestionList: PropTypes.array,
  ReviewScreenStatus: PropTypes.object,
  observationList: PropTypes.array,
  trustScoreList: PropTypes.array,
  identifier: PropTypes.string,
  RfiitemsList: PropTypes.array
};

export default ReviewAssessmentStep

