import {
  Button,
  Box,
  Grid,
  Paper,
  Table,
  Dialog,
  DialogContent,
  DialogTitle,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { Form, Formik, FieldArray } from "formik";
import ProgressBarLarge from "_components/ProgressBarLarge";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "_hooks";
import langConstant from "_lang";
import { useDispatch } from "react-redux";
import { UseModalPropType } from "_hooks";
import { scrollToTop } from "_helpers/utils";
import SelectPageRange from "_components/SelectPageRange";
import AppPagination from "_components/AppPagination";
import CreateMessage from "./CreateMessageDialog";
import Confirmation from "./ConfirmationDialog";
import UserListDialog from "./UserListDialog";
import { BorderRight } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  title: { display: "flex", alignItems: "center", padding: ".75rem" },
  error: { color: "red", fontSize: "12px" },

  wideColumn: {
    width: "60%",
  },
  normalColumn: {
    width: "30%",
  },
  regColumn: {
    width: "20%",
  },
}));
const CustomerListForAdmin = ({ modal }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [progressView, setProgressView] = useState(false);
  const [disablePage, setDisablePage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [failedMessageUser, setFailedMessageUser] = useState(null);
  const [failedEmailUser, setFailedEmailUser] = useState(null);
  const parsedCustomerList = modal.data.uniqueCustomerList
    ? modal.data.uniqueCustomerList
    : [];
  const customerList = modal.data.customerListForAdmin
    ? modal.data.customerListForAdmin
    : [];
  const userLists = modal.data.uniqueCustomerUserList
    ? modal.data.uniqueCustomerUserList
    : [];

  const createMessageModal = useModal();
  const userListModal = useModal();
  const confirmationModal = useModal();

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [percentage, setPercentage] = useState(0);

  const onOpenCreateMessageDialog = () => {
    setProgressView(false);
    setDisablePage(true);
    setPercentage(0);
    setFailedMessageUser(null);
    setFailedEmailUser(null);

    if (selectedCustomers.length > 0) {
      const selectedCustomerNames = new Set(
        selectedCustomers.map((customer) => customer.name)
      );

      const filteredUser = customerList.filter((customer) =>
        selectedCustomerNames.has(customer.CUSTOMER_NAME)
      );
      createMessageModal?.setData({
        filteredUser,
        setProgressView,
        setDisablePage,
        setPercentage,
        setFailedMessageUser,
        setFailedEmailUser,
      });
      createMessageModal?.openModal();
    } else {
      setDisablePage(false);
      onOpenConfirmationDialog();
    }
  };
  const onOpenConfirmationDialog = () => {
    const message = langConstant.SELECT_CUSTOMER_FOR_NOTIFICATION;
    confirmationModal?.setData({
      message,
      selectedCustomers,
    });
    confirmationModal?.openModal();
  };

  const onOpenUserListDialog = (name) => {
    const users = userLists[name];
    users.sort((a, b) => {
      if (a.FIRST_NAME < b.FIRST_NAME) return -1;
      if (a.FIRST_NAME > b.FIRST_NAME) return 1;
      if (a.LAST_NAME < b.LAST_NAME) return -1;
      if (a.LAST_NAME > b.LAST_NAME) return 1;
      return 0;
    });

    userListModal?.setData({
      users,
    });
    userListModal?.openModal();
  };

  const calculatePageCount = (rows) => {
    return rows?.length > 0
      ? Math.round(Math.ceil(rows?.length / rowsPerPage))
      : 0;
  };

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value);
    const totalPageCount =
      parsedCustomerList.length > 0
        ? Math.round(Math.ceil(parsedCustomerList.length / range.value))
        : 0;
    if (page >= totalPageCount) {
      setPage(totalPageCount - 1);
    }
    setPageCount(totalPageCount);
    scrollToTop();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (values) => {
    onOpenCreateMessageDialog();
  };

  useEffect(() => {
    setPageCount(calculatePageCount(parsedCustomerList));
    setProgressView(false);
    setDisablePage(false);
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={modal?.isOpen}
        maxWidth="lg"
        disableEscapeKeyDown
        disableEnforceFocus
        keepMounted
        onClose={modal?.closeModal}
      >
        <DialogTitle className={classes.title}>
          <IconButton
            style={{ position: "absolute", right: 4 }}
            aria-label="close"
            onClick={modal?.closeModal}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{ rows: parsedCustomerList }}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({ values, errors, setFieldValue, handleChange }) => (
              <Form>
                <Grid>
                  <Typography style={{ flex: 1 }} variant="h6" align="center">
                    {langConstant.LIST_OF_ALL_CLIENTS_ON_ENVIRONMENT}
                  </Typography>
                </Grid>

                <Box mt={2} display="flex" justifyContent="left">
                  <Grid
                    container
                    style={{ display: "flex", justifyContent: "left" }}
                  >
                    <Grid>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={disablePage}
                        style={{ marginRight: 10, marginLeft: 10 }}
                      >
                        {langConstant.CREATE_MESSAGE}{" "}
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          const updatedSelectedCustomers = values.rows.map(
                            (item, index) => ({
                              name: item.CUSTOMER_NAME,
                              index: `${index}`,
                            })
                          );
                          setSelectedCustomers(updatedSelectedCustomers);

                          setFieldValue(
                            "rows",
                            values.rows.map((row) => ({
                              ...row,
                              CUSTOMER_SELECTED: true,
                            }))
                          );
                        }}
                        style={{ marginRight: 10 }}
                        disabled={disablePage}
                      >
                        {langConstant.SELECT_ALL}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setSelectedCustomers([]);
                        }}
                        variant="contained"
                        color="primary"
                        disabled={disablePage}
                      >
                        {langConstant.RESET}{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>

                <Grid
                  container
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box
                    my={2}
                    style={{
                      marginRight: progressView ? 20 : "",
                      paddingRight: progressView ? 20 : "",
                      borderRight: progressView ? "5px solid #dedede" : "",
                    }}
                  >
                    <Grid>
                      <TableContainer
                        component={Paper}
                        style={{ marginTop: 5 }}
                      >
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {langConstant.CUSTOMER_NAME}
                              </TableCell>
                              <TableCell>{langConstant.ACTIVE_USERS}</TableCell>
                              <TableCell>Select</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <FieldArray name="rows">
                              {({ insert, remove, push }) => (
                                <>
                                  {values.rows.length > 0 &&
                                    (rowsPerPage > 0
                                      ? values.rows.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                        )
                                      : values.rows
                                    ).map((row, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          className={classes.wideColumn}
                                          style={{
                                            whiteSpace: "normal",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <TextField
                                            className={classes.textField}
                                            name={`rows.${index}.CUSTOMER_NAME`}
                                            size="small"
                                            variant="outlined"
                                            disabled
                                            fullWidth
                                            value={row.CUSTOMER_NAME}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.match(
                                                  /^[a-zA-Z0-9\s.'"]*$/
                                                )
                                              ) {
                                                handleChange(e);
                                              }
                                            }}
                                            onPaste={(e) => e.preventDefault()}
                                            inputProps={{
                                              style: {
                                                whiteSpace: "normal",
                                                wordBreak: "break-all",
                                              },
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            whiteSpace: "normal",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          <TextField
                                            className={classes.textField}
                                            name={`rows.${index}.COUNT`}
                                            size="small"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            value={row.COUNT}
                                            onChange={(e) => {
                                              if (
                                                e.target.value.match(
                                                  /^[a-zA-Z0-9\s.'"]*$/
                                                )
                                              ) {
                                                handleChange(e);
                                              }
                                            }}
                                            onPaste={(e) => e.preventDefault()}
                                            inputProps={{
                                              style: {
                                                whiteSpace: "normal",
                                                wordBreak: "break-all",
                                              },
                                            }}
                                            multiline
                                          />
                                          <Button
                                            type="button"
                                            onClick={() => {
                                              onOpenUserListDialog(
                                                row.CUSTOMER_NAME
                                              );
                                            }}
                                            style={{
                                              marginLeft: "2px",
                                            }}
                                            variant="contained"
                                            color="primary"
                                            disabled={disablePage}
                                          >
                                            {langConstant.VIEW}
                                          </Button>
                                        </TableCell>

                                        <TableCell>
                                          <Checkbox
                                            name={`rows.${index}.CUSTOMER_SELECTED`}
                                            disabled={disablePage}
                                            checked={
                                              selectedCustomers.findIndex(
                                                (item) =>
                                                  item.index === `${index}`
                                              ) > -1
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `rows.${index}.CUSTOMER_SELECTED`,
                                                e.target.checked
                                              );

                                              if (e.target.checked) {
                                                const tempData = {
                                                  name: row.CUSTOMER_NAME,
                                                  index: `${index}`,
                                                };
                                                setSelectedCustomers(
                                                  (prevUsers) => [
                                                    ...prevUsers,
                                                    tempData,
                                                  ]
                                                );
                                              } else {
                                                setSelectedCustomers(
                                                  (prevUsers) =>
                                                    prevUsers.filter(
                                                      (item) =>
                                                        item.index !==
                                                        `${index}`
                                                    )
                                                );
                                              }
                                            }}
                                            sx={{
                                              "&.Mui-checked": {
                                                color: "#124571",
                                              },
                                              display: "inline",
                                            }}
                                            size="small"
                                          />
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </>
                              )}
                            </FieldArray>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Box my={2}>
                      <Grid
                        container
                        style={{ justifyContent: "right" }}
                        justify="right"
                      >
                        {parsedCustomerList?.length > 0 && !disablePage && (
                          <SelectPageRange
                            selectedRange={rowsPerPage}
                            handleChange={handleSelectPageRange}
                          />
                        )}
                        {pageCount > 0 && !disablePage && (
                          <AppPagination
                            colSpan={3}
                            count={pageCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        )}
                      </Grid>
                    </Box>
                  </Box>

                  <Grid style={{ marginTop: 5, justifyContent: "left" }}>
                    {progressView && (
                      <Grid style={{ justifyContent: "left" }}>
                        <Typography
                          style={{ color: "grey", fontWeight: "bold" }}
                          variant="h6"
                          align="center"
                        >
                          Progress_Logs
                        </Typography>
                      </Grid>
                    )}
                    {progressView && (
                      <>
                        <ProgressBarLarge
                          percentage={parseInt(percentage.toFixed(2))}
                        />

                        {failedMessageUser &&
                          failedMessageUser.map((item, index) => (
                            <Typography
                              key={index}
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              {`IN APP:  CUSTOMER ${item.CUSTOMER_NAME}'s  USER ${item.FIRST_NAME} ${item.LAST_NAME} notification has failed.`}
                            </Typography>
                          ))}
                        {failedEmailUser &&
                          failedEmailUser.map((item, index) => (
                            <Typography
                              key={index}
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginTop: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              {`IN EMAIL:  CUSTOMER ${item.CUSTOMER_NAME}'s  USER ${item.FIRST_NAME} ${item.LAST_NAME} notification has failed.`}
                            </Typography>
                          ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      {createMessageModal?.isOpen && (
        <CreateMessage modal={createMessageModal} />
      )}
      {confirmationModal?.isOpen && <Confirmation modal={confirmationModal} />}
      {userListModal?.isOpen && <UserListDialog modal={userListModal} />}
    </React.Fragment>
  );
};

CustomerListForAdmin.propTypes = {
  modal: UseModalPropType.isRequired,
};

export default CustomerListForAdmin;
