import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import propTypes from "prop-types";
import {
  ChangeCircle,
  Circle,
  QuestionAnswer,
  StackedBarChart,
  Tune,
} from "@mui/icons-material";
import { getQuestionBackground } from "../utils";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";
import QuestionSummaryGraphView from "./Graphs/QuestionSummaryGraphView";
import Select from "react-select";
import NoDataCard from "./Graphs/NoDataCard";
import { groupBy, uniqBy } from "lodash";

export const QuestionView = ({
  questionList,
  filterOptions,
  regulatorsList,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const [filteredGroups, setFilteredGroups] = useState(regulatorsList);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [filterQuestions, setFilterQuestion] = useState(questionList);
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [isQuestionGraphView, setIsQuestionGraphView] = useState(false);
  const ViewChangeIcon = isQuestionGraphView ? ChangeCircle : StackedBarChart;
  const isFilterOpen = Boolean(filterAnchorEl);
  const groupedRegulators = uniqBy(regulatorsList, "REGULATORS_CONTROL_GROUP");

  const onFilterToggle = (e) => {
    setFilterAnchorEl(e?.currentTarget || null);
  };

  const onClearAll = () => {
    onStatusFilterChange(null, true);
    onFilterToggle();
  };

  const onStatusFilterChange = (item, clearAll) => {
    // Extracting refs
    const refs = selectedGroup?.REGULATORS_QUESTION_REF?.split(",")?.map(
      (ref) => ref?.trim()
    );

    // Filtering questions based on regulators' references
    const questionWithOnlyRegFilter = questionList.filter((q) =>
      refs?.includes(q.REGULATORS_QUESTION_REF)
    );

    if (clearAll) {
      // Resetting filters and questions
      setFilterQuestion(
        selectedGroup ? questionWithOnlyRegFilter : questionList
      );
      setSelectedFilters(null);
      return;
    }

    // Checking if the item is already selected
    const isSelected = selectedFilters?.find((i) => i?.value === item?.value);

    // Updating selected filters
    let updatedFilters = isSelected
      ? selectedFilters?.filter((i) => i?.value !== item?.value)
      : [...(selectedFilters || []), item];

    setSelectedFilters(updatedFilters);

    // Filtering questions based on selected filters
    const filteredQuestions = questionList?.filter((i) => {
      const matchFilter = selectedGroup
        ? updatedFilters?.some(
            (r) =>
              r?.value === i?.QUESTION_STATUS ||
              r?.payload?.QUESTION_ID === i?.QUESTION_ID
          ) && refs?.includes(i?.REGULATORS_QUESTION_REF)
        : updatedFilters?.some(
            (r) =>
              r?.value === i?.QUESTION_STATUS ||
              r?.payload?.QUESTION_ID === i?.QUESTION_ID
          );
      return matchFilter;
    });

    // Updating filtered questions
    setFilterQuestion(
      updatedFilters?.length
        ? filteredQuestions
        : selectedGroup
        ? questionWithOnlyRegFilter
        : questionList
    );
  };

  const onRegulatorGroupChange = (e) => {
    setSelectedGroup(e);
    const refs = e?.REGULATORS_QUESTION_REF?.split(",")?.map((ref) =>
        ref?.trim()
      ),
      statusList = selectedFilters?.map((i) => i?.value?.trim());
    if (e) {
      setFilteredGroups(
        regulatorsList?.filter(
          (i) => i?.REGULATORS_CONTROL_GROUP === e?.REGULATORS_CONTROL_GROUP
        )
      );
      setFilterQuestion(
        questionList?.filter((question) =>
          statusList?.length
            ? refs?.includes(question?.REGULATORS_QUESTION_REF.trim()) &&
              statusList?.includes(question?.QUESTION_STATUS.trim())
            : refs?.includes(question?.REGULATORS_QUESTION_REF.trim())
        )
      );
    } else {
      setFilteredGroups(regulatorsList);
      setFilterQuestion(
        statusList?.length
          ? questionList?.filter((q) =>
              statusList?.includes(q?.QUESTION_STATUS)
            )
          : questionList
      );
    }
  };

  const formatOptionLabel = (option) => (
    <div style={{ display: "inline-flex", gap: 2, alignItems: "center" }}>
      <span>{option?.REGULATORS_CONTROL_GROUP}</span>
      <Typography fontSize={10}>
        ({option?.QUESTION_STATUS} - {option?.QUESTION_COUNT})
      </Typography>
    </div>
  );

  useEffect(() => {
    setFilteredGroups(regulatorsList);
    setFilterQuestion(questionList);
  }, [regulatorsList, questionList]);

  return (
    <React.Fragment>
      <Menu
        anchorEl={filterAnchorEl}
        open={isFilterOpen}
        onClose={() => onFilterToggle()}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          disabled={Boolean(!selectedFilters?.length)}
          onClick={onClearAll}
          sx={{ py: 0 }}
        >
          <Typography
            sx={{
              display: "inline-flex",
              color: "red",
              fontSize: 12,
              py: 0.75,
            }}
          >
            Clear All
          </Typography>
        </MenuItem>
        <Divider flexItem />
        {filterOptions?.map((item, index) => {
          return (
            <MenuItem
              onClick={() => onStatusFilterChange(item)}
              key={index}
              sx={{ py: 0.25 }}
            >
              <Typography
                sx={{
                  display: "inline-flex",
                  fontWeight: "bold",
                  fontSize: "12px",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                {item?.label} &nbsp;
                <Circle
                  sx={{
                    color: getQuestionBackground(item?.value),
                    fontSize: 12,
                  }}
                />
              </Typography>
              <Checkbox
                id={item?.value}
                checked={Boolean(
                  selectedFilters?.some((i) => i?.value === item?.value)
                )}
                sx={{
                  "&.Mui-checked": {
                    color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
                  },
                  "& .MuiSvgIcon-root": { fontSize: 20 },
                }}
              />
            </MenuItem>
          );
        })}
      </Menu>

      <Box
        display="flex"
        flex={0.6}
        p={1}
        flexDirection="column"
        borderRadius="4px"
        gap={1}
        bgcolor="white"
        alignSelf="stretch"
      >
        <Box display="flex" gap={2}>
          <Typography fontWeight="500" variant="subtitle1">
            Questions &nbsp;
            <Tooltip title="Apply Filters">
              <IconButton
                disabled={Boolean(!questionList?.length || isQuestionGraphView)}
                onClick={onFilterToggle}
                component="span"
              >
                {selectedFilters?.length > 0 && (
                  <Circle
                    sx={{
                      fontSize: 12,
                      position: "absolute",
                      top: 8,
                      right: 8,
                      color: "red",
                    }}
                  />
                )}
                <Tune />
              </IconButton>
            </Tooltip>
            <Tooltip title={isQuestionGraphView ? "Normal View" : "Graph View"}>
              <IconButton
                onClick={() => setIsQuestionGraphView(!isQuestionGraphView)}
              >
                <ViewChangeIcon />
              </IconButton>
            </Tooltip>
          </Typography>
          <Box width="16rem">
            <Select
              getOptionLabel={(option) => option?.REGULATORS_CONTROL_GROUP}
              getOptionValue={(option) => option?.REGULATORS_CONTROL_GROUP}
              formatOptionLabel={formatOptionLabel}
              placeholder="Select Regulator Group"
              options={groupedRegulators}
              isLoading={!groupedRegulators?.length}
              value={selectedGroup}
              onChange={onRegulatorGroupChange}
              isDisabled={Boolean(!regulatorsList?.length)}
              isSearchable
              isClearable
            />
          </Box>
        </Box>
        <>
          {isQuestionGraphView ? (
            <QuestionSummaryGraphView regulatorsList={filteredGroups} />
          ) : (
            <Box
              display="flex"
              overflow="auto"
              alignSelf="stretch"
              flexWrap="wrap"
              gap={1}
              p={1}
              border="1px solid #F0F4F8"
              borderRadius="4px"
            >
              {filterQuestions?.length ? (
                filterQuestions?.map((item, index) => (
                  <Tooltip
                    key={item?.QUESTION_ID + index}
                    title={item?.QUESTION_TEXT}
                  >
                    <Typography
                      border="1px solid #0000001f"
                      bgcolor={getQuestionBackground(item?.QUESTION_STATUS)}
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontWeight="700"
                      borderRadius="4px"
                      p={0.125}
                      component="span"
                      px={0.5}
                      width="4rem"
                      height="1.5rem"
                      display="inline-block"
                      alignItems="center"
                      fontSize={12}
                    >
                      {item?.REGULATORS_QUESTION_REF}
                    </Typography>
                  </Tooltip>
                ))
              ) : (
                <NoDataCard
                  message="No Questions available"
                  Icon={QuestionAnswer}
                />
              )}
            </Box>
          )}
        </>
      </Box>
    </React.Fragment>
  );
};

QuestionView.propTypes = {
  filterQuestions: propTypes.array,
  questionList: propTypes.array,
  onFilterChange: propTypes.func,
  onRegulatorGroupChange: propTypes.func,
  filterOptions: propTypes.array,
  selectedFilters: propTypes.array,
  regulatorsList: propTypes.array,
};
