import { MasterAssessmentConstants } from "./constants"

const INITIAL_STATE = {
    AssessmentComments: [],
    RfiFilterDetails: [],
    CommentLoader: false,
    QuestionDataPoints: [],
    EvidencesList: [],
    DataAssetsList: [],

    RfiDialogDataAssets: [],
    RfiDialogEvidences: [],
    ActionsList: [],
}

export default function MasterAssessment(state = INITIAL_STATE, action) {
    switch (action.type) {
        case MasterAssessmentConstants.GET_COMMENTS:
            return {
                ...state,
                AssessmentComments: action.payload
            };
        case MasterAssessmentConstants.ERROR_COMMENTS:
            return {
                ...state,
                AssessmentComments: []
            };
        case MasterAssessmentConstants.GET_RFI_FILTER:
            return {
                ...state,
                RfiFilterDetails: action.payload
            };
        case MasterAssessmentConstants.SET_COMMENT_LOADER:
            return {
                ...state,
                CommentLoader: action.payload
            };

        case MasterAssessmentConstants.GET_QUESTION_DATA_POINTS:
            return {
                ...state,
                QuestionDataPoints: action.payload
            };

        case MasterAssessmentConstants.GET_MASTER_EVIDENCES:
            return {
                ...state,
                EvidencesList: action.payload
            };

        case MasterAssessmentConstants.ERROR_EVIDENCES:
            return {
                ...state,
                EvidencesList: []
            };
        case MasterAssessmentConstants.GET_MASTER_DATA_ASSETS:
            return {
                ...state,
                DataAssetsList: action.payload
            };

        case MasterAssessmentConstants.ERROR_DATA_ASSETS:
            return {
                ...state,
                DataAssetsList: []
            };

        case MasterAssessmentConstants.GET_RFI_DIALOG_EVIDENCES:
            return {
                ...state,
                RfiDialogEvidences: action.payload
            };

        case MasterAssessmentConstants.GET_RFI_DIALOG_DATA_ASSETS:
            return {
                ...state,
                RfiDialogDataAssets: action.payload
            };
        case MasterAssessmentConstants.SET_ACTIONS_LIST:
            return {
                ...state,
                ActionsList: action.payload
            };
        default:
            return state;
    }
}