/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/

import React from "react";
import { alertActions, loadingActions } from "_actions";
import apiService from "_services/api.service";
import { MasterAssessmentConstants } from "./constants";
import langConstant from "_lang";


export const getCommentsList = (question_ID, submission_ID, loaderIdentifier) => {
  return (dispatch) => {
    loaderIdentifier && dispatch({
      type: MasterAssessmentConstants.SET_COMMENT_LOADER,
      payload: true,
    });
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetComments", {
        i_SUBMISSION_ID: submission_ID,
        i_QUESTION_ID: question_ID,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            dispatch({
              type: MasterAssessmentConstants.GET_COMMENTS,
              payload: response["#result-set-1"],
            });
          } else {
            dispatch({ type: MasterAssessmentConstants.ERROR_COMMENTS });
            dispatch(alertActions.error("Comment list not found"));
          }
          dispatch({
            type: MasterAssessmentConstants.SET_COMMENT_LOADER,
            payload: false,
          });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
          dispatch({
            type: MasterAssessmentConstants.SET_COMMENT_LOADER,
            payload: false,
          });
        }
      );
  };
};

export const postComment = (question_ID, submission_ID, user_ID, comment, identifier) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutComments", {
        i_SUBMISSION_ID: submission_ID,
        i_QUESTION_ID: question_ID,
        i_USER_ID: user_ID,
        i_COMMENT: comment,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response['#update-count-1']) {
            identifier && dispatch(getCommentsList(question_ID, submission_ID, false));
          } else {
            dispatch({ type: MasterAssessmentConstants.ERROR_COMMENTS });
            dispatch(alertActions.error("Comment list not found"));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const getQuestionDataQualityPoints = (qid) => {
  //let data = new Blob(JSON.stringify(draft) );
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionDQProfile", {
        i_QUESTION_ID: qid,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({ type: MasterAssessmentConstants.GET_QUESTION_DATA_POINTS, payload: response["#result-set-1"] });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
    //dispatch(loadingActions.start());
  };
};


export const getEvidenceForMasterAssessment = (questionId, submissionId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetSubmissionEvidenceFromEvidenceLog", { i_QUESTION_ID: questionId, i_SUBMISSION_ID: submissionId })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response) {
            dispatch({
              type: MasterAssessmentConstants.GET_MASTER_EVIDENCES,
              payload: response["#result-set-1"],
            });
          } else {
            dispatch(alertActions.error(langConstant.EVIDENCE_NOT_FOUND));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
            dispatch({ type: MasterAssessmentConstants.ERROR_EVIDENCES });
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const getDataAssestsForMasterAssessment = (qid) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetDataAssestsForQuestion", {
        i_QUESTION_ID: qid,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch({
            type: MasterAssessmentConstants.GET_MASTER_DATA_ASSETS,
            payload: response["#result-set-1"],
          });
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
            dispatch({ type: MasterAssessmentConstants.ERROR_DATA_ASSETS });
          } else {
            dispatch(alertActions.error(error.toString()));
            dispatch({ type: MasterAssessmentConstants.ERROR_DATA_ASSETS });
          }
        }
      );
  };
};

export const PutAuditObservation = (dataObj) => {
  return (dispatch) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutAuditObservation", dataObj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response['#result-set-1'] && response['#result-set-1'].length > 0) {
            dispatch(alertActions.success("Observations Added Successfully"));
            return response['#result-set-1'][0]
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
}

export const UpdateAuditObservation = (dataObj) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAuditObservation", dataObj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response['#update-count-1']) {
            dispatch(alertActions.success("Observations Updated Successfully"));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
}

export const UpdateAuditorQuestionStatus = (dataObj, QuestionList, currentQuestion, callBackFunc, updateCommentCount = false, ScreenIdentifer) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionStatus", dataObj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response['#result-set-1'] && response['#result-set-1'].length > 0) {
            const data = [...QuestionList].map((el) => {
              if (`${currentQuestion.CISO_CONTROL_NAME}_${currentQuestion.QUESTION_ID}_${currentQuestion.REGULATORS_QUESTION_REF}` === `${el.CISO_CONTROL_NAME}_${el.QUESTION_ID}_${el.REGULATORS_QUESTION_REF}`) {
                if (updateCommentCount) {
                  return { ...el, QUESTION_STATUS: dataObj["i_QUESTION_STATUS"], NUM_OF_COMMENTS: el.NUM_OF_COMMENTS === null ? 1 : el.NUM_OF_COMMENTS + 1 }
                }
                return { ...el, QUESTION_STATUS: dataObj["i_QUESTION_STATUS"] }
              }
              return el
            });

            if (ScreenIdentifer === "audit") {
              dispatch({ type: "SET_AUDIT_QUESTIONS", payload: data });
            }

            if (ScreenIdentifer === "reviewmanager") {
              dispatch({ type: "SET_REVIEW_MANAGER_QUESTIONS", payload: data });
            }

            if (dataObj["i_QUESTION_STATUS"] === "AUDIT_REVIEWED") {
              dispatch(alertActions.success("Question Marked as Reviewed"));
            }

            if (dataObj["i_QUESTION_STATUS"] === "SENT_BACK") {
              callBackFunc(data);
              dispatch(alertActions.success("Selected Question has been Sent Back"));
            }

          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );

  }
}


export const UpdateAuditorQuestionStatusToFinal = (dataObj, QuestionList, QuestionIDArray, callBackFunc, ScreenIdentifer) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionStatus", dataObj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response['#result-set-1'] && response['#result-set-1'].length > 0) {

            const data = [...QuestionList].map((el) => {
              if (QuestionIDArray.includes(el.QUESTION_ID)) {
                return { ...el, QUESTION_STATUS: dataObj["i_QUESTION_STATUS"] }
              }

              return el
            })

            if (ScreenIdentifer === "audit") {
              dispatch({ type: "SET_AUDIT_QUESTIONS", payload: data });
            }

            if (ScreenIdentifer === "reviewmanager") {
              dispatch({ type: "SET_REVIEW_MANAGER_QUESTIONS", payload: data });
            }

            callBackFunc(data);
            dispatch(alertActions.success("Selected question(s) have been marked as " + dataObj["i_QUESTION_STATUS"]));

          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );

  }
}

export const updateAssessmentStatus = (SubID, RegID, status, callback, message = "") => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAssessmentStatus", {
        i_REGULATION_ID: RegID,
        i_SUBMISSION_ID: SubID,
        i_STATUS: status,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(alertActions.success(message));
          callback();
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const GetRFIList = (questionID, submissionID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRFIList", { i_QUESTION_ID: questionID, i_SUBMISSION_ID: submissionID })
    .then(apiService.handleResponse)
    .then((response) => {
      if (response) {
        return response
      }
    },
      (error) => {
        if (error.response) {
          return error.response
        }
      }
    );
}

export const GetRFIEvidenceAndDataAssetsForQuestion = (UserID, SubmissionID, QuestionID) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetRFIEvidenceAndDataAssetsForQuestion", {
        i_USER_ID: UserID, i_SUBMISSION_ID: SubmissionID, i_QUESTION_ID: QuestionID
        // i_USER_ID: 1, i_SUBMISSION_ID: 3, i_QUESTION_ID: 100004
      }).then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#result-set-1"]) {

            dispatch({
              type: MasterAssessmentConstants.GET_RFI_DIALOG_EVIDENCES,
              payload: response["#result-set-1"],
            });
          }

          if (response["#result-set-2"]) {
            dispatch({
              type: MasterAssessmentConstants.GET_RFI_DIALOG_DATA_ASSETS,
              payload: response["#result-set-2"],
            });

          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      )
  }


}

export const UpdateMultiRFI = (obj, identifier) => {
  return (dispatch) => {

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateMultiRFI", obj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          let word;
          if (identifier === "delete") {
            word = "Deleted"
          } else {
            word = "Updated"
          }
          dispatch(
            alertActions.success("RFi has been successfully " + word)
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }

      )
  }

}

export const updateQuestionStatusForRfiDialog = (QUESTION_ID, SUBMISSION_ID, STATUS, userID) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateQuestionStatus", {
        i_QUESTION_ID: QUESTION_ID,
        i_SUBMISSION_ID: SUBMISSION_ID,
        i_QUESTION_STATUS: STATUS,
        i_USER_ID: userID
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());

          //* Successfully changed the question status
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const PutMultiRFI = (obj) => {
  return (dispatch) => {

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutMultiRFI", obj)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(
            alertActions.success("RFi has been successfully assigned")
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }

      )
  }

}

export const GetAuditObservationforQuestion = (subID, queID) => {
  return (dispatch) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAuditObservationforQuestion", {
        i_SUBMISSION_ID: subID,
        i_QUESTION_ID: queID
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"] && response["#result-set-1"].length > 0) {
            return response["#result-set-1"]
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }

      )
  }
}

export const updateRFI = (
  CurrentQuestion,
  newStatus,
  rfiResolvedDate = null,
  active,
  newDueDate,
  RfiitemsListArray,
  rfiID
) => {
  return (dispatch) => {
    dispatch(loadingActions.start());

    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateRFI", {
        i_ASSESSMENT_ID: CurrentQuestion.SUBMISSION_ID,
        i_QUESTION_ID: CurrentQuestion.QUESTION_ID,
        i_RFI_DUE_DATE: newDueDate,
        i_ACTIVE: active,
        i_RFI_STATUS: newStatus,
        i_RFI_RESOLVED_DATE: rfiResolvedDate,
        i_RFI_ID: rfiID
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());

          if (newStatus != null) {

            const data = [...RfiitemsListArray].map((el) => {
              if (el.REGULATORS_QUESTION_REF === CurrentQuestion.REGULATORS_QUESTION_REF) {
                return { ...el, QUESTION_STATUS: newStatus, STATUS: "RFI RESOLVED" };
              }
              return el;
            });

            dispatch({ type: "MY_ASSESSMENT_RFI_ITEMS", payload: data });

            dispatch(
              alertActions.success(
                langConstant.RESOLVED_RFI_ALERT_PROMPT(CurrentQuestion.REGULATORS_QUESTION_REF)
              )
            );

          }

        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const GetQuestionResponsesForAssessments = (qId, customerID) => {
  return (dispatch) => {
    return apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetQuestionResponses", {
        i_QUESTION_ID: qId,
        i_CUSTOMER_ID: customerID
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response && response["#result-set-1"]) {
            return response["#result-set-1"]
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const saveSingleQuestion = (data, callback) => {
  const payload = {
    i_QUESTION_ID: data.QUESTION_ID,
    i_CURRENT_ANSWER_SELECTED_RESPONSE: data.CURRENT_ANSWER_SELECTED_RESPONSE,
    i_CURRENT_ANSWER_JUSTIFICATION_DETAILS: data.CURRENT_ANSWER_JUSTIFICATION_DETAILS ? data.CURRENT_ANSWER_JUSTIFICATION_DETAILS : "",
    i_CURRENT_ANSWER_EVIDENCE_DETAILS: data.CURRENT_ANSWER_EVIDENCE_DETAILS ? data.CURRENT_ANSWER_EVIDENCE_DETAILS : "",
    i_CURRENT_ANSWER_MATCH_SCORE: data.CURRENT_ANSWER_MATCH_SCORE ? data.CURRENT_ANSWER_MATCH_SCORE : "",
    i_QUESTION_STATE: data.QUESTION_STATE,
    i_SUBMISSION_ID: data.SUBMISSION_ID
  };
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAssessments", payload)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          callback();
          dispatch(
            alertActions.success(langConstant.QUESTION_UPDATED_SUCCESSFULLY)
          );
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const updateComplianceDecision = (question, option, callback) => {
  const payload = {
    i_QUESTION_ID: question.QUESTION_ID,
    i_Compliance_Decision: option,
    i_SUBMISSION_ID: question.SUBMISSION_ID
  };
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateComplianceDecision", payload)
      .then(apiService.handleResponse)
      .then(
        (response) => {
          callback()
          dispatch(
            alertActions.success(langConstant.COMPLIANCE_UPDATED_SUCCESSFULLY)
          );
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

export const updateAssignedStatus = (id, assignedByUserID, status, callback) => {
  return (dispatch) => {
    dispatch(loadingActions.start());
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateAssignedStatus", {
        i_REGULATION_ID: id,
        i_USER_ID: assignedByUserID,
        i_STATUS: status,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(loadingActions.end());
          if (status == "PENDING_FOR_REVIEW") {
            dispatch(
              alertActions.success(
                langConstant.ASSESSMENT_SUBMITTED_FOR_REVIEW_SUCCESSFULLY
              )
            );
            callback();
          }
        },
        (error) => {
          dispatch(loadingActions.end());
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};
