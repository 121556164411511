/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import Pagination from "@material-ui/lab/Pagination";
import Drawer from "@material-ui/core/Drawer";

import { Box, Button, makeStyles } from "@material-ui/core";

import langConstant from "_lang";
import FilterByStatus from "_components/FilterByStatusTab";
import ProgressBarLarge from "_components/ProgressBarLarge";
import AssessmentNavigator from "./components/AssessmentNavigator/index";
import RfiDetails from "./components/RfiDetails";
import manageRfiActions from "../../actions";
import uploadDataAction from "../../../../UploadDataForm/actions";
import moment from "moment";
import sessionService from "_services/session.service";


const useStyles = makeStyles(() => ({
  drawerPaper: {
    overflow: "visible",
  },
}));

// values must match RFI status values from database
const RFI_ASSIGNED = "RFI_ASSIGNED";
const RFI_RESOLVED = "RFI_RESOLVED";

const selectedTabAssigned = { name: RFI_ASSIGNED, id: 0 };
const selectedTabResolved = { name: RFI_RESOLVED, id: 1 };

const ResolveRfiStep = ({ progressPercentage, onPageChange }) => {
  const dispatch = useDispatch();
  const userID = sessionService.getUserId();
  const [IsAttributeChanged, setIsAttributeChanged] = useState({ bool: false, id: [] });

  const totalRfisList = useSelector(
    (state) => state.rfiListForAssessment.rfiList
  );

  const RfiAttributesList = useSelector((state) => state.rfiListForAssessment.rfiDataAttributes);

  const [tabs, setTabs] = useState([
    { name: langConstant.RFI_ASSIGNED, count: 0 },
    { name: langConstant.RFI_RESOLVED, count: 0 },
  ]);
  const [drawerState, setDrawerState] = useState(false);
  const [selectedTab, setSelectedTab] = useState(selectedTabAssigned);

  const [page, setPage] = useState(0);

  const [rfisInTabs, setRfisInTabs] = useState({
    RFI_ASSIGNED: [],
    RFI_RESOLVED: [],
  });

  const classes = useStyles();

  const KafkaTopicUpload = async () => {

    if (IsAttributeChanged.bool && IsAttributeChanged.id.length > 0) {
      RfiAttributesList.map(async (rfi) => {
        if (IsAttributeChanged.id.includes(rfi.RFI_ITEM_KEY)) {
          let tempobj = {
            payload: {
              evidenceLogId: "",
              messageDataTime: moment().format("YYYY-MM-DD hh:mm:ss"),
              messageSource: "",
              questionId: "",
              regulationId: "",
              rfiId: "",
              ruleId: "",
              sourceDataId: rfi.SOURCE_DATA_ID,
              sourceSystemId: "",
              submissionId: "",
              uniqueMessageId: "",
              userId: userID
            },
            topicID: 0,
            topicName: window.REACT_APP_KAFKA_NEWDATA_TOPIC

          }

          const response = await uploadDataAction.kafkatopicUpload(tempobj);

        }

      });

    }
  }

  // * Toggles Assessment Navigator on the right
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  // * Navigates to page and closes assessment navigator
  const setPageNumber = (pageNumber) => {
    setDrawerState(false);
    setPage(pageNumber);
  };

  const handleTabChange = (e, newValue) => {
    switch (newValue) {
      case 1:
        setSelectedTab(selectedTabResolved);
        break;
      default:
        setSelectedTab(selectedTabAssigned);
    }
    setPage(0);
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const setupRfisTabs = (rfisList) => {
    let filteredAssignedRfis = [];
    let filteredResolvedRfis = [];

    _.each(rfisList, (rfiElement) => {
      rfiElement.RFI_STATUS === RFI_RESOLVED
        ? filteredResolvedRfis.push(rfiElement)
        : filteredAssignedRfis.push(rfiElement);
    });

    setTabs([
      { name: langConstant.RFI_ASSIGNED, count: filteredAssignedRfis.length },
      { name: langConstant.RFI_RESOLVED, count: filteredResolvedRfis.length },
    ]);

    setRfisInTabs({
      RFI_ASSIGNED: filteredAssignedRfis,
      RFI_RESOLVED: filteredResolvedRfis,
    });
  };

  useEffect(() => {
    totalRfisList.length && setupRfisTabs(totalRfisList);
  }, [totalRfisList]);

  useEffect(() => {
    rfisInTabs[selectedTab.name][page] &&
      dispatch(
        manageRfiActions.getRfiDataAttributes(
          rfisInTabs[selectedTab.name][page].RFI_ID
        )
      );
  }, [page, rfisInTabs, selectedTab]);

  useEffect(() => {
    if (page !== 0 && page === rfisInTabs[selectedTab.name].length) {
      setPage(page - 1);
    } else if (
      rfisInTabs.RFI_ASSIGNED.length === 0 &&
      rfisInTabs.RFI_RESOLVED.length !== 0
    ) {
      setSelectedTab(selectedTabResolved);
    } else if (
      rfisInTabs.RFI_RESOLVED.length === 0 &&
      rfisInTabs.RFI_ASSIGNED.length !== 0
    ) {
      setSelectedTab(selectedTabAssigned);
    }
  }, [rfisInTabs]);

  return (
    <Box>
      <Box>
        <Button
          onClick={toggleDrawer(true)}
          style={{
            position: "fixed",
            right: "0",
            top: "50vh",
            backgroundColor: "rgba(240, 20, 47, 1)",
            color: "white",
            borderRadius: "6px 0px 0px 6px",
            zIndex: "100",
            minWidth: "50px ",
          }}
        >
          <ExploreOutlinedIcon />
        </Button>
        <Drawer
          anchor={"right"}
          open={drawerState}
          onClose={toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AssessmentNavigator
            selectedTab={selectedTab.name}
            selectedRfisList={rfisInTabs[selectedTab.name]}
            onClose={() => setDrawerState(false)}
            setPageNumber={(pageNumber) => {
              setPageNumber(pageNumber);
            }}
            style={{ position: "relative", overflow: "visible" }}
          />
        </Drawer>
      </Box>
      <ProgressBarLarge percentage={progressPercentage} />

      {/* tabs row */}
      <Box pb={2} mt={2}>
        <FilterByStatus
          selectedTab={selectedTab}
          onChange={handleTabChange}
          tabs={tabs}
        />
      </Box>

      {/* pagination row */}
      <Box>
        <Pagination
          shape="rounded"
          siblingCount={1}
          className="custom-pagination"
          color="primary"
          count={rfisInTabs[selectedTab.name].length}
          page={page + 1}
          showFirstButton={false}
          showLastButton={false}
          onChange={(event, value) => {
            handlePageChange(value - 1);
            KafkaTopicUpload();
            onPageChange();
            setIsAttributeChanged({ bool: false, id: [] });
          }}
        />
      </Box>

      <RfiDetails isFormDisabled={selectedTab.name === RFI_RESOLVED} setIsAttributeChanged={setIsAttributeChanged} userID={userID} />
    </Box>
  );
};

ResolveRfiStep.propTypes = {
  progressPercentage: PropTypes.number,
  rfiList: PropTypes.array,
  onPageChange: PropTypes.func,
};

export default ResolveRfiStep;
