/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Box, Button, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import AppDataTable from "./components/AppDataTable";
import regulationAction from "./actions";
import moment from "moment";
import { getCurrentPageSlug } from "_helpers/utils";
import sessionService from "_services/session.service";

const NewRegulation = () => {
  const location = useLocation();
  const pageSlug = getCurrentPageSlug(location.pathname);

  const userID = sessionService.getUserId();
  const customerID = sessionService.getCustomerId();

  //REGULATION ID REGULATION NAME REGULATOR COUNTRY STATUS UPLOAD DATE
  const columns = [
    { field: "REGULATION_ID", headerName: "REGULATION ID", minWidth: 135 },
    { field: "REGULATION_SHORT_NAME", headerName: "REGULATION SHORT NAME", minWidth: 150},
    { field: "DOMAIN_NAME", headerName: "DOMAIN NAME", minWidth: 150 },
    { field: "REGULATION_NAME", headerName: "REGULATION NAME" },
    { field: "REGULATOR_NAME", headerName: "REGULATOR" },
    { field: "COUNTRY_NAME", headerName: "COUNTRY", minWidth: 180 },
    { field: "Status_label", headerName: "STATUS", minWidth: 150 },
    {
      field: "UPLOAD_DATE",
      headerName: "UPLOAD DATE",
      minWidth: 150,
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
    },
    { field: "action", headerName: "ACTIONS", minWidth: 95, sticky: true },
  ];
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.regulationList);

  useEffect(() => {
    dispatch(regulationAction.getRegulationList(userID, customerID));
  }, []);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {pageSlug == "new-regulation" && (
            <Box textAlign="right">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                to={"/new-regulation/upload-regulation"}
                startIcon={<AddIcon />}
                component={Link}
              >
                Add New Regulation
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <AppDataTable
        rows={rows}
        columns={columns}
        className="data-table"
        pageSlug={pageSlug}
      />
    </Box>
  );
};
export default NewRegulation;
