// OP#2994 - UJA10 - UI Development : Manage Evidence Types - Part 1 only
// api service for Evidence-types tab

import apiService from "_services/api.service";
import axios from "axios";

export const GetEvidenceTypeList = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetEvidenceTypeList")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export const GetRegulatoryDomainsForUser = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRegulatoryDomainsForUser", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export const GetCustomerInfoClassfication = (userID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetCustomerInfoClassification", {
      i_USER_ID: userID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export const getDomainList = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetRegulatoryDomains")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export const PutEvidenceType = (DataObj) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutEvidenceType", DataObj)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export const GetEvidenceTypeById = (i_EVIDENCE_TYPE_ID, i_USER_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetEvidenceTypeByID", {
      i_EVIDENCE_TYPE_ID,
      i_USER_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

export const GetExtractedKeywords = async ({
  file_directory = "",
  filename = "",
  alias = "dummy",
  storageType,
  preProcessingIP,
  preProcessingURL,
}) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/extract_plain", {
      file_directory,
      filename,
      alias,
      storageType: storageType.toLowerCase(),
      preProcessingIP: preProcessingIP,
      preProcessingURL: preProcessingURL,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error?.response) {
          return error?.response;
        }
      }
    );
};

export const UploadFileOnS3Bucket = async (payload) => {
  return apiService
    .apiCall("flaskapiwrapper")
    .send("/api/v1/service/upload/fileUploadOnS3", payload)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error?.response) {
          return error?.response;
        }
      }
    );
};
