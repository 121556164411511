import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ScreenLockLandscapeIcon from "@material-ui/icons/ScreenLockLandscape";
import DialogBox from "_components/DialogBox";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AssessmentEvidenceTable from "_components/EvidenceLocker/component/EvidenceTable";
import langConstant from "_lang";
import assessmentEvidenceAction from "./actions";

const AssessmentEvidence = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let { id, sid } = useParams();
  const regulationId = parseInt(id);
  const nonVendorSubmissionID = parseInt(sid);
  const submissionId = parseInt(id);

  const evidenceRows = useSelector(
    (state) => state.assessmentEvidenceLocker.evidence
  );

  const NoData = ()=> <span style={{ color: "red" }}>No Data</span>

  const evidenceColumns = {
    view_evidence: [
      // {
      //   field: "REGULATORS_QUESTION_REF",
      //   headerName: "QUESTION REF.",
      //   minWidth: 120,
      // },
      {
        field: "Version_Label",
        headerName: "Version",
      },

      { field: "EVIDENCE_SHORTNAME", headerName: "Evidence Type" },
      {
        field: "DESCRIPTION",
        headerName: "Classification",
        // eslint-disable-next-line react/display-name
        format: (value) => value || <NoData />,
      },
      // {
      //   field: "Tags_Label",
      //   headerName: "TAGS",
      //   minWidth: 100,
      // },
      {
        field: "EVIDENCE_WEIGHTAGE",
        headerName: "Weightage (In %)",
        format: function formatData(value) {
          if (value) {
            return value;
          } else {
            return <NoData />;
          }
        },
      },
      {
        field: "Attachment_Label",
        headerName: "Attachments",
      },
      {
        field: "Evidence_WordCloud",
        headerName: "Wordcloud",
        format: function formatData(value) {
          if (value) {
            return value;
          } else {
            return <NoData />;
          }
        },
      },
      {
        field: "UPLOAD_DATE",
        headerName: "Upload Date & Time",
        format: (value) =>
          value ? moment(value).format("DD-MMM-YY h:mm A") : "",
      },
      {
        field: "EXPIRY_DATE",
        headerName: langConstant.EXPIRY_DATE,
        format: (value) =>
          value ? moment(value).format("DD-MMM-YY h:mm A") : "-",
      },
      {
        field: "Uploaded_By",
        headerName: "Uploaded By",
      },
      {
        field: "METADATA",
        headerName: "Accuracy Score",
        format: function formatData(value) {
          if (value && value.length >= 1) {
            return value[0];
          } else {
            return <NoData />;
          }
        },
      },
      {
        field: "METADATA",
        headerName: "Suitability Score",
        format: function formatData(value) {
          if (value && value.length >= 2) {
            return value[1];
          } else {
            return <NoData />;
          }
        },
      },
    ],
    tags: [
      {
        field: "Version_Label",
        headerName: "Version",
      },
      { field: "EVIDENCE_SHORTNAME", headerName: "Evidence Type" },
      {
        field: "COMMENTS_INPUT",
        headerName: "Comments",
      },
      {
        field: "Tags_Label",
        headerName: "TAGS",
      },
    ],
  };

  useEffect(() => {
    if (window.location.pathname.includes("vendor-assessment") === true) {
      dispatch(
        assessmentEvidenceAction.getAssessmentEvidence(submissionId, "vendor")
      );
    }

    if (
      window.location.pathname.includes("my-assessment") === true ||
      window.location.pathname.includes("audits") === true
    ) {
      dispatch(
        assessmentEvidenceAction.getAssessmentEvidence(
          regulationId,
          "notvendor",
          nonVendorSubmissionID
        )
      );
    }
  }, []);

  // * Toggles the RFI Filter Dialog box
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  return (
    <Box>
      <AssessmentEvidenceTable
        rows={evidenceRows}
        columns={evidenceColumns}
        className="data-table"
        isAccordion={true}
        displayFilters
        type="assessment"
      />
    </Box>
  );
};

export default AssessmentEvidence;
