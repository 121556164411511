import React, { useCallback, useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { TextField, Box } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from "prop-types";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";


function SearchBar({ QuestionList = [], setPage }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [TotalQuestionList, setTotalQuestionList] = useState(QuestionList)
    const [filterdel, setfilterdel] = useState([]);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [HasMoreData, setHasMoreData] = useState(true);

    useEffect(() => {
        setTotalQuestionList(QuestionList);
        setHasMoreData(true)
    },[QuestionList, anchorEl]);

    useEffect(() => {
        setfilterdel([...TotalQuestionList].slice(0, 19));
    }, [QuestionList, anchorEl, TotalQuestionList])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const QuestionClick = (element) => {
        handleClose();
        if (element) {
            const index = _.findIndex(TotalQuestionList, (e) => {
                return `${e.CISO_CONTROL_NAME}_${e.QUESTION_ID}_${e.REGULATORS_QUESTION_REF}` == `${element.CISO_CONTROL_NAME}_${element.QUESTION_ID}_${element.REGULATORS_QUESTION_REF}`;
            });
            setPage(index);
        }
    }

    const fetchMoreData = () => {
        if (filterdel.length >= TotalQuestionList.length) {
            setHasMoreData(false)
            return;
        }
        let length = filterdel.length;
        const data = [...TotalQuestionList].slice(length, length + 19);
        setfilterdel((prev) => [...prev, ...data]);
    };


    const DebouncedOnSearchHandler = useCallback(_.debounce((e) => {
        if (e.target.value === "") {
            setTotalQuestionList(QuestionList);
            setHasMoreData(true);
            return
        }
        const data = QuestionList.filter((el) =>
            el.QUESTION_TEXT
                .toLowerCase()
                .trim()
                .includes(e.target.value.toLowerCase().trim())
            ||
            el.REGULATORS_QUESTION_REF && el.REGULATORS_QUESTION_REF
                .toLowerCase()
                .trim()
                .includes(e.target.value.toLowerCase().trim())
        );
        setTotalQuestionList(data);
        setHasMoreData(true);

    }, 300));

    const QuestionListRenderer = () => {
        return (
            <List
                sx={{ width: "100%", bgcolor: "background.paper" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                { filterdel.map((el, i) => (
                    <ListItemButton onClick={() => { QuestionClick(el) }} key={`search-list-1-${i}`}>
                        <ListItemText primary={<><strong>{el.REGULATORS_QUESTION_REF}</strong> - {el.QUESTION_TEXT}</>} />
                    </ListItemButton>
                ))}
            </List>
        );

    }

    return (
        <div style={{ alignSelf: "flex-end", marginRight: "10px" }}>
            <div style={{ display: "flex" }}>
                <div>
                    <Typography style={{ marginTop: "3px" }}>Search</Typography>
                </div>
                <div style={{ marginLeft: "10px" }}>
                    <SearchIcon aria-describedby={id} onClick={handleClick} sx={{ fontSize: "32px", cursor: "pointer", color: "#036FB8" }} />
                </div>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: { width: "50%", height: "500px" ,overflow: "hidden" }
                }}
            >
                <Box style={{ height: "500px", padding: "10px" }}>
                    <Box >
                        <TextField
                            variant="standard"
                            style={{ width: "100%" }}
                            onChange={DebouncedOnSearchHandler}
                            placeholder="Search Questions"
                        />
                    </Box>
                    <Box id="scrollableDiv" style={{ height: "93%", overflow: "auto" }}>
                        <InfiniteScroll
                            dataLength={filterdel.length}
                            next={fetchMoreData}
                            hasMore={HasMoreData}
                            loader={<h4>Loading...</h4>}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>No More Data</b>
                                </p>
                            }
                            scrollableTarget="scrollableDiv"
                        >
                            {QuestionListRenderer()}
                        </InfiniteScroll>
                    </Box>
                </Box>
            </Popover>
        </div>
    );
}

SearchBar.propTypes = {
    QuestionList: PropTypes.array,
    setPage: PropTypes.func
};

export default SearchBar
