import React from "react";
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import langConstant from "_lang";
import PropTypes from "prop-types";
import { decreaseActiveStep } from "_components/Stepper/action"
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "_components/Layout/CustomButton";
import { paginationStyles } from "_components/AppPagination";
import { getThemeDetails } from "containers/LoginPage/reducers";

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(1),
  },
}));

const ActionBar = ({ onChangePage, page, pageCount, isPagination = false, PrimaryBtn, SecondaryBtn = {}, isSecondaryBtn = false }) => {
  const { btnicon, btntext, onbtnclick, btndisabled } = PrimaryBtn
  const { secbtnicon, secbtntext, seconbtnclick, secbtndisabled, secbtnclasses } = SecondaryBtn
  const classes = useStyles();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepper.steps);
  const activeStep = useSelector((state) => state.stepper.activestep);
  const themeDetails = useSelector(getThemeDetails);
  const paginationClass = paginationStyles({
    bgColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
    fontColor: themeDetails?.FONT_TYPEFACE_BOLD,
  });

  const OnGoBackClick = () => {
    dispatch(decreaseActiveStep());
  }


  return (
    <Box className="fixed-action-bar" p={1} style={{ display: "flex" }}>
      <Grid container alignItems="center" spacing={2} >
        <Grid item style={{ width: "80px" }}></Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            disabled={btndisabled}
            startIcon={btnicon}
            onClick={onbtnclick}
          >
            {btntext}
          </Button>
        </Grid>

        {
          isSecondaryBtn && (
            <Grid item>
              <CustomButton
                className={secbtnclasses}
                variant="contained"
                color="primary"
                size="medium"
                startIcon={secbtnicon}
                onClick={seconbtnclick}
                disabled={secbtndisabled}
              >
                {secbtntext}
              </CustomButton>
            </Grid>
          )
        }

        <Grid item>
          <Button
            color="primary"
            size="small"
            disabled={activeStep === 0}
            onClick={OnGoBackClick}
            className={classes.backButton}
          >
            {langConstant.GO_BACK}
          </Button>
        </Grid>
      </Grid>
      {
        isPagination && (
          <Grid container style={{ justifyContent: "flex-end", marginRight: "80px" }}>
            <Pagination
              shape="rounded"
              siblingCount={1}
              // className="custom-pagination"
              className={paginationClass.root}
              color="primary"
              count={pageCount}
              page={page + 1}
              showFirstButton={false}
              showLastButton={false}
              onChange={(event, value) => {
                onChangePage(value - 1);
              }}
            />
          </Grid>
        )
      }
    </Box>
  )
}

ActionBar.propTypes = {
  onChangePage: PropTypes.func,
  page: PropTypes.number,
  pageCount: PropTypes.number,
  CONTINUE: PropTypes.bool,
  GO_BACK: PropTypes.bool,
  SEND_FOR_REVIEW: PropTypes.bool,
  isPagination: PropTypes.bool,
  PrimaryBtn: PropTypes.object,
  SecondaryBtn: PropTypes.object,
  isSecondaryBtn: PropTypes.bool,
};

export default ActionBar