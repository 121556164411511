/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import langConstant from "_lang";
import apiService from "_services/api.service";
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from "@aws-sdk/client-secrets-manager";
import { store } from "store";
import axios from "axios";
import sessionService from "_services/session.service";

export const fetchAWSSecretData = async () => {
  const secret_name = "WEB_ADMIN";

  const client = new SecretsManagerClient({
    region: "us-east-1",
  });

  let response;
  /* eslint-disable-next-line */
  try {
    response = await client.send(
      new GetSecretValueCommand({
        SecretId: secret_name,
        VersionStage: "AWSCURRENT", // VersionStage defaults to AWSCURRENT if unspecified
      })
    );
    console.log(response, "success response");
  } catch (error) {
    // For a list of exceptions thrown, see
    // https://docs.aws.amazon.com/secretsmanager/latest/apireference/API_GetSecretValue.html
    console.log(error, "success response");
    throw error;
  }
  console.log(response, "response");
  console.log(response.SecretString, "response.SecretString");
  const secret = response.SecretString;

  // Your code goes here
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const toTitleCase = (str) => {
  if (str) {
    return str.replace(/\b\w+/g, function (s) {
      return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
    });
  } else {
    return str;
  }
};

export const replaceAll = (str, match, replacement) => {
  const escapeRegExp = (string) => {
    return string.replace(/[^A-Za-z0-9 ]/g, "\\$&");
  };
  if (str) {
    return str.replace(new RegExp(escapeRegExp(match), "g"), () => replacement);
  } else {
    return str;
  }
};

export const defaultGaugeConfig = (size = "large") => {
  return {
    innerRadius: -10,
    startAngle: -240,
    endAngle: 60,
    xAxes: [
      {
        type: "ValueAxis",
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: {
          inside: true,
          radius: "110%",
          line: {
            strokeOpacity: 1,
            strokeWidth: 0,
            stroke: "#fdd400",
          },
          ticks: {
            template: {
              stroke: "#fdd400",
              strokeOpacity: 1,
              length: 0,
            },
          },
          labels: {
            template: {
              disabled: true,
            },
          },
          grid: {
            template: {
              disabled: true,
            },
          },
        },
        axisRanges: [
          {
            value: 0,
            endValue: 50,
            axisFill: {
              fillOpacity: 1,
              fill: "#F9A3AE",
            },
            grid: {
              strokeOpacity: 0,
            },
          },
          {
            value: 50,
            endValue: 80,
            axisFill: {
              fillOpacity: 1,
              fill: "#E7E99F",
            },
            grid: {
              strokeOpacity: 0,
            },
          },
          {
            value: 80,
            endValue: 100,
            axisFill: {
              fillOpacity: 1,
              fill: "#9CF1AA",
            },
            grid: {
              strokeOpacity: 0,
            },
          },
        ],
      },
      {
        type: "ValueAxis",
        min: 0,
        max: 100,
        strictMinMax: true,
        renderer: {
          radius: "135%",
          line: {
            strokeOpacity: 1,
            strokeWidth: 2,
            stroke: "#dfdfdf",
          },
          ticks: {
            template: {
              strokeOpacity: 1,
              length: 10,
            },
          },
          labels: {
            template: {
              disabled: true,
            },
          },
          grid: {
            template: {
              disabled: true,
            },
          },
        },
      },
    ],
    hands: [
      {
        fill: "#000",
        stroke: "#000",
        innerRadius: 0,
        radius: "110%",
        startWidth: 1,
        pin: {
          fill: "#000",
          stroke: "#59D3FE",
          radius: 10,
          strokeWidth: 13,
        },
      },
    ],
  };
};
export const getMinMaxFromArray = (key, array, type) => {
  return array.reduce(function (prev, curr) {
    switch (type) {
      case "min":
        return prev[key] < curr[key] ? prev[key] : curr[key];
      default:
        return prev[key] > curr[key] ? prev[key] : curr[key];
    }
  });
};

export const getScoreColorClass = (value) => {
  switch (value) {
    case 0:
    case 1:
    case 2:
      return "lowScoreBgColor";
    case 3:
      return "mediumScoreBgColor";
    case 4:
    case 5:
      return "highScoreBgColor";
    default:
      return "";
  }
};

export const getTrustScoreColorClass = (value) => {
  if (value >= 80) {
    return "highScoreBgColor";
  } else if (value >= 50 && value <= 79) {
    return "mediumScoreBgColor";
  } else {
    return "lowScoreBgColor";
  }
};

export const getTrustScoreLevel = (value) => {
  if (value >= 80) {
    return langConstant.HIGH;
  } else if (value >= 50 && value <= 79) {
    return langConstant.MEDIUM;
  } else {
    return langConstant.LOW;
  }
};

export const getLevelColor = (value) => {
  switch (value) {
    case "H":
      return "255, 35, 102";
    case "M":
      return "255, 200, 0";
    case "L":
      return "2, 188, 119";
    default:
      return "189,189,189";
  }
};

export const getCurrentPageSlug = (path = "") => {
  const pathNames =
    typeof path === "string" ? path.split("/").filter((x) => x) : null;
  if (pathNames && pathNames.length > 0) {
    return pathNames[pathNames.length - 1];
  } else {
    return path;
  }
};
export const sortByPriority = (data, key) => {
  const priority = { H: 0, M: 1, L: 2 };
  if (data && data.length > 0 && key) {
    return data.sort((a, b) => priority[a[key]] - priority[b[key]]);
  } else {
    return data;
  }
};

export const getOptionKeyValue = (options = [], value, key) => {
  if (value) {
    const arr = options.filter((item) => {
      return item.value == value;
    });
    return arr.length > 0 ? arr[0][key] : null;
  } else {
    return null;
  }
};

export const formatComparisonOperator = (operator, operatorsSet) => {
  return operatorsSet.filter((comparisonItem) => {
    return comparisonItem.value === operator;
  })[0]?.name;
};

export const virusCheckerService = (data = {}) => {
  return apiService
    .apiCall("virusScanning")
    .send("api/v1/virus/upload", data)
    .then(apiService.handleResponse)
    .then(
      (response) => {
        const customResponse = {
          status: 200,
          data: response,
        };
        return customResponse;
      },
      (error) => {
        const customResponse = {
          status: 500,
          data: error,
        };
        return customResponse;
      }
    );
};

export const decodeB64ToJsonObject = (encodedB64) =>
  // eslint-disable-next-line no-undef
  JSON.parse(Buffer.from(encodedB64, "base64"));

export const getEmailHtmlTemplate = (
  TemplateName,
  UserDataObj,
  EmailDataObj,
  templateData
) => {
  let data;

  switch (TemplateName) {
    case "Assessment_Assigned":
      data = `
    <p>${UserDataObj.UserName},</p>
    <p>${templateData["Message body"]}</p>
    <p>${EmailDataObj.EmailBody}</p>
    `;
      break;

    case "RFI_Email": {
      let s2 = templateData["Message body"].replace(
        "@QuestionID",
        UserDataObj.QuestionID
      );

      data = `
      <p>${UserDataObj.UserName},</p>
      <p>${s2}</p>
      `;
      break;
    }

    case "RFI_Update_Email": {
      let s2 = templateData["Message body"].replace(
        "@QuestionID",
        UserDataObj.QuestionID
      );

      data = `
      <p>${UserDataObj.UserName},</p>
      <p>${s2}</p>
      `;
      break;
    }

    case "Forgot_Password": {
      data = `
      <p>${UserDataObj.UserName},</p>
      <p>${langConstant.TEMPORARY_PASSWORD_SET_TO} : ${UserDataObj.TempPassword}</p>
      <a href="${window.REACT_APP_HOST}/login">Login to Regulativ.ai</a>
      `;

      break;
    }

    case "Temporary_Password": {
      // data = `
      // <p>Hello</p>
      // <p>Your temporary password is <b style="border">${UserDataObj?.TempPassword}</b></p>
      // <a href="${window.REACT_APP_HOST}/login">Login to Regulativ.ai</a>
      // <p style='line-height: 1.5;'>Many Thanks,
      //   <br />Administrator.
      // </p>
      // `;
      data = `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: auto; padding: 20px; border: 1px solid #e0e0e0; border-radius: 10px;">
        <p style="font-size: 16px; color: #333;">Hello, ${
          UserDataObj?.UserName
        }</p>
        <p style="font-size: 16px; color: #333;">Your temporary password is 
          <b style="border: 1px solid #ccc; padding: 5px; background-color: #f9f9f9;">${
            UserDataObj?.TempPassword
          }</b>
        </p>
        <a href="${window.REACT_APP_HOST}/login" 
           style="display: inline-block; padding: 10px 20px; margin-top: 20px; background-color: #007BFF; color: #fff; text-decoration: none; border-radius: 5px;">
          Login to Regulativ.ai
        </a>
        <p style="font-size: 16px; color: #333; line-height: 1.5; margin-top: 20px;">
          Many Thanks,<br />
          Administrator.
        </p>
        ${
          UserDataObj?.img
            ? `<div style="margin-top: 20px; text-align: center;">
          <img src="${UserDataObj?.img}" alt="Dynamic Image" style="max-width: 100%; height: auto; border-radius: 10px;" />
        </div>`
            : ""
        }
      </div>
    `;
      break;
    }

    default:
      break;
  }

  return `

  <table style="border-collapse:collapse;width:100%;">
    <tbody>
      <tr>
        <td>
          <table
            style="background-color: lavender;font-family:arial,sans-serif;max-width:700px;margin-left:auto;margin-right:auto;width:100%;">
            <tbody>
              <!-- <tr>
                <td style='height:100px;text-align:left;padding:15px;letter-spacing:0.5px;font-weight:100;color: black'></td>
              </tr> -->
              <tr>
                <td style="text-align:left;padding:15px;letter-spacing:0.5px;font-weight:100;">
                  ${data}
                  <p style='height: 10px;'></p>
                  <p style='line-height: 1.5;'>Thank you,
                    <br />Regulativ.ai
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>

    <table style="font-family:arial,sans-serif;max-width:700px;margin-left:auto;margin-right:auto;width:100%;color: black">
      <tbody>
        <tr>
          <td style="text-align:center;padding:15px;letter-spacing:0.5px;font-weight:100">
            <p>${templateData["footer"]}</p>
          </td>
        </tr>
      </tbody>
    </table>

  </table>
  
  `;
};

export const generateBrightRandomColor = () => {
  var maxAttempts = 100; // Set a maximum number of attempts to avoid an infinite loop

  for (var i = 0; i < maxAttempts; i++) {
    var newColor = generateRandomColor();

    // Check if the color is not too dark (brightness above a threshold)
    if (calculateBrightness(newColor) > 128) {
      return newColor;
    }
  }

  // If maximum attempts are reached, return a default color
  return "#FFFFFF"; // White as a fallback
};

function generateRandomColor() {
  var red = Math.floor(Math.random() * 256);
  var green = Math.floor(Math.random() * 256);
  var blue = Math.floor(Math.random() * 256);

  var colorCode = "rgb(" + red + "," + green + "," + blue + ")";
  return colorCode;
}

function calculateBrightness(color) {
  var rgbValues = color.match(/\d+/g).map(function (value) {
    return parseInt(value);
  });

  // Calculate brightness using the following formula: (R * 299 + G * 587 + B * 114) / 1000
  return (rgbValues[0] * 299 + rgbValues[1] * 587 + rgbValues[2] * 114) / 1000;
}

export const getRouteBasedTitle = () => {
  const entitlementList =
      store?.getState()?.authentication?.detailedInfo?.NAVIGATION_DETAILS,
    entitlement = entitlementList?.find(
      (i) =>
        i?.ENTITLEMENT_PATH?.includes(location.pathname) &&
        i?.ENTITLEMENT_LABEL_LEVEL != 1
    );
  return entitlement?.ENTITLEMENT_LABEL ?? "";
};

export const boldOnSearch = (text, search) => {
  if (!search) {
    return text;
  }
  const index = text?.toLowerCase().indexOf(search?.toLowerCase());
  if (index === -1) {
    return text;
  }
  const first = text?.slice(0, index);
  const middle = text?.slice(index, index + search?.length);
  const last = text?.slice(index + search?.length);
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      {first}
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <b>{middle}</b>
      {last}
    </>
  );
};

export const readableFileSize = (attachmentSize) => {
  const DEFAULT_SIZE = 0;
  const fileSize = attachmentSize ?? DEFAULT_SIZE;

  if (!fileSize) {
    return `${DEFAULT_SIZE} kb`;
  }

  const sizeInKb = fileSize / 1024;

  if (sizeInKb > 1024) {
    return `${(sizeInKb / 1024).toFixed(2)} mb`;
  } else {
    return `${sizeInKb.toFixed(2)} kb`;
  }
};

export const downloadFile = (
  fileLocation,
  fileName,
  storageType,
  preProcessingIP,
  preProcessingURL
) => {
  const JWTToken = sessionService.getItem("jwtToken");
  axios
    .get(`${window.FLASK_EXTRACTION_URL}/getFile`, {
      headers: { Authorization: "Bearer " + JWTToken },
      params: {
        fileLocation: fileLocation,
        fileName: fileName,
        storageType: storageType.toLowerCase(),
        preProcessingIP: preProcessingIP,
        preProcessingURL: preProcessingURL,
      },
      responseType: "blob",
    })
    .then((response) => {
      var url = window.URL.createObjectURL(new Blob([response.data]));
      var a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a); // append the element to the dom
      a.click();
      a.remove();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchFile = (
  fileLocation,
  fileName,
  storageType,
  preProcessingIP,
  preProcessingURL,
  setDocURL
) => {
  const JWTToken = sessionService.getItem("jwtToken");
  axios
    .get(`${window.FLASK_EXTRACTION_URL}/getFile`, {
      headers: { Authorization: "Bearer " + JWTToken },
      params: {
        fileLocation: fileLocation,
        fileName: fileName,
        storageType: storageType.toLowerCase(),
        preProcessingIP: preProcessingIP,
        preProcessingURL: preProcessingURL,
      },
      responseType: "blob",
    })
    .then((response) => {
      var url = window.URL.createObjectURL(new Blob([response.data]));
      setDocURL(url);
    })
    .catch((err) => {
      console.log(err);
    });
};
