import React, { useEffect, useState } from "react";
import {
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import _ from "lodash";

import styles from "../styles";
import ShowTabContent from "./ShowTabContent"
import TabsComponent from "_components/TabsComponent";

const CurrentAnswerData = (props) => {
  const { tabsData, saveAnswer } = props;
  const initialTabs = [
    { name: 'CURRENT ANS', count: 0 },
    { name: 'PAST YEAR + 1', count: 0 },
    { name: 'PAST YEAR + 2', count: 0 },
    { name: 'PAST YEAR + 3', count: 0 },
    { name: 'DATA ASSETS', count: 0 },
    { name: 'EVIDENCES', count: 0 },
  ];
  const classes = styles();
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState({ data: tabsData.currentAnswer && tabsData.currentAnswer.answer, id: 0 });

  const onChange = (e, newValue) => {
    switch (newValue) {
      case 1:
        setSelectedTab({ data: tabsData.pastAnsOne && tabsData.pastAnsOne.answer, id: 1 });
        break;
      case 2:
        setSelectedTab({ data: tabsData.pastAnsTwo && tabsData.pastAnsTwo.answer, id: 2 });
        break;
      case 3:
        setSelectedTab({ data: tabsData.pastAnsThree && tabsData.pastAnsThree.answer, id: 3 });
        break;
      case 4:
        if (tabsData.dataAssetsList?.length) {
          const tableData = {
            headers: ['Data Assets Name', 'Data Assets Value'],
            rowData: tabsData.dataAssetsList
          }
          setSelectedTab({ data: tableData, id: 4 });
        }
        break;
      case 5:
        if (tabsData.evidenceList?.length) {
          tabsData.evidenceList.forEach(evidence => {
              let value = evidence.METADATA;
            if (value && value !== "MQ==") {
              let tempData = window.atob(value)
              tempData = tempData.slice(1, -1)
              tempData = tempData.replaceAll("], ", "]&")
              const convertedData = new URLSearchParams(tempData)
              value = JSON.parse(convertedData.get('score'))
            }
            else if (value === "MQ==") {
              value = []
            }
            evidence.accuracy = value?.length ? value[0] : 'No Date';
            evidence.suitability = value?.length ? value[1] : 'No Date';
          })
          const tableData = {
            headers: ['EVIDENCE TYPE', 'Accuracy', 'Suitability', 'Classification'],
            rowData: tabsData.evidenceList
          }
          setSelectedTab({ data: tableData, id: 5 });
        }
        break;
      default:
        setSelectedTab({ data: tabsData.currentAnswer && tabsData.currentAnswer.answer, id: 0 });
    }
  };

  //* This changes count of count of tabs
  useEffect(() => {
    setSelectedTab({ data: tabsData.currentAnswer && tabsData.currentAnswer.answer, id: 0 });
    const filterTabs = _.cloneDeep(initialTabs);
    filterTabs[0].count = tabsData.currentAnswer ? tabsData.currentAnswer.score : 0;
    filterTabs[1].count = tabsData.pastAnsOne ? tabsData.pastAnsOne.score : 0;
    filterTabs[2].count = tabsData.pastAnsTwo ? tabsData.pastAnsTwo.score : 0;
    filterTabs[3].count = tabsData.pastAnsThree ? tabsData.pastAnsThree.score : 0;
    filterTabs[4].count = tabsData.dataAssetsList?.length && tabsData.dataAssetsList.length;
    filterTabs[5].count = tabsData.evidenceList?.length && tabsData.evidenceList.length

    filterTabs[0].className = tabsData.currentAnswer ? tabsData.currentAnswer.className : '';
    filterTabs[1].className = tabsData.pastAnsOne ? tabsData.pastAnsOne.className : '';
    filterTabs[2].className = tabsData.pastAnsTwo ? tabsData.pastAnsTwo.className : '';
    filterTabs[3].className = tabsData.pastAnsThree ? tabsData.pastAnsThree.className : '';

    setTabs(filterTabs);
  }, [tabsData]);

  return (<div className={classes.root}>
    <Box pb={2}>
      <TabsComponent
        selectedTab={selectedTab}
        onChange={onChange}
        tabs={tabs}
      />
      <Box style={{ height: "calc(100vh - 611px)" }}>
        <Box style={{ width: "100%", height: "100%" }}>
          <ShowTabContent selectedTab={selectedTab} saveAnswer={saveAnswer} />
        </Box>
      </Box>
    </Box>
  </div>)
}
CurrentAnswerData.propTypes = {
  tabsData: PropTypes.object,
  saveAnswer: PropTypes.func,
};
export default CurrentAnswerData;