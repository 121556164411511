/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Box, MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import sessionService from "./_services/session.service";
import { userDataActions } from "./_actions";
import userActions from "./containers/LoginPage/actions";
import TimeOut from "_components/TimeOut";
import { alertActions } from "_actions";
import DialogBox from "_components/DialogBox";
import LoginPage from "./containers/LoginPage";
import Dashboard from "./containers/Dashboard";
import NewRegulation from "./containers/manage-regulation/NewRegulation";
import MyAssessments from "./containers/cyber-assessment/MyAssessments";
import HelpPortal from "containers/help-section";

import InitiateMyAssessment from "containers/cyber-assessment/MyAssessments/NewInitiateMyAssessment/InitiateMyAssessment";

import InitiateVendorAssessment from "./containers/vendor-management/VendorRiskManagement/InitiateAssessment";
import InitiateSupplierAssessment from "./containers/vendor-management/SupplierQuestionnaireManagement/InitiateAssessment";
import ManageAssessments from "./containers/cyber-assessment/ManageAssessments";
import ManageRfiProcess from "./containers/cyber-assessment/ManageRfi/ManageRfiProcess/index";
import ManageRfi from "./containers/cyber-assessment/ManageRfi/index";
import VendorRiskManagement from "./containers/vendor-management/VendorRiskManagement/VendorRiskManagement";
import SupplierQuestionnaire from "./containers/vendor-management/SupplierQuestionnaireManagement/SupplierQuestionnaire";
import ManageSuppliers from "containers/vendor-management/ManageSuppliers/ManageSuppliers";
import ReviewManagerAssessment from "./containers/cyber-assessment/ManageAssessments/NewReviewAssessment/ReviewManagerAssessment";
import ApproveRegulation from "./containers/manage-regulation/NewRegulation/ApproveRegulation";
import AssignAssessment from "./containers/cyber-assessment/ManageAssessments/NewAssignAssessment/AssignAssessment";
import UploadRegulatoryForm from "./containers/manage-regulation/NewRegulation/UploadRegulatoryForm";
import UploadDataForm from "./containers/cyber-assessment/UploadDataForm";
import QuestionsMapping from "./containers/manage-regulation/NewRegulation/QuestionsMapping";
import Notifications from "./containers/Notifications/index";
import ManageQuestions from "./containers/manage-regulation/NewRegulation/ManageQuestions";
import IndividualQuestion from "./containers/manage-regulation/NewRegulation/IndividualQuestion/index";
import PrivateRoute from "./_components/PrivateRoute";
import NoAuthRoute from "./_components/NoAuthRoute";
import Layout from "./_components/Layout";
import ViewAllEvidence from "./containers/cyber-assessment/AssessmentEvidence/index";
import ManageTeam from "./containers/cyber-assessment/ManageTeam";
import Calendar from "containers/Calendar/Calendar";
import Regulators from "containers/manage-regulation/Regulators";
import PolicyExplorer from "containers/manage-regulation/Policy/PolicyExplorer";
import PolicyComparison from "containers/manage-regulation/Policy/PolicyComparison";
import ComparisonDetail from "containers/manage-regulation/Policy/PolicyComparison/ComparisonDetail";
import ComparisonChart from "containers/manage-regulation/Policy/PolicyComparison/ComparisonChart";
import ExecuteComparison from "containers/manage-regulation/Policy/ExecuteComparison";
import ConfigurationManagement from "containers/user-administration/ConfigurationManagement/index";
import ForgotPassword from "containers/change-password/ForgotPassword";
import ResetPassword from "containers/change-password/ResetPassword";
import Audits from "containers/cyber-assessment/Audits/Audits";
import AuditAssessment from "containers/cyber-assessment/Audits/components/AuditAssessment";
import UploadEvidences from "containers/cyber-assessment/UploadEvidences/UploadEvidences";
import TeamProgress from "containers/Reporting/TeamProgress/TeamProgress";
import ReportingDashboard from "containers/Reporting/ReportingDashboard/ReportingDashboard";
import ZerotrustAssessment from "containers/Insights/ZeroTrustAssessment/ZeroTrustAssessment";
import DataQuality from "containers/Insights/DataQuality/DataQuality";
import ThreatIntelligence from "containers/Insights/ThreatIntelligence/ThreatIntelligence";
import BreachReporting from "containers/Insights/BreachReporting/BreachReporting";
import ComparisonInsights from "containers/manage-regulation/Policy/PolicyComparison/ComparisonInsights";
import PolicyInsightsGraph from "containers/manage-regulation/Policy/PolicyExplorer/component/PolicyDataTable/component/policyInsightsGraph";
import PolicyComparisonOverview from "containers/manage-regulation/Policy/PolicyComparisonOverview";
import StatusGraph from "containers/manage-regulation/Policy/PolicyComparison/ComparisonDetail/StatusGraph";
import StatisticsGraph from "containers/manage-regulation/Policy/PolicyComparison/ComparisonDetail/StatisticsGraph";
import NetworkGraph from "containers/manage-regulation/NewRegulation/ManageQuestions/component/NetworkGraph";
import AuditActions from "containers/cyber-assessment/AuditActions/index";
import ActionListDetails from "containers/cyber-assessment/AuditActions/components/ListDetails";
import ComparisonGraph from "containers/manage-regulation/Policy/PolicyComparison/ComparisonGraph";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { getMuiTheme } from "./theme";
import AdminConsole from "containers/AdminConsole";
import AssessmentDashboardWrapper from "_components/AssessmentDashboardView";
import ComplianceCenter from "containers/ComplianceCenter";
import UploadDocuments from "containers/manage-regulation/NewRegulation/ManageQuestions/component/UploadDocuments";
import Page404 from "_components/Layout/Page404";

const App = () => {
  const themeDetails = useSelector(getThemeDetails);
  const theme = getMuiTheme({
    actionButttonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
    tableHeaderColor: themeDetails?.TABLE_HEADER_COLOUR,
    // themeDetails?.TOPBAR_BGROUND_COLOUR
  });
  const Timeout = useSelector(
    (state) => state?.authentication?.detailedInfo?.LOGIN_TIMEOUT
  );
  const LicenseExpiryDate = useSelector(
    (state) => state?.authentication?.detailedInfo?.LICENCE_EXPIRY_DATE
  );
  const userID = sessionService.getUserId();
  const dispatch = useDispatch();
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (LicenseExpiryDate) {
      let checkIsExpiryToday =
        new Date().toDateString() ===
        new Date(LicenseExpiryDate).toDateString();
      if (checkIsExpiryToday) {
        if (userID) {
          return dispatch(
            alertActions.error(
              "Your Licence is expiring today. Please contact your system administrator."
            )
          );
        }
      }

      let checkIsExpiry = new Date() > new Date(LicenseExpiryDate);
      if (checkIsExpiry) {
        // Login cannot be complete as the Licence key is not valid.  Please contact your system administrator.
        if (userID) {
          dispatch(
            alertActions.error(
              "Login cannot be complete as the Licence has expired.  Please contact your system administrator."
            )
          );
          dispatch(userActions.logout(userID));
          return;
        }
      }
    }
  }, [LicenseExpiryDate]);

  useEffect(() => {
    if (userID) {
      const params = {
        userID: userID,
      };
      // dispatch(userDataActions.getUserDetails(params));
      dispatch(userDataActions.getUserImageDetails({ i_USER_ID: userID }));
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={Dashboard}
              entitlement="HOME_PAGE"
            />
            <PrivateRoute
              exact
              path="/calendar"
              component={Calendar}
              entitlement=""
            />
            <PrivateRoute
              exact
              path="/new-regulation"
              component={NewRegulation}
              entitlement="MANAGE_REGULATIONS"
            />
            <PrivateRoute
              exact
              path="/my-assessments/view-all-evidence/:id/:sid"
              component={ViewAllEvidence}
              entitlement="CYBER_ASSESSMENT"
            />
            <PrivateRoute
              exact
              path="/vendor-assessment/view-all-evidence/:id"
              component={ViewAllEvidence}
              entitlement="VENDOR_MANAGEMENT/VRM"
            />
            <PrivateRoute
              exact
              path="/my-assessments"
              component={MyAssessments}
              entitlement="CYBER_ASSESSMENT"
            />
            <PrivateRoute
              exact
              path="/my-assessments/:id/:sid"
              component={InitiateMyAssessment}
              entitlement="CYBER_ASSESSMENT"
            />
            <PrivateRoute
              exact
              path="/upload-data"
              component={UploadDataForm}
              entitlement="CYBER_ASSESSMENT/UPLOAD_DATA"
            />
            <PrivateRoute
              exact
              path="/manage-assignments"
              component={ManageAssessments}
              entitlement="CYBER_ASSESSMENT/MANAGE_TEAM"
            />
            <PrivateRoute
              exact
              path="/dashboard-view/:id/:rid"
              component={AssessmentDashboardWrapper}
              entitlement="CYBER_ASSESSMENT/MY_ASSESSMENTS"
            />
            <PrivateRoute
              exact
              path="/manage-rfis/regulation-rfis/:assessmentId"
              component={ManageRfiProcess}
              entitlement="CYBER_ASSESSMENT/MANAGE_RFIS"
            />
            <PrivateRoute exact path="/manage-rfis" component={ManageRfi} />
            <PrivateRoute
              exact
              path="/vendor-assessment"
              component={VendorRiskManagement}
              entitlement="VENDOR_MANAGEMENT/VRM"
            />
            <PrivateRoute
              exact
              path="/vendor-assessment/:id/:rid"
              component={InitiateVendorAssessment}
              entitlement="VENDOR_MANAGEMENT/VRM"
            />
            <PrivateRoute
              exact
              path="/supplier-questionnaire"
              component={SupplierQuestionnaire}
              entitlement="VENDOR_MANAGEMENT/SQM"
            />
            <PrivateRoute
              exact
              path="/vendor-supplier"
              component={ManageSuppliers}
              entitlement="VENDOR_MANAGEMENT/SUPPLIER_LIST"
            />
            <PrivateRoute
              exact
              path="/supplier-questionnaire/:id/:rid"
              component={InitiateSupplierAssessment}
              entitlement="VENDOR_MANAGEMENT/SQM"
            />
            <PrivateRoute
              exact
              path="/manage-assignments/assign-assignments"
              component={AssignAssessment}
              entitlement="CYBER_ASSESSMENT/MANAGE_TEAM"
            />
            <PrivateRoute
              exact
              path="/manage-assignments/assign-assignments/:id"
              component={AssignAssessment}
              entitlement="CYBER_ASSESSMENT/MANAGE_TEAM"
            />
            <PrivateRoute
              exact
              path="/approve-regulation/:id"
              component={ApproveRegulation}
              entitlement="MANAGE_REGULATIONS"
            />
            <PrivateRoute
              exact
              path="/manage-assignments/:id"
              component={ReviewManagerAssessment}
              entitlement="CYBER_ASSESSMENT/MANAGE_TEAM"
            />
            <PrivateRoute
              exact
              path="/new-regulation/upload-regulation"
              component={UploadRegulatoryForm}
              entitlement="MANAGE_REGULATIONS"
            />
            <PrivateRoute
              exact
              path="/new-regulation/:id"
              component={QuestionsMapping}
              entitlement="MANAGE_REGULATIONS/NEW_REGULATION"
            />
            <PrivateRoute
              exact
              path="/notifications"
              component={Notifications}
              entitlement="NOTIFICATIONS/MY_NOTIFICATIONS"
            />
            <PrivateRoute
              exact
              path="/manage-questions/"
              component={NewRegulation}
              entitlement="MANAGE_REGULATIONS/MANAGE_QUESTIONS"
            />
            <PrivateRoute
              exact
              path="/manage-questions/:id"
              component={ManageQuestions}
              entitlement="MANAGE_REGULATIONS/MANAGE_QUESTIONS"
            />
            <PrivateRoute
              exact
              path="/manage-questions/:id/network-graph"
              component={NetworkGraph}
              entitlement="MANAGE_REGULATIONS/MANAGE_QUESTIONS"
            />
            <PrivateRoute
              exact
              path="/manage-questions/:id/upload-documents"
              component={UploadDocuments}
              entitlement="MANAGE_REGULATIONS/MANAGE_QUESTIONS"
            />
            {/* <PrivateRoute
                exact
                path="/manage-questions/:id/:qid"
                component={IndividualQuestion}
              /> */}
            <PrivateRoute
              exact
              path="/regulators"
              component={Regulators}
              entitlement="MANAGE_REGULATIONS/REGULATORS"
            />
            <PrivateRoute
              exact
              path="/policy-explorer"
              component={PolicyExplorer}
              entitlement="POLICY_MANAGEMENT/POLICY_EXPLORER"
            />
            <PrivateRoute
              exact
              path="/policy-comparison-analysis"
              component={PolicyComparison}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/policy-comparison-overview"
              component={PolicyComparisonOverview}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/policy-comparison/:id"
              component={ComparisonDetail}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/comparison-chart/:id"
              component={ComparisonChart}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/comparison-graph/:id"
              component={ComparisonGraph}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/comparison-status/:id"
              component={StatusGraph}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/comparison-statistics/:id"
              component={StatisticsGraph}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/policy-explorer/insights/:id"
              component={PolicyInsightsGraph}
              entitlement="INSIGHTS"
            />
            <PrivateRoute
              exact
              path="/comparison-insights/:id"
              component={ComparisonInsights}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/execute-policy-comparison"
              component={ExecuteComparison}
              entitlement="POLICY_MANAGEMENT/POLICY_COMPARISONS"
            />
            <PrivateRoute
              exact
              path="/manage-team"
              component={ManageTeam}
              entitlement="USER_ADMINISTRATION/MANAGE_TEAM"
            />
            <PrivateRoute
              exact
              path="/configuration-management"
              component={ConfigurationManagement}
              entitlement="USER_ADMINISTRATION/CONFIGURATION_MANAGEMENT"
            />
            <PrivateRoute
              exact
              path="/upload-evidence"
              component={UploadEvidences}
              entitlement="CYBER_ASSESSMENT"
            />

            <PrivateRoute
              exact
              path="/my-audits"
              component={Audits}
              entitlement="CYBER_ASSESSMENT/MY_AUDITS"
            />

            <PrivateRoute
              exact
              path="/team-progress"
              component={TeamProgress}
            />

            <PrivateRoute
              exact
              path="/my-audits/:rid/:sid"
              component={AuditAssessment}
              entitlement="CYBER_ASSESSMENT/MY_AUDITS"
            />

            <PrivateRoute
              exact
              path="/dashboard"
              component={ReportingDashboard}
              entitlement="REPORTING/DASHBOARD"
            />

            <PrivateRoute
              exact
              path="/my-audits/view-all-evidence/:id/:sid"
              component={ViewAllEvidence}
              entitlement="CYBER_ASSESSMENT/MY_AUDITS"
            />

            <PrivateRoute
              exact
              path="/zero-trust-assessment"
              component={ZerotrustAssessment}
              entitlement="INSIGHTS/ZERO_TRUST_ASSESSMENT"
            />

            <PrivateRoute
              exact
              path="/data-quality"
              component={DataQuality}
              entitlement="INSIGHTS/DATA_QUALITY"
            />

            <PrivateRoute
              exact
              path="/threat-intelligence"
              component={ThreatIntelligence}
              entitlement="INSIGHTS/THREAT_INTELLIGENCE"
            />

            <PrivateRoute
              exact
              path="/breach-reporting"
              component={BreachReporting}
              entitlement="INSIGHTS/BREACH_REPORTING"
            />

            <PrivateRoute
              exact
              path="/audit-actions"
              component={AuditActions}
              entitlement="CYBER_ASSESSMENT/AUDIT_ACTIONS"
            />

            <PrivateRoute
              exact
              path="/audit-actions/:ACTIONS_LIST_ID"
              component={ActionListDetails}
              entitlement="CYBER_ASSESSMENT/AUDIT_ACTIONS"
            />
            <PrivateRoute
              exact
              path="/admin-console"
              component={AdminConsole}
              entitlement="ADMIN_CONSOLE"
            />
            <PrivateRoute
              exact
              path="/help-section"
              component={HelpPortal}
              entitlement=""
            />
            <PrivateRoute
              exact
              path="/compliance"
              component={ComplianceCenter}
              entitlement="REGULATION_COMPLIANCE_CENTRE"
            />
            {/* <NoAuthRoute path="/forgot-password" component={ForgotPassword} /> */}
            <NoAuthRoute path="/reset-password" component={ResetPassword} />
            <NoAuthRoute path="/login" component={LoginPage} />
            <Route path="*" component={Page404} />
          </Switch>
        </Layout>
      </Router>

      {Timeout !== undefined && (
        <TimeOut Timeout={Timeout} setIsIdle={setIsIdle} />
      )}
      <DialogBox
        maxWidth={"sm"}
        title={"warning"}
        open={isIdle}
        handleClose={() => {
          setIsIdle(false);
        }}
      >
        <Box style={{ textAlign: "center" }}>
          <Box style={{ marginBottom: "20px" }}>
            <Typography>You have been logged out of the system</Typography>
          </Box>

          <Box>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="small"
              style={{ width: "100px" }}
              onClick={() => {
                setIsIdle(false);
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </DialogBox>
    </MuiThemeProvider>
  );
};

export default App;
