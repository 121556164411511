import React, { useState } from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import langConstant from "_lang";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box, Divider, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from "prop-types";
import Checkbox from '@mui/material/Checkbox';
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useSelector } from 'react-redux';
import { getThemeDetails } from 'containers/LoginPage/reducers';


const useStyles = makeStyles(() => ({
    inputProfileField: {
        padding: "7px 5px !important",
        width: "192px",
        maxWidth: "100%",
        color: "#000",
    },
    BoxPages: {
        padding: "15px 10px",
        boxShadow: "inset rgb(0 0 0 / 17%) 0px 0px 14px 2px;",
        // marginRight:"30px",
        position: "relative",
        overflowY: "auto",
        height: "650px",
    },
    sentencesList: {
        paddingLeft:"0",
        paddingRight:"0",
        '& .MuiButtonBase-root':{
            width:'100%',
            '& .MuiGrid-root':{
                width:'100%',
            }
        },
    },
    sentenceShort: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        textOverflow: 'ellipsis',
    },
    AccordionDetailsSpace : {
        paddingLeft:"8px !important",
        paddingRight:"0 !important",
    },
    headerFont: {
        fontWeight: "500"
    }
}))


const BasePolicySideBar = ({
    sentences, 
    setSelectedBaseRows, 
    setBaseFilterText, 
    selectedBaseRows, 
    setExpandView, 
    expandView, 
    // tokenizedSentences
}) => {   
    const classes = useStyles()
    const themeDetails = useSelector(getThemeDetails);
    const [showText, setShowText] = useState(false)

    const columnData = [
        {
            key: 'NAME',
            label: langConstant.SENTENCE,
            format: function formatData(value) {
                // let bodyContent = tokenizedSentences.find((item) => item.POLICY_EXTRACTION_DETAILS_ID == value['POLICY_EXTRACTION_DETAILS_ID'])
                let bodyContent = []
                if(value['SENTENCES']){
                    bodyContent = JSON.parse(value['SENTENCES'])
                }

                return <>
                    {value['ITEM_BODY_CONTENT'] && showText ?
                        <Accordion style={{ boxShadow: "none", backgroundColor:"transparent"}}>
                            <AccordionSummary 
                                style={{ padding: '0px', margin: '0px 0px' }} 
                                expandIcon={value['ITEM_BODY_CONTENT'] ? <ExpandMoreIcon /> : null}
                            >
                                <Typography className={classes.headerFont}>{value['NAME']}</Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.AccordionDetailsSpace}>
                                {bodyContent.map((content, contentIndex) => {
                                    return (
                                        <Grid key={contentIndex} className={classes.AccordionContent}>
                                            <Typography style={{fontSize: "14px"}} className={classes.sentenceShort}>{content}</Typography>
                                            {contentIndex !== bodyContent.length - 1 && <Divider />}
                                        </Grid>
                                    )
                                })}
                            </AccordionDetails>
                        </Accordion> :
                        <Typography className={classes.headerFont}>{value['NAME']}</Typography>
                    }
                </>
            }
        },
    ]

    return (
        <Grid>
            <Grid container style={{padding: "5.5px 0px"}}>
                <Grid item xs={9} md={9} style={{display: "flex",alignItems:"center", textAlign: "left", padding: "0px 0px"}}>
                    <Checkbox 
                        value={showText} 
                        onClick={() => setShowText(!showText)}
                        sx={{
                            '&.Mui-checked': {
                              color: "#124571",
                            },
                        }}
                    /> 
                    <Typography style={{fontWeight:"500"}}>{langConstant.SHOW_TEXT}</Typography>
                </Grid>
                <Grid item xs={3} md={3} style={{alignItems: "center", textAlign: "right"}}>
                    {expandView ? 
                        <IconButton style={{color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR, fontWeight: "bold"}} onClick={() => setExpandView(false)}>
                            <KeyboardDoubleArrowLeftIcon />
                        </IconButton> :
                        <IconButton style={{color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR, fontWeight: "bold"}} onClick={() => setExpandView(true)}>
                            <KeyboardDoubleArrowRightIcon />
                        </IconButton>
                    }
                    
                </Grid>
            </Grid>
            <Box className={classes.BoxPages} >
                <Grid >
                    <Grid>
                        <TextField
                            variant='outlined'
                            className={classes.customInputBox}
                            InputProps={{
                                classes: { input: classes.inputProfileField },
                            }}
                            onChange={(e) => setBaseFilterText(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    {sentences && sentences.length ?  
                        <List>
                            {sentences.map((item, index) => {
                                    return (
                                        <ListItem 
                                            className={classes.sentencesList} 
                                            style={{
                                                position:'relative', 
                                            }} 
                                            key={index} 
                                            onClick={() => setSelectedBaseRows([item])}
                                        >
                                            <ListItemButton style={{
                                                backgroundColor: item.POLICY_EXTRACTION_DETAILS_ID === selectedBaseRows[0].POLICY_EXTRACTION_DETAILS_ID ? "#6e99bf40" : "" ,
                                                borderRadius: "15px"
                                            }}>
                                                {columnData.map((column, columnIndex) => {
                                                    return (
                                                        <Grid key={columnIndex}>
                                                            {column.format ? column.format(item) : item[column['key']]}
                                                        </Grid>
                                                    )
                                                })}
                                            </ListItemButton>
                                        </ListItem>
                                    )
                            })} 
                        </List> :
                        <Grid>
                            <Typography className={classes.NoContent}>{langConstant.NO_CONTENT_EXTRACTED}</Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}

BasePolicySideBar.propTypes = {
    selectedBaseRows: PropTypes.array,
    sentences: PropTypes.array,
    setSelectedBaseRows: PropTypes.func,
    setBaseFilterText: PropTypes.func,
    setExpandView: PropTypes.func,
    expandView: PropTypes.bool,
    // tokenizedSentences: PropTypes.array,
}

export default BasePolicySideBar;

