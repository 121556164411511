import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import {
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { Button } from "@material-ui/core";
import SendIcon from "@mui/icons-material/Send";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import SettingsIcon from "@mui/icons-material/Settings";
import PrivacySettingPrompt from "./PrivacySettingPrompt";
import classNames from "classnames";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import ChatBotServices from "./services";
import sessionService from "_services/session.service";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "_actions";
import BouncingLoader from "./BouncingLoader";
import langConstant from "_lang";
import { getThemeDetails } from "containers/LoginPage/reducers";

const ChatBox = ({
  handleClose,
  isChatBoxAvailable,
  selectedPolicy = {},
  entitlement,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const scrollRef = useRef(null);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const assistantData = useSelector(
    (state) => state?.authentication?.detailedInfo?.ASSISTANT_DATA
  );
  const dispatch = useDispatch();
  const customerID = sessionService.getCustomerId();
  const userID = sessionService.getUserId();
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [isExpandView, setIsExpandView] = useState(false);
  const [sanctionKeywords, setSanctionKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chatMetaData, setChatMetaData] = useState({
    isAutoSave: false,
    privacyId: null,
  });
  const [useCaseID, setUseCaseID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [query, setQuery] = useState("");

  const [chatHistory, setChatHistory] = useState([
    { messageBy: "chatBot", message: "Hello How Can I Help You ?" },
  ]);

  useEffect(() => {
    (async () => {
      const payload = { i_CUSTOMER_ID: customerID };
      const response = await ChatBotServices.getSanctionKeywords(payload);
      if (response.status === 200) {
        if (response["#result-set-1"].length) {
          let tempSanctionKeywords = [];
          response["#result-set-1"].forEach((element) => {
            tempSanctionKeywords.push(element.KEYWORD);
          });
          setSanctionKeywords(tempSanctionKeywords);
        }
      }
      if (assistantData.length) {
        const assistant = assistantData.find(
          (item) => item.ENTITLEMENT_NAME === entitlement
        );
        setUseCaseID(assistant["ASSISTANT_ID"]);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedPolicy?.POLICY_ID) {
      dispatch(
        ChatBotServices.getChatAuditLogs(
          selectedPolicy.POLICY_ID,
          setChatHistory
        )
      );
    }
  }, [selectedPolicy]);

  const getChatAnswers = async () => {
    let matchedData = [];
    if (sanctionKeywords.length > 0) {
      const searchRegex = new RegExp(sanctionKeywords.join("|"), "g");
      matchedData = query.toLowerCase().match(searchRegex);
    }
    if (matchedData && matchedData.length > 0) {
      dispatch(alertActions.error(langConstant.INVALID_PROMPT));
    } else {
      if (query) {
        setChatHistory((prevState) => [
          ...prevState,
          { messageBy: "you", message: query },
        ]);
        const payload = {
          // StoragePath: selectedPolicy.STORAGE_FOLDER_URL,
          DocumentId: selectedPolicy?.POLICY_ID ? selectedPolicy.POLICY_ID : "",
          Query: query,
          // PolicyID: selectedPolicy.POLICY_ID,
        };
        setQuery("");
        setIsLoading(true);
        const response = await ChatBotServices.getChatBotAnswer(payload);
        setIsLoading(false);
        if (response.status) {
          if (response.status == 200) {
            if (response.data && response.data.answer) {
              setChatHistory((prevState) => [
                ...prevState,
                { messageBy: "chatBot", message: response.data.answer },
              ]);
              if (scrollRef.current) {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }
              await updateChatAudit(payload["Query"], response.data.answer);
            }
          } else {
            dispatch(alertActions.error(response.message));
          }
        } else {
          if (response.data && response.data.message) {
            dispatch(
              alertActions.error(
                response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(langConstant.SOMETHING_WENT_WRONG));
          }
        }
      }
    }
  };

  const viewHandleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const viewHandleClose = () => {
    setAnchorEl(null);
    setChatMetaData({ isAutoSave: true, privacyId: null });
  };

  const updateChatAudit = async (prompt, answer) => {
    const payload = {
      i_USER_ID: userID,
      i_CUSTOMER_ID: customerID,
      i_PROMPT: prompt,
      i_RESPONSE: answer,
      i_USE_CASE_ID: useCaseID,
      i_CONTEXT_ID:
        Object.keys(selectedPolicy).length > 0 ? selectedPolicy.POLICY_ID : "",
      i_LOG_TO_PERSONAL_WORKBOOK: chatMetaData.isAutoSave,
      i_PRIVACY_ID: chatMetaData.privacyId,
    };
    await ChatBotServices.putChatAuditLog(payload);
  };

  if (scrollRef.current) {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Box
      className={
        isChatBoxAvailable
          ? !isExpandView
            ? classNames(classes.chatBox, classes.ChatBoxShow)
            : classNames(
                classes.chatBox,
                classes.ChatBoxShow,
                classes.extendedView
              )
          : classes.chatBox
      }
    >
      <div className={classes.chatboxWrap}>
        <div className={classes.chatboxheader}>
          <div className={classes.chatboxicon}>
            <div className={classes.chatStatus}></div>
            <HeadsetMicIcon style={{ color: "white" }} />
          </div>
          <div className={classes.chatboxnamewrap}>
            <h3 className={classes.chatName}> Reggie </h3>
            <p className={classes.chatonline}> Online </p>
          </div>
          <div
            className={classes.chatclose}
            onClick={() => {
              handleClose();
              setIsExpandView(false);
              setIsChatStarted(false);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={classes.conversation}>
          {chatHistory.map((chat, chatIndex) => {
            return (
              <React.Fragment key={chatIndex}>
                {chat.messageBy === "chatBot" ? (
                  <>
                    <div className={classes.message}>
                      <div className={classes.caption}>
                        <div className={classes.avatar}>
                          <img
                            src="https://cdn.chatbot.com/widget/61f28451fdd7c5000728b4f9/FPBAPaZFOOqqiCbV.png"
                            alt=""
                            className={classes.avatarImg}
                          ></img>
                        </div>
                        <span className={classes.avatarName}>Reggie</span>
                      </div>
                    </div>
                    <div className={classes.botResponse}>
                      <div className={classes.botRepliesText}>
                        {chat.message}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.messageYou}>
                      <span className={classes.messageYouIcon}>You</span>
                    </div>
                    <div className={classes.inputMessage}>
                      <div className={classes.inputMessageWrapper}>
                        <div className={classes.inputMessageText}>
                          {chat.message}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </React.Fragment>
            );
          })}
          <div ref={scrollRef} />
        </div>
        {isChatStarted ? (
          <div>
            {isLoading && (
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  paddingLeft: "10px",
                }}
              >
                <span>{langConstant.REGGIE_IS_TYPING}</span>
                <BouncingLoader />
              </Grid>
            )}
            <Grid container>
              <Grid item xs={10}>
                <Checkbox
                  checked={chatMetaData.isAutoSave}
                  onClick={(e) =>
                    setChatMetaData((prevState) => ({
                      ...prevState,
                      isAutoSave: e.target.checked,
                    }))
                  }
                  sx={{
                    "&.Mui-checked": { color: "#124571" },
                    display: "inline",
                  }}
                  size="small"
                />
                <span style={{ fontWeight: "bold" }}>
                  {langConstant.AUTOSAVE_CHAT_TO_MY_WORKBOOK}
                </span>
              </Grid>
              <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
                <AspectRatioIcon
                  onClick={() => setIsExpandView(!isExpandView)}
                  style={{ cursor: "pointer", paddingRight: "5px" }}
                />
                <SettingsIcon
                  onClick={viewHandleClick}
                  style={{ cursor: "pointer" }}
                />
                <PrivacySettingPrompt
                  handleClose={viewHandleClose}
                  anchorEl={anchorEl}
                  setChatMetaData={setChatMetaData}
                  chatMetaData={chatMetaData}
                />
              </Grid>
            </Grid>
            <div className={classes.typing}>
              <TextField
                placeholder={"Type your question here"}
                value={query}
                className={classes.chatInputWrapper}
                disabled={isLoading}
                onChange={(e) => setQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled={isLoading}>
                        <SendIcon
                          onClick={() => {
                            if (scrollRef.current) {
                              scrollRef.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                            getChatAnswers();
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  classes: { notchedOutline: classes.borderNone },
                }}
              />
            </div>
          </div>
        ) : (
          <div className={classes.startAgain}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setIsChatStarted(true)}
            >
              {langConstant.START_THE_CHAT_AGAIN}
            </Button>
          </div>
        )}
        <div className={classes.poweredBy}>
          <span className={classes.poweredName}>
            Powered by{" "}
            <a href="#" className={classes.poweredLink}>
              Regulativ
            </a>
          </span>
        </div>
      </div>
    </Box>
  );
};

ChatBox.propTypes = {
  handleClose: PropTypes.func,
  isChatBoxAvailable: PropTypes.bool,
  selectedPolicy: PropTypes.object,
  entitlement: PropTypes.string,
};

export default ChatBox;
