import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import RadialSeparators from "../CirculerSpeedDialProgress/RadialSeprator";
import propTypes from "prop-types";
import {
  Assignment,
  AssignmentReturn,
  FactCheck,
  Info,
  PendingActions,
} from "@mui/icons-material";
import { styles } from "../styles";
import { TileCard } from "./TileCard";
import "react-circular-progressbar/dist/styles.css";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { useSelector } from "react-redux";
import { GradientSVG } from "../CirculerSpeedDialProgress/GradientSVG";
import sessionService from "_services/session.service";
import SegmentIcon from "@mui/icons-material/Segment";

const InfoTooltip = ({ message }) => (
  <Tooltip placement="left" sx={{ zIndex: 4 }} title={message}>
    <IconButton
      size="small"
      sx={{ top: -2, right: -2, position: "absolute", zIndex: 4 }}
    >
      <Info fontSize="small" />
    </IconButton>
  </Tooltip>
);

export const TopBarView = ({
  kpiData,
  assessmentDetails,
  assessmentKpiData,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const userId = sessionService.getUserId();
  return (
    <Grid container spacing={2}>
      <Grid md={4} item display="flex" gap={2}>
        <Box display="flex" sx={styles.whiteWrapper} alignItems="center">
          <Avatar
            alt="assessment-priority"
            sx={{
              ...styles.avatarColor[assessmentDetails?.PRIORITY],
              fontWeight: "bold",
              width: 56,

              fontSize: 30,
            }}
            variant="rounded"
          >
            {assessmentDetails?.PRIORITY}
          </Avatar>
        </Box>
        <Box
          sx={styles.whiteWrapper}
          display="flex"
          flex={1}
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <Box display="flex" flex={1} flexDirection="column" gap={2}>
            <Typography variant="body2" fontSize="1rem">
              Assesment Name -
              <strong>{assessmentDetails?.REGULATION_SHORT_NAME}</strong>
            </Typography>
            <Typography variant="body2" fontSize="1rem">
              Legel Entity -{" "}
              <strong>{assessmentDetails?.LEGAL_ENTITY_NAME}</strong>
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display="flex" flex={1} flexDirection="column" gap={2}>
            <Typography variant="body2" fontSize="1rem">
              Assigned To - <strong>{assessmentDetails?.USER_NAME}</strong>
            </Typography>
            <Typography variant="body2" fontSize="1rem">
              Assigned By -{" "}
              <strong>{assessmentDetails?.ASSIGNRD_BY_USER_NAME}</strong>
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid md={6} item display="flex" gap={2}>
        <TileCard
          label="Total Controls"
          count={assessmentKpiData?.ALL_QUESTION}
          icon={<Assignment sx={{ fontSize: 44 }} />}
          color="blue"
        />
        <TileCard
          label="Controls In Progress"
          count={kpiData?.progress}
          icon={<SegmentIcon sx={{ fontSize: 44 }} />}
          color="yellowgreen"
        />
        <TileCard
          label="Sent Back"
          count={assessmentKpiData?.SENT_BACK}
          icon={<AssignmentReturn sx={{ fontSize: 44 }} />}
          color="orange"
        />
        <TileCard
          label="Audit Observations"
          count={assessmentKpiData?.AUDIT_OBSERVATIONS}
          icon={<PendingActions sx={{ fontSize: 44 }} />}
          color="cadetblue"
        />
        <TileCard
          label="Completed"
          count={assessmentKpiData?.COMPLETED}
          icon={<FactCheck sx={{ fontSize: 44 }} />}
          color="green"
        />
      </Grid>
      <Grid md item display="flex" p={0} pr="0px !important">
        <Box
          display="flex"
          flex={1}
          gap={1}
          p={1}
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
          bgcolor="#fff"
          position="relative"
        >
          {/* <CircularProgressbarWithChildren
                circleRatio={0.6}
                value={10}
                text={`${kpiData?.progress}%`}
                strokeWidth={10}
                styles={buildStyles({
                  rotation: 0.7,
                  strokeLinecap: "butt",
                  pathColor: `url(#id-${userId})`,
                  textColor: "#000",
                  textSize: "1rem",
                })}
              >
                <GradientSVG id={`id-${userId}`} />
                <RadialSeparators
                  count={13}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${10}%`,
                  }}
                />
              </CircularProgressbarWithChildren> */}
          <InfoTooltip message="Shows count of all controls which are Completed, Completed and Locked, Final" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              flex: 1,
              position: "relative",
              // mt:4,
              height: "6rem",
            }}
          >
            <CircularProgressbarWithChildren
              circleRatio={0.6}
              value={kpiData?.completedPercentage}
              text={`${kpiData?.completedPercentage}%`}
              strokeWidth={10}
              styles={buildStyles({
                rotation: 0.7,
                strokeLinecap: "butt",
                pathColor: `url(#id-${userId})`,
                textColor: "#000",
                textSize: "1rem",
              })}
            >
              <GradientSVG id={`id-${userId}`} />
              <RadialSeparators
                count={13}
                style={{
                  background: "#fff",
                  width: "2px",
                  // This needs to be equal to props.strokeWidth
                  height: `${10}%`,
                }}
              />
            </CircularProgressbarWithChildren>
          </Box>
          <Typography
            sx={{ zIndex: 2, fontSize: "1rem", textAlign: "center", flex: 1 }}
          >
            Completed Controls
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

InfoTooltip.propTypes = {
  message: propTypes.string.isRequired,
};

TopBarView.propTypes = {
  kpiData: propTypes.object,
  assessmentDetails: propTypes.object,
  assessmentKpiData: propTypes.object,
};
