import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import langConstant from "_lang";
import PropTypes from "prop-types";
import classNames from 'classnames';
import useStyles from './styles';


const DataTable = ({columns, filteredRows, selectedPolicies, selectPoliciesToCompare, filterData, setFilterData, policyPreview, rowsPerPage, page}) => {

    const classes = useStyles();

    const sortByID = () => {
        if (filterData.orderByID === '') {
            setFilterData({ ...filterData, orderByID: "ASC" })
        } else {
            if (filterData.orderByID === "ASC") {
                setFilterData({ ...filterData, orderByID: "DESC" })
            } else {
                setFilterData({ ...filterData, orderByID: "ASC" })
            }
        }
    }

    return (
        <TableContainer component={Paper} className={classes.marginTop}>
            <Table className="data-table">
                <TableHead>
                    <TableRow>
                        <TableCell component="th" scope="row" className={classes.stickyColl} />
                        {columns.map((item, index) => (
                            <TableCell
                                component="th"
                                scope="row"
                                key={"column_" + index + item.field}
                                className={index === 0 ? classNames(classes.stickyColl, classes.cursorPointer) : classes.stickyColl}
                                style={{ minWidth: item.minWidth }}
                                onClick={() => {
                                    if (index === 0) {
                                        setFilterData({ ...filterData, orderByID: '' })
                                    }
                                }}
                            >
                                {item.headerName}

                                {
                                    index === 0 && (
                                        <TableSortLabel
                                            active={filterData.orderByID !== '' ? true : false}
                                            onClick={(e) => { e.stopPropagation(); sortByID(); }}
                                            direction={filterData.orderByID ? (filterData.orderByID === "ASC" ? "desc" : "asc") : "asc"}
                                        ></TableSortLabel>
                                    )
                                }

                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? filteredRows.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                        : filteredRows).map((row, rowIndex) => (
                        <TableRow 
                            key={"row_" + rowIndex} 
                            style={{backgroundColor: policyPreview.isOpen && policyPreview.data.POLICY_ID === row['POLICY_ID'] ? "#a9ffa9" : ""}}
                        >
                            <TableCell component="td" scope="row">
                                <input
                                    type="checkbox"
                                    style={{accentColor: "#124571"}}
                                    checked={selectedPolicies.includes(row[columns[0].field])}
                                    onChange={(e) => selectPoliciesToCompare(row[columns[0].field], e.target.checked, row)}
                                />
                            </TableCell>
                            {columns.map((column, index) => {
                                const value = row[column.field];
                                return (
                                    <TableCell
                                        component="td"
                                        scope="row"
                                        key={"column_" + index + row[columns[0].field]}
                                    >
                                        {column.format ? column.format(value) : value}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                    {filteredRows.length === 0 &&
                        <TableRow style={{ height: 53 }}>
                            <TableCell
                                style={{ textAlign: "center" }}
                                colSpan={columns.length + 1}
                            >
                                {langConstant.NO_RECORD_FOUND}
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    selectedPolicies: PropTypes.array,
    filteredRows: PropTypes.array,
    selectPoliciesToCompare: PropTypes.func,
    filterData: PropTypes.object,
    setFilterData: PropTypes.func,
    policyPreview: PropTypes.object,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number
}

export default DataTable;