/* COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. */

import React from "react";
import {
  Link as RouterLink,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Route } from "react-router-dom";
import { makeStyles, Typography, Box, Breadcrumbs } from "@material-ui/core";
import langConstant from "_lang";
import { useSelector, useDispatch } from "react-redux";
import manageQuestionsAction from "../containers/manage-regulation/NewRegulation/ManageQuestions/actions";
import Stepper from "_components/Stepper/Stepper";
import sessionService from "_services/session.service";
import { getNavigationPathList } from "containers/LoginPage/reducers";

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    fontSize: "0.875rem",
    marginTop: "10px",
  },
  link: {
    color: "#007BFF",
    textDecoration: "none",
  },
  filedTitle: {
    fontWeight: "500",
    padding: "0 5px",
  },
}));

const ShowStepperArray = [
  "/my-audits/:rid/:sid",
  "/my-assessments/:id/:sid",
  "/policy-explorer",
  "/vendor-assessment/:id/:rid",
];

const AppBreadcrumbs = () => {
  const isMatch = useRouteMatch(ShowStepperArray);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const customerID = sessionService.getCustomerId();

  const manageQuestions = useSelector((state) => state.manageQuestions);
  const steps = useSelector((state) => state.stepper.steps);
  const entitlementList = useSelector(getNavigationPathList);
  const routesMap = entitlementList?.reduce((acc, item) => {
    if (
      (item?.ENTITLEMENT_LABEL_LEVEL == 2 ||
        item?.ENTITLEMENT_LABEL_LEVEL == 1) &&
      item?.ENTITLEMENT_PATH
    ) {
      let slug = item?.ENTITLEMENT_PATH?.replace("/", ""),
        parentPage =
          entitlementList?.find(
            (i) => i?.ENTITLEMENT_NAME == item?.ENTITLEMENT_PARENT
          )?.ENTITLEMENT_LABEL || item?.ENTITLEMENT_PARENT;
      acc[slug] = {
        parentPage,
        title: item?.ENTITLEMENT_SLUG || item?.ENTITLEMENT_LABEL,
      };
    }
    return acc;
  }, {});

  const userData = useSelector((state) => state.authentication.userData);
  const NavigationDetails = useSelector(
    (state) => state.authentication?.detailedInfo?.NAVIGATION_DETAILS
  );

  const currentRequestedNavDetails = NavigationDetails?.find(
    (entitlement) => entitlement?.ENTITLEMENT_PATH === location.pathname
  );
  const isAllowed =
    userData?.allowedNavItems?.indexOf(
      currentRequestedNavDetails?.ENTITLEMENT_NAME
    ) > -1;

  const hideBreadcrumb = ["/dashboard-view"];

  const detailPages = {
    "manage-questions":
      manageQuestions.regulationDetail &&
      manageQuestions.regulationDetail.REGULATION_NAME
        ? manageQuestions.regulationDetail.REGULATION_NAME
        : "",
  };

  const getRouteBasedTitle = () => {
    const matchingEntitlements = entitlementList?.filter(
      (i) =>
        i?.ENTITLEMENT_PATH?.includes(location.pathname) &&
        location.pathname !== "/"
    );
    return location?.pathname === "/"
      ? matchingEntitlements?.find((i) => i?.ENTITLEMENT_PATH === "/")
          ?.ENTITLEMENT_LABEL || "Home Page"
      : matchingEntitlements?.find(
          (i) => i?.ENTITLEMENT_LABEL_LEVEL != 1 && i?.ENTITLEMENT_PARENT
        )?.ENTITLEMENT_LABEL ||
          matchingEntitlements?.find(
            (i) => i?.ENTITLEMENT_PATH === location.pathname
          )?.ENTITLEMENT_LABEL ||
          "";
  };

  return (
    <Route>
      {({ location }) => {
        const AssessmentName = location?.state?.AssessmentName;
        const pathnames = location.pathname.split("/")?.filter((x) => x);
        const pageSlug = pathnames[0];
        // const parentPage = routesMap?.[pageSlug]
        //   ? routesMap?.[pageSlug]?.parentPage
        //   : "Dashboard";

        // Calling action so that breadcrumb is not empty
        if (
          pageSlug == "manage-questions" &&
          !isNaN(pathnames[1]) &&
          pathnames.length > 2 &&
          manageQuestions.regulationDetail &&
          !manageQuestions.regulationDetail.REGULATION_NAME
        ) {
          dispatch(
            manageQuestionsAction.getMQBAssessments(pathnames[1], customerID)
          );
        }

        const idTitle =
          detailPages[pageSlug] &&
          !isNaN(pathnames[pathnames.length - 1]) &&
          detailPages[pageSlug];

        if (location.pathname.includes(hideBreadcrumb)) return null;

        return (
          <Box
            display="flex"
            flexDirection="column"
            // borderBottom="1px solid #0000001f"
          >
            <Box
              mb={1}
              style={{ display: "flex", justifyContent: "space-between" }}
              flex={1}
            >
              <Breadcrumbs
                aria-label="Breadcrumb"
                className={classes.breadcrumbs}
              >
                {/* <Typography variant="inherit" color="textPrimary" key={1}>
                {parentPage}
              </Typography> */}
                {pathnames?.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                  let title = routesMap?.[value]?.title || value;
                  // Adding condition so that Regulation number doesn't show in manage questions page
                  if (
                    pageSlug == "manage-questions" &&
                    !last &&
                    !isNaN(value)
                  ) {
                    return "";
                  } else {
                    return last ? (
                      <Typography
                        variant="inherit"
                        color="textPrimary"
                        key={to}
                      >
                        {idTitle ? idTitle : title}
                      </Typography>
                    ) : (
                      <RouterLink
                        color="inherit"
                        className={classes.link}
                        to={to}
                        key={to}
                      >
                        {title}
                      </RouterLink>
                    );
                  }
                })}
              </Breadcrumbs>
              <Box style={{ minWidth: "240px", alignSelf: "flex-end" }}>
                {isMatch?.isExact && AssessmentName && (
                  <Typography className={classes.filedTitle}>
                    Assessment Name : {AssessmentName}
                  </Typography>
                )}
              </Box>
              <Box
                style={{
                  height: "20px",
                  width: "26%",
                  minWidth: "240px",
                  alignSelf: "flex-end",
                }}
              >
                {isMatch?.isExact && steps?.length > 0 && <Stepper />}
              </Box>
            </Box>
            {getRouteBasedTitle() && isAllowed && (
              <h1 className="page-title">{getRouteBasedTitle()}</h1>
            )}
          </Box>
        );
      }}
    </Route>
  );
};

export default AppBreadcrumbs;
