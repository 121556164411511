import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import langConstant from "_lang";
import useStyles from "./styles";
import classNames from "classnames";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import {
  ownerColumnList,
  typeColumnList,
  uploadedByColumnList,
} from "../utils";
import { useSelector } from "react-redux";
import { getThemeDetails } from "containers/LoginPage/reducers";

const GroupingDataTable = ({
  groupingData,
  filterData,
  groupRowsData,
  selectedPolicies,
  selectPoliciesToCompare,
  policyPreview,
}) => {
  const themeDetails = useSelector(getThemeDetails);
  const classes = useStyles({
    actionButtonColor: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR,
  });
  const [columns, setColumns] = useState([]);
  const [groupHeaders, setGroupHeaders] = useState([]);

  useEffect(() => {
    if (groupingData && Object.keys(groupingData).length > 0) {
      let filteredGroupRowsData = [...groupRowsData];
      if (filterData.document_option.value == "document_owner") {
        setColumns(ownerColumnList);
        if (filterData.document_owner !== "all") {
          filteredGroupRowsData = filteredGroupRowsData.filter(
            (item) => item.value === filterData.document_owner
          );
        }
      } else if (filterData.document_option.value == "document_type") {
        setColumns(typeColumnList);
        if (filterData.document_type !== "all") {
          filteredGroupRowsData = filteredGroupRowsData.filter(
            (item) => item.value === filterData.document_type
          );
        }
      } else if (filterData.document_option.value == "uploaded_by") {
        setColumns(uploadedByColumnList);
        if (filterData.uploaded_by !== "all") {
          filteredGroupRowsData = filteredGroupRowsData.filter(
            (item) => item.value === filterData.uploaded_by
          );
        }
      }
      let headersData = [];
      filteredGroupRowsData.forEach((item) => {
        if (item.value !== "all") {
          headersData.push({
            ...item,
            isOpen: false,
            rows: 10,
            hasMore:
              groupingData[item.value] && groupingData[item.value].length > 10
                ? true
                : false,
          });
        } else {
          if (filterData.document_option.value !== "uploaded_by") {
            headersData.push({
              ...item,
              value: null,
              isOpen: false,
              name: "No Data",
              rows: 10,
              hasMore:
                groupingData[null] && groupingData[null].length > 10
                  ? true
                  : false,
            });
          }
        }
      });
      headersData = headersData.sort((a, b) => b.value - a.value);
      setGroupHeaders(headersData);
    }
  }, [filterData, groupingData]);

  const updateIsOpen = (header) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == header.value
    );
    tempGroupHeaders[index].isOpen = !tempGroupHeaders[index].isOpen;
    tempGroupHeaders[index].rows = 10;
    if (groupingData[header.value].length > 10) {
      tempGroupHeaders[index].hasMore = true;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  const fetchMoreData = (headerValue) => {
    const tempGroupHeaders = [...groupHeaders];
    const index = tempGroupHeaders.findIndex(
      (item) => item.value == headerValue
    );
    tempGroupHeaders[index].rows = tempGroupHeaders[index].rows + 10;
    if (tempGroupHeaders[index].rows >= groupingData[headerValue].length) {
      tempGroupHeaders[index].hasMore = false;
    }
    setGroupHeaders(tempGroupHeaders);
  };

  return (
    <TableContainer component={Paper} className={classes.marginTop}>
      <Table className="data-table ">
        <TableHead>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              className={classes.stickyColl}
            />
            {filterData.document_option.value === "document_owner" && (
              <TableCell>{langConstant.DOCUMENT_OWNER}</TableCell>
            )}
            {filterData.document_option.value === "document_type" && (
              <TableCell>{langConstant.DOCUMENT_TYPES}</TableCell>
            )}
            {filterData.document_option.value === "uploaded_by" && (
              <TableCell>{langConstant.UPLOADED_BY}</TableCell>
            )}
            {columns.map((item, index) => (
              <TableCell
                component="th"
                scope="row"
                key={"column_" + index + item.field}
                className={
                  index === 0
                    ? classNames(classes.stickyColl, classes.cursorPointer)
                    : classes.stickyColl
                }
                style={{ minWidth: item.minWidth }}
              >
                {item.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {groupHeaders.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ cursor: "pointer" }}
                  >
                    {item.isOpen ? (
                      <ArrowCircleDownIcon
                        style={{ color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR }}
                        onClick={() => updateIsOpen(item)}
                      />
                    ) : (
                      <ArrowCircleRightIcon
                        style={{ color: themeDetails?.ACTIONBUTTON_BGROUND_COLOUR }}
                        onClick={() => updateIsOpen(item)}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    colSpan={10}
                    onClick={() => updateIsOpen(item)}
                    style={{ fontWeight: "bold", cursor: "pointer" }}
                  >
                    {item["name"]}
                    <span className={classes.recordLength}>
                      {groupingData[item["value"]]
                        ? groupingData[item["value"]].length
                        : 0}
                    </span>
                  </TableCell>
                </TableRow>
                {item.isOpen &&
                  groupingData[item["value"]] &&
                  groupingData[item["value"]]
                    .slice(0, item["rows"])
                    .map((policy, policyIndex) => (
                      <TableRow
                        key={policyIndex}
                        style={{
                          backgroundColor:
                            policyPreview.isOpen &&
                            policyPreview.data.POLICY_ID === policy["POLICY_ID"]
                              ? "#a9ffa9"
                              : "",
                        }}
                      >
                        <TableCell component="td" scope="row">
                          <input
                            type="checkbox"
                            checked={selectedPolicies.includes(
                              policy[columns[0].field]
                            )}
                            onChange={(e) =>
                              selectPoliciesToCompare(
                                policy[columns[0].field],
                                e.target.checked,
                                policy
                              )
                            }
                            style={{ accentColor: "#124571" }}
                          />
                        </TableCell>
                        <TableCell component="td" scope="row" />
                        {columns.map((column, index) => {
                          const value = policy[column.field];
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              key={"column_" + index + policyIndex}
                              className={
                                index === 0
                                  ? classNames(
                                      classes.stickyColl,
                                      classes.cursorPointer
                                    )
                                  : classes.stickyColl
                              }
                              style={{ minWidth: item.minWidth }}
                            >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
                {item.isOpen && item.hasMore && (
                  <TableRow>
                    <TableCell
                      colSpan={11}
                      component="td"
                      scope="row"
                      className={classes.loadMore}
                    >
                      <span onClick={() => fetchMoreData(item.value)}>
                        {langConstant.LOAD_MORE}
                      </span>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

GroupingDataTable.propTypes = {
  groupingData: PropTypes.object,
  filterData: PropTypes.object,
  groupRowsData: PropTypes.array,
  selectedPolicies: PropTypes.array,
  selectPoliciesToCompare: PropTypes.func,
  policyPreview: PropTypes.object,
};

export default GroupingDataTable;
