import {
  Dialog,
  Typography,
  Box,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  FormLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import propTypes from "prop-types";
import { commonDialogStyles } from "_components/ActionList/styles";
import { Close, Save } from "@mui/icons-material";
import sessionService from "_services/session.service";
import { PutNotes } from "../../services";
import { alertActions } from "_actions";
import { useDispatch } from "react-redux";
import { linkTypes } from "../../constants";

const getAttachedItem = (action) => {
  if (!action?.ACTIONS_LIST_LINK_ID || !action?.ACTIONS_LIST_TYPE) return null;
  const mapping = {
    [linkTypes[0]]: "i_SUBMISSION_ID",
    [linkTypes[1]]: "i_POLICY_ID",
    [linkTypes[2]]: "i_POLICYCOMPARISON_ID",
    [linkTypes[3]]: "i_REGULATION_ID",
  };
  return {
    [mapping[action?.ACTIONS_LIST_TYPE]]: action?.ACTIONS_LIST_LINK_ID,
  };
};

export const AddNoteDialog = ({ modal, action, submitCallback }) => {
  const dispatch = useDispatch();
  const dialogStyles = commonDialogStyles();
  const userId = sessionService.getUserId();
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (!note) return;
    setIsLoading(true);
    const payload = {
      i_ACTIONS_ID: action?.ACTIONS_ID || null,
      i_CREATED_BY: userId,
      i_NOTES_TEXT: note,
      i_POLICYCOMPARISON_ID: action?.i_POLICYCOMPARISONID || null,
      i_REGULATION_ID: action?.REGULATION_ID,
      i_SUBMISSION_ID: action?.SUBMISSION_ID,
      i_QUESTION_ID: action?.QUESTION_ID,
      i_RFI_ID: action?.RFI_ID,
      i_POLICY_ID: action?.POLICY_ID,
      i_NOTES_STATUS: null,
      i_NOTES_ID: null,
      i_ACTIVE: true,
      i_START_DATE: Date.now().toString(),
      i_END_DATE: Date.now().toString(),
      ...getAttachedItem(action),
    };
    const showError = (error) => {
      dispatch(
        alertActions.error(
          error?.data?.message?.map((item, index) => {
            return <span key={index}>{item}</span>;
          }) ?? error?.toString()
        )
      );
    };
    await PutNotes(payload)
      .then((response) => {
        if (response?.status === 200 && response?.["#update-count-1"]) {
          dispatch(alertActions.success("Note successfully added"));
          setTimeout(() => {
            submitCallback?.();
            modal?.closeModal();
          }, 200);
        } else {
          showError(response);
        }
      })
      .catch((error) => {
        showError(error);
        console.error("error", error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={modal?.closeModal}
      open={modal?.isOpen}
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle disableTypography className={dialogStyles.title}>
        <Typography style={{ flex: 1 }} variant="h6" align="center">
          ADD NOTE
        </Typography>
        <IconButton
          style={{ position: "absolute", right: 4 }}
          aria-label="close"
          onClick={modal?.closeModal}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          marginY="1rem"
          flexDirection="column"
          display="flex"
          style={{ gap: 6 }}
        >
          <FormLabel htmlFor="note" variant="body1">
            Add Note
          </FormLabel>
          <TextField
            value={note}
            id="note"
            multiline
            rows={4}
            variant="outlined"
            onChange={(e) => setNote(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ alignSelf: "center", padding: "1rem" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onSubmit}
          startIcon={isLoading ? <CircularProgress size={16} /> : <Save />}
          disabled={!note || isLoading}
        >
          Save
        </Button>
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={modal?.closeModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  modal: propTypes.object,
  action: propTypes.object,
  submitCallback: propTypes.func,
};
