// OP#2883 - UJA11 & UJA12 - UI Development : Manage Regulators & Regulations List
// services for Regulators

import React from "react";
import { alertActions } from "_actions";
import apiService from "_services/api.service";
import langConstant from "_lang";
import Actions from "./actions";
import moment from "moment";

const getAvailableRegulators = (userId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/GetAvailableRegulators", {
        i_USER_ID: userId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(Actions.getRegulators(response["#result-set-1"]));
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const putRegulator = (data, customerId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/PutRegulator", {
        i_REGULATOR_NAME: data.REGULATOR_NAME,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_REGULATOR_RESPONSE_TYPE_ID: data.REGULATOR_RESPONSE_TYPE_ID,
        i_REGULATOR_WEB_URL: data.REGULATOR_WEB_URL,
        i_REGULATORY_DOMAIN_ID: data.REGULATORY_DOMAIN_ID,
        i_START_DATE: moment().format("YYYY-MM-DD HH:mm:ss"),
        i_REGULATOR_ADDRESS: data.ADDRESS,
        i_END_DATE: "",
        i_ACTIVE: data.ACTIVE,
        i_HORIZON_SCAN: data.HORIZON_SCAN,
        i_CUSTOMER_ID: customerId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          dispatch(
            alertActions.success(langConstant.REGULATOR_SUCCESSFULLY_ADDED)
          );
          if (response["#result-set-2"][0]["new_id"]) {
            data["REGULATOR_ID"] = response["#result-set-2"][0]["new_id"];
            dispatch(Actions.addRegulatorData(data));
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const getRegulatorDetails = (regulatorId, customerId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRegulatorDetails", {
      i_REGULATOR_ID: regulatorId,
      i_CUSTOMER_ID: customerId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getRegulatorKeyPersons = (regulatorId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRegulatorKeyPersons", {
      i_REGULATOR_ID: regulatorId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getRegulatoryDomains = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetRegulatoryDomains")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getRegulatoryStatusAndType = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetRegulationExternalStatus")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getResponseType = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetResponseType")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getCountryDetails = () => {
  return apiService
    .apiCall("dbapi")
    .get("/v1/api/proc/DRegulativDB/GetCountryDetails")
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateRegulator = (data, setRegulatorData, customerId) => {
  return (dispatch) => {
    apiService
      .apiCall("dbapi")
      .send("/v1/api/proc/DRegulativDB/UpdateRegulator", {
        i_REGULATOR_ID: data.REGULATOR_ID,
        i_REGULATOR_NAME: data.REGULATOR_NAME,
        i_COUNTRY_CODE: data.COUNTRY_CODE,
        i_REGULATOR_RESPONSE_TYPE_ID: data.REGULATOR_RESPONSE_TYPE_ID,
        i_REGULATOR_WEB_URL: data.REGULATOR_WEB_URL,
        i_REGULATORY_DOMAIN_ID: data.REGULATORY_DOMAIN_ID,
        i_ADDRESS: data.ADDRESS,
        i_START_DATE: data.START_DATE,
        i_END_DATE: "",
        i_ACTIVE: data.ACTIVE,
        i_HORIZON_SCAN: data.HORIZON_SCAN,
        i_CUSTOMER_ID: customerId,
      })
      .then(apiService.handleResponse)
      .then(
        (response) => {
          if (response["#update-count-1"] === 1) {
            setRegulatorData(data);
            dispatch(Actions.updateRegulatorData(data));
            dispatch(
              alertActions.success(langConstant.REGULATOR_SUCCESSFULLY_UPDATED)
            );
          }
        },
        (error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            dispatch(
              alertActions.error(
                error.response.data.message.map((item, index) => {
                  return <div key={index}>{item}</div>;
                })
              )
            );
          } else {
            dispatch(alertActions.error(error.toString()));
          }
        }
      );
  };
};

const updateRegulatorKeyPerson = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateRegulatorKeyPersons", {
      i_ID: data.ID,
      i_REGULATOR_ID: data.REGULATOR_ID,
      i_KEY_PERSON_NAME: data.KEY_PERSON_NAME,
      i_KEY_PERSON_ROLE: data.KEY_PERSON_ROLE,
      i_KEY_PERSON_EMAIL_ADDRESS: data.KEY_PERSON_EMAIL_ADDRESS,
      i_KEY_PERSON_PHONE_NUMBER: data.KEY_PERSON_PHONE_NUMBER,
      i_ACTIVE: data.ACTIVE,
      i_START_DATE: data.START_DATE,
      i_END_DATE: data.END_DATE,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const addRegulatorKeyPerson = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutRegulatorKeyPersons", {
      i_REGULATOR_ID: data.REGULATOR_ID,
      i_KEY_PERSON_NAME: data.KEY_PERSON_NAME,
      i_KEY_PERSON_ROLE: data.KEY_PERSON_ROLE,
      i_KEY_PERSON_EMAIL_ADDRESS: data.KEY_PERSON_EMAIL_ADDRESS,
      i_KEY_PERSON_PHONE_NUMBER: data.KEY_PERSON_PHONE_NUMBER,
      i_START_DATE: data.START_DATE,
      i_END_DATE: data.END_DATE,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const getRegulationsForRequlator = (regulatorId, customerId) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetRegulationsForRequlator", {
      i_REGULATOR_ID: regulatorId,
      i_CUSTOMER_ID: customerId,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const putRegulationInformation = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/PutregulationInformation", {
      i_REGULATOR_ID: data.REGULATOR_ID,
      i_REGULATION_SHORT_NAME: data.REGULATION_SHORT_NAME,
      i_REGULATION_TYPE: data.REGULATION_TYPE,
      i_COUNTRY_CODE: data.COUNTRY_CODE,
      i_REGULATION_WEB_URL: data.REGULATION_WEB_URL,
      i_REGULATION_STORAGE_FOLDER_URL: data.REGULATION_STORAGE_FOLDER_URL,
      i_STATUS_ID: data.STATUS_ID,
      i_REGULATORY_DOMAIN_ID: data.REGULATORY_DOMAIN_ID,
      i_UPLOAD_DATE: data.UPLOAD_DATE,
      i_START_DATE: data.START_DATE,
      i_END_DATE: data.END_DATE,
      i_CUSTOMER_ID: data.CUSTOMER_ID,
      i_HORIZON_SCAN: data.HORIZON_SCAN,
      i_REGULATION_NAME: data.REGULATION_NAME,
      i_REGULATION_STATUS: data.REGULATION_STATUS,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error.response) {
          return error.response;
        }
      }
    );
};

const updateRegulationInformation = (data) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateregulationInformation", {
      i_REGULATION_ID: data.REGULATION_ID,
      i_REGULATOR_ID: data.REGULATOR_ID,
      i_REGULATION_SHORT_NAME: data.REGULATION_SHORT_NAME,
      i_REGULATION_NAME: data.REGULATION_NAME,
      i_COUNTRY_CODE: data.COUNTRY_CODE,
      i_REGULATION_WEB_URL: data.REGULATION_WEB_URL,
      i_REGULATION_STORAGE_FOLDER_URL: data.REGULATION_STORAGE_FOLDER_URL,
      i_STATUS_ID: data.STATUS_ID,
      i_REGULATORY_DOMAIN_ID: data.REGULATORY_DOMAIN_ID,
      i_CUSTOMER_ID: data.CUSTOMER_ID,
      i_UPLOAD_DATE: data.UPLOAD_DATE,
      i_START_DATE: data.START_DATE,
      i_END_DATE: data.END_DATE,
      i_HORIZON_SCAN: data.HORIZON_SCAN,
      i_ACTIVE: data.ACTIVE,
      i_REGULATION_STATUS: data.REGULATION_STATUS,
      i_REGULATION_TYPE: data.REGULATION_TYPE,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error) {
          return error;
        }
      }
    );
};

const getDocumentsForRegulation = (REGULATION_ID, CUSTOMER_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetDocumentsForRegulation", {
      i_REGULATION_ID: REGULATION_ID,
      i_CUSTOMER_ID: CUSTOMER_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error) {
          return error;
        }
      }
    );
};

const getSummaryDocumentsForRegulation = (REGULATION_ID, CUSTOMER_ID) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/GetSummaryDocumentsForRegulation", {
      i_REGULATION_ID: REGULATION_ID,
      i_CUSTOMER_ID: CUSTOMER_ID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error) {
          return error;
        }
      }
    );
};

const updateRegulationDocumentSummaryLevel = (
  customerID,
  regulationID,
  oldDocument,
  newDocument
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateRegulationDocumentSummaryLevel", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_OLD_DOCUMENT_RECOMMENDATION: oldDocument,
      i_NEW_DOCUMENT_RECOMMENDATION: newDocument,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          return response;
        }
      },
      (error) => {
        if (error) {
          return error;
        }
      }
    );
};

const updateRegulationDocumentControlLevel = (
  customerID,
  regulationID,
  oldDocument,
  sentenceDetails,
  newDocument,
  oldEvidenceTypeID,
  newEvidenceTypeID
) => {
  return apiService
    .apiCall("dbapi")
    .send("/v1/api/proc/DRegulativDB/UpdateRegulationDocumentControlLevel", {
      i_CUSTOMER_ID: customerID,
      i_REGULATION_ID: regulationID,
      i_OLD_DOCUMENT_RECOMMENDATION: oldDocument,
      i_SENTENCE_DETAILS: sentenceDetails,
      i_NEW_DOCUMENT_RECOMMENDATION: newDocument,
      i_OLD_EVIDENCE_TYPE_ID: oldEvidenceTypeID,
      i_NEW_EVIDENCE_TYPE_ID: newEvidenceTypeID,
    })
    .then(apiService.handleResponse)
    .then(
      (response) => {
        if (response) {
          response["status"] = 200;
          return response;
        }
      },
      (error) => {
        if (error) {
          return error;
        }
      }
    );
};

export default {
  getAvailableRegulators,
  getRegulatorDetails,
  getRegulatorKeyPersons,
  getRegulatoryDomains,
  getRegulatoryStatusAndType,
  getResponseType,
  getCountryDetails,
  updateRegulator,
  putRegulator,
  updateRegulatorKeyPerson,
  addRegulatorKeyPerson,
  getRegulationsForRequlator,
  putRegulationInformation,
  updateRegulationInformation,
  getDocumentsForRegulation,
  getSummaryDocumentsForRegulation,
  updateRegulationDocumentControlLevel,
  updateRegulationDocumentSummaryLevel,
};
