import React from "react";
import PropTypes from "prop-types";
import { ListItemIcon, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import langConstant from "_lang";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ChatIcon from "@mui/icons-material/Chat";
import useStyles from "./styles";
import { downloadFile } from "_helpers/utils";

const ActionMenu = ({
  anchorMenuEl,
  handleMenuClose,
  openMenu,
  selectedPolicy,
  updatePolicyMetaData,
  handleArchiveChange,
  checkChunkingAvailability,
  setPolicyPreview,
}) => {
  const classes = useStyles();

  const navigationDetails = useSelector(
    (state) => state.authentication.detailedInfo.NAVIGATION_DETAILS
  );
  const isInsightAvailable = navigationDetails?.some(
    (el) => el.ENTITLEMENT_NAME === "POLICY_MANAGEMENT/POLICY_INSIGHT"
  );

  let folderData = [];
  if (selectedPolicy && selectedPolicy.STORAGE_FOLDER_URL) {
    folderData = selectedPolicy.STORAGE_FOLDER_URL?.split("/");
  }

  const docStorageIP = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_IP
  );
  const docStorageURL = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_URL
  );
  const docStorageType = useSelector(
    (state) => state.authentication?.detailedInfo?.DOC_STORAGE_TYPE
  );

  return (
    <Menu
      anchorEl={anchorMenuEl}
      id="account-menu"
      open={openMenu}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuList
        style={{
          textAlign: "center",
          justifyContent: "space-between",
          display: "flex",
          padding: "0px 0px",
          outline: "0",
        }}
        className={classes.contentContexMenu}
      >
        <Tooltip title={langConstant.EXTRACTION_PARAMETER}>
          <MenuItem>
            <ListItemIcon onClick={() => updatePolicyMetaData(selectedPolicy)}>
              <Box
                className={classes.actionWrap}
                style={{
                  backgroundColor: "#c13636",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon className={classes.actionButton} />
              </Box>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
        {selectedPolicy.EXTRACTION_FILE_NAME !== null &&
        selectedPolicy.EXTRACTION_FILE_NAME !== "" &&
        folderData.length >= 3 ? (
          <Tooltip title={selectedPolicy.EXTRACTION_FILE_NAME}>
            <MenuItem>
              <ListItemIcon>
                <Box
                  className={classes.actionWrap}
                  style={{
                    backgroundColor: "#007bff",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    downloadFile(
                      selectedPolicy.STORAGE_FOLDER_URL,
                      selectedPolicy.EXTRACTION_FILE_NAME,
                      docStorageType,
                      docStorageIP,
                      docStorageURL
                    );
                  }}
                >
                  <DescriptionIcon className={classes.actionButton} />
                </Box>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        ) : (
          <Tooltip title={langConstant.NOT_AVAILABLE}>
            <MenuItem>
              <ListItemIcon>
                <Box
                  className={classes.actionWrap}
                  style={{
                    backgroundColor: "#757575",
                    marginRight: "5px",
                    cursor: "not-allowed",
                  }}
                >
                  <DescriptionIcon className={classes.actionButton} />
                </Box>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )}
        {selectedPolicy.POLICY_FILE_NAME !== null && folderData.length >= 3 ? (
          <Tooltip title={selectedPolicy.POLICY_FILE_NAME}>
            <MenuItem>
              <ListItemIcon>
                <Box
                  className={classes.actionWrap}
                  style={{
                    backgroundColor: "#53c167",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    downloadFile(
                      selectedPolicy.STORAGE_FOLDER_URL,
                      selectedPolicy.POLICY_FILE_NAME,
                      docStorageType,
                      docStorageIP,
                      docStorageURL
                    );
                  }}
                >
                  <CloudDownloadIcon className={classes.actionButton} />
                </Box>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        ) : (
          <Tooltip title={langConstant.NOT_AVAILABLE}>
            <MenuItem>
              <ListItemIcon>
                <Box
                  className={classes.actionWrap}
                  style={{
                    backgroundColor: "#757575",
                    marginRight: "5px",
                    cursor: "not-allowed",
                  }}
                >
                  <CloudDownloadIcon className={classes.actionButton} />
                </Box>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )}
        {isInsightAvailable &&
        selectedPolicy.Extraction_Status === "success" ? (
          <Tooltip title={langConstant.POLICY_INSIGHTS}>
            <MenuItem>
              <ListItemIcon>
                <Link
                  rel="noreferrer"
                  target="_blank"
                  style={{ textDecoration: "none", color: "blue" }}
                  to={{
                    pathname: `policy-explorer/insights/${selectedPolicy.POLICY_ID}`,
                  }}
                >
                  <Box
                    className={classes.actionWrap}
                    style={{
                      backgroundColor: "#007bff",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <BarChartIcon className={classes.actionButton} />
                  </Box>
                </Link>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        ) : (
          <Tooltip title={langConstant.POLICY_INSIGHTS}>
            <MenuItem>
              <ListItemIcon>
                <Box
                  className={classes.actionWrap}
                  style={{
                    backgroundColor: "#757575",
                    marginRight: "5px",
                    cursor: "not-allowed",
                  }}
                >
                  <BarChartIcon className={classes.actionButton} />
                </Box>
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )}
        <Tooltip
          title={
            selectedPolicy.ARCHIVED
              ? langConstant.UNARCHIVED
              : langConstant.ARCHIVED
          }
        >
          <MenuItem>
            <ListItemIcon>
              <Box
                className={classes.actionWrap}
                style={{
                  backgroundColor: "#d48d31",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                {selectedPolicy.ARCHIVED ? (
                  <UnarchiveIcon
                    className={classes.actionButton}
                    onClick={() =>
                      handleArchiveChange(false, selectedPolicy.POLICY_ID)
                    }
                  />
                ) : (
                  <ArchiveIcon
                    className={classes.actionButton}
                    onClick={() =>
                      handleArchiveChange(true, selectedPolicy.POLICY_ID)
                    }
                  />
                )}
              </Box>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
        <Tooltip title={langConstant.CHAT}>
          <MenuItem onClick={() => checkChunkingAvailability()}>
            <ListItemIcon>
              <Box
                className={classes.actionWrap}
                style={{
                  backgroundColor: "#124571",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                <ChatIcon className={classes.actionButton} />
              </Box>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
        <Tooltip title={langConstant.POLICY_PREVIEW}>
          <MenuItem>
            <ListItemIcon
              onClick={() =>
                setPolicyPreview({ isOpen: true, data: selectedPolicy })
              }
            >
              <Box
                className={classes.actionWrap}
                style={{
                  backgroundColor: "#3c0292",
                  marginRight: "5px",
                  cursor: "pointer",
                }}
              >
                <RemoveRedEyeIcon className={classes.actionButton} />
              </Box>
            </ListItemIcon>
          </MenuItem>
        </Tooltip>
      </MenuList>
    </Menu>
  );
};

ActionMenu.propTypes = {
  anchorMenuEl: PropTypes.any,
  handleMenuClose: PropTypes.func,
  openMenu: PropTypes.bool,
  selectedPolicy: PropTypes.object,
  updatePolicyMetaData: PropTypes.func,
  handleArchiveChange: PropTypes.func,
  checkChunkingAvailability: PropTypes.func,
  setPolicyPreview: PropTypes.func,
};

export default ActionMenu;
