/*COPYRIGHT. REGULATIV.AI LIMITED - 2021. ALL RIGHTS RESERVED. 

This software is only to be used for the purpose for which it has been
provided. No part of it is to be reproduced, disassembled, transmitted,
stored in a retrieval system nor translated in any human or computer
language in any way or for any other purposes whatsoever without the
written consent of REGULATIV.AI LIMITED. 
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Grid,
  TextField,
  Box,
  InputAdornment,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import langConstant from "_lang";
import AppPagination from "../../../../_components/AppPagination";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import DialogBox from "_components/DialogBox";
import RfiFilterTable from "_components/RfiFilterTable";
import DraftsOutlinedIcon from "@material-ui/icons/DraftsOutlined";
import EnhancedEncryptionOutlinedIcon from "@mui/icons-material/EnhancedEncryptionOutlined";
import { useDispatch, useSelector } from "react-redux";
import initiateAssessmentAction from "../InitiateAssessment/actions";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import FilterDropdown from "../../../../_components/FilterDropdown";
import _ from "lodash";
import sessionService from "_services/session.service";
import OpenCalendarPopup from "_components/OpenCalendarPopup/OpenCalendarPopup";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AuditDialog from "./AuditDialog";
import SelectPageRange from "_components/SelectPageRange";
import { scrollToTop } from "_helpers/utils";
import { getThemeDetails } from "containers/LoginPage/reducers";
import { ActionIcon } from "_components/ActionIcon";
import { ActionMenu } from "_components/ActionMenu";
import { DashboardCustomize } from "@mui/icons-material";


const useStyles = makeStyles(() => ({
  stickyColl: {
    position: "sticky",
    right: 0,
  },
  actionWrap: {
    borderRadius: "50%",
    padding: "4px",
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
  },
  actionIcon: {},
}));

const AppDataTable = (props) => {
  const themeDetails = useSelector(getThemeDetails);
  const userID = sessionService.getUserId();
  const classes = useStyles();
  const rows = props.rows;
  const columns = props.columns;
  const [page, setPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [auditDialog, setAuditDialog] = useState(false);
  const [submissionId, setSubmissionId] = useState(null)
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const openMenu = Boolean(anchorMenuEl);
  const calculatePageCount = (rows) => {
    return rows.length > 0
      ? Math.round(Math.ceil(rows.length / rowsPerPage))
      : 0;
  };

  const onActionClick = (event, data, path, disableLink) => {
    event?.preventDefault();
    setSelectedRow({data, path, disableLink});
    setAnchorMenuEl(openMenu ? null : event.currentTarget);
  };

  const goBackOnError = () => { };
  const rfiColumns = [
    { field: "REGULATORS_QUESTION_REF", headerName: "QUESTION REF.", minWidth: 135 },
    { field: "Status_label", headerName: "STATUS" },
    { field: "RFI_DESCRIPTION", headerName: "COVER NOTE", minWidth: 350 },
    { field: "RFI_DUE_DATE", headerName: "DUE DATE", format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""), minWidth: 120 },
  ];

  const rfiRows = useSelector((state) => state.initiateRfiDetails.rfiItems);

  const toggleDialogFalse = () => {
    setDialogOpen(false);
  };
  const toggleDialogTrue = (id, SUBMISSION_ID) => {
    dispatch(initiateAssessmentAction.getAssessmentById(id, null, goBackOnError, SUBMISSION_ID, userID));
    setDialogOpen(true);
  };

  const [pageCount, setPageCount] = useState(calculatePageCount(rows));

  const handleChangePage = (event) => {
    setPage(event);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [filteredRows, setFilteredRows] = useState([]);
  const initialFilterObj = { ASSIGNED_BY: "", STATUS: "", DOMAIN_NAME: '' };
  const [filterBy, setFilterBy] = useState(initialFilterObj);
  const [searchText, setSearchText] = useState("");
  const userRole = sessionService.getUserRole();
  const userRoleId = sessionService.getUserRoleId();
  const onFilterChange = (fieldName, e) => {
    const filterValue = e.target.value;

    const filterObj = { ...filterBy, [fieldName]: filterValue };

    setSearchText("");
    setFilterBy(filterObj);

    let filtered_array = rows.filter((item) => {
      if (filterObj["ASSIGNED_BY"] && filterObj["STATUS"] && filterObj['DOMAIN_NAME']) {
        return (
          filterObj["ASSIGNED_BY"] == item["ASSIGNED_BY"] &&
          filterObj["STATUS"] == item["STATUS"] &&
          filterObj['DOMAIN_NAME'] == item["DOMAIN_NAME"]
        );
      } else if (filterObj["ASSIGNED_BY"] && filterObj["STATUS"]) {
        return (filterObj["ASSIGNED_BY"] == item["ASSIGNED_BY"] &&
          filterObj["STATUS"] == item["STATUS"]);
      } else if (filterObj["ASSIGNED_BY"] && filterObj["DOMAIN_NAME"]) {
        return (filterObj["ASSIGNED_BY"] == item["ASSIGNED_BY"] &&
          filterObj["DOMAIN_NAME"] == item["DOMAIN_NAME"]);
      } else if (filterObj["STATUS"] && filterObj["DOMAIN_NAME"]) {
        return (filterObj["STATUS"] == item["STATUS"] &&
          filterObj["DOMAIN_NAME"] == item["DOMAIN_NAME"]);
      } else if (filterObj["ASSIGNED_BY"]) {
        return filterObj["ASSIGNED_BY"] == item["ASSIGNED_BY"];
      } else if (filterObj["STATUS"]) {
        return filterObj["STATUS"] == item["STATUS"];
      } else if (filterObj["DOMAIN_NAME"]) {
        return filterObj["DOMAIN_NAME"] == item["DOMAIN_NAME"];
      } else {
        return true;
      }
    });

    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };
  const resetFilter = () => {
    setFilterBy(initialFilterObj);
    setSearchText("");
    setFilteredRows(rows);
    setPageCount(calculatePageCount(rows));
    setPage(0);
  };
  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
    setFilterBy({ ASSIGNED_BY: "", STATUS: "", DOMAIN_NAME: '' });
    const filtered_array = rows.filter((row) => {
      return row.REGULATION_NAME.toLowerCase().includes(
        searchValue.toLowerCase()
      );
    });
    setFilteredRows(filtered_array);
    setPageCount(calculatePageCount(filtered_array));
    setPage(0);
  };

  useEffect(() => {
    const updateRows =
      rows.length > 0
        ? rows.map((item) => {
          const path =
            userRole == "CISO Analyst" ||
              [
                "SUBMITTED_TO_REGULATOR",
                "PENDING_FOR_REVIEW",
                "FINAL_REVIEW",
              ].indexOf(item.STANDARD_STATUS) == -1
              ? "/my-assessments/"
              : "/manage-assignments/";
          let data = item;
          data["Status_label"] = item.STATUS ? (
            <span className={item.STANDARD_STATUS + " status-badge"}>
              {item.STATUS}
            </span>
          ) : (
            item.STATUS
          );

          const disableLink =
            ([
              "SUBMITTED_TO_REGULATOR",
              "PENDING_FOR_REVIEW",
              "FINAL_REVIEW",
            ].indexOf(item.STANDARD_STATUS) > -1 &&
              userRoleId == 200) ||
              (["RETURNED"].indexOf(item.STANDARD_STATUS) > -1 &&
                userRoleId == 201)
              ? true
              : false;

          data["action"] = <ActionIcon onClick={(e) => onActionClick(e, item, path, disableLink)} />

          return data;
        })
        : [];

    setFilteredRows(updateRows);
    setPageCount(calculatePageCount(updateRows));
  }, [rows]);

  const createFilterDropdown = (fieldName, label) => {
    let options = _.reject(_.uniq(_.map(rows, fieldName)).sort(), _.isNull);

    if (options.length > 0 && options[0]) {
      options = options.map((item) => {
        return { value: item, name: item };
      });
    }
    if (options.length > 0) {
      return (
        <FilterDropdown
          options={options}
          label={label}
          fieldName={fieldName}
          value={filterBy[fieldName]}
          onChange={onFilterChange}
          icon={true}
        />
      );
    }
  };

  const handleSelectPageRange = (range) => {
    setRowsPerPage(range.value)
    const totalPageCount = filteredRows.length > 0 ? Math.round(Math.ceil(filteredRows.length / range.value)): 0
    if(page >= totalPageCount){
      setPage(totalPageCount - 1)
    }
    setPageCount(totalPageCount);
    scrollToTop()
  }

  return (
    <div>
      {openMenu && (
        <ActionMenu
          anchorMenuEl={anchorMenuEl}
          onClose={onActionClick}
          isOpen={openMenu}
          menuItems={[
            {
              tooltipLabel: langConstant.VIEW,
              icon: VisibilityIcon,
              isDisabled: selectedRow?.disableLink,
              linkTo: {
                pathname: selectedRow?.path?.includes("manage-assignments")
                  ? selectedRow?.path + selectedRow?.data?.REGULATION_ID
                  : selectedRow?.path +
                    selectedRow?.data?.REGULATION_ID +
                    "/" +
                    selectedRow?.data?.SUBMISSION_ID,
                state: {
                  submission_id: selectedRow?.data?.SUBMISSION_ID,
                  AssessmentName:
                    selectedRow?.data?.REGULATION_SHORT_NAME +
                    (selectedRow?.path?.LEGAL_ENTITY_NAME
                      ? " - " + selectedRow?.path?.LEGAL_ENTITY_NAME
                      : ""),
                },
              },
            },
            {
              tooltipLabel: "Check RFI",
              icon: DraftsOutlinedIcon,
              onClick: () =>
                toggleDialogTrue(
                  selectedRow?.data?.REGULATION_ID,
                  selectedRow?.data?.SUBMISSION_ID
                ),
              color: "#0e9439",
              isDisabled: selectedRow?.data?.RFI != "TRUE",
            },
            {
              tooltipLabel: langConstant.VIEW_EVIDENCE_LOCKER,
              icon: EnhancedEncryptionOutlinedIcon,
              linkTo: `my-assessments/view-all-evidence/${selectedRow?.data?.REGULATION_ID}/${selectedRow?.data?.SUBMISSION_ID}`,
              color: "#C13636",
            },
            {
              component: (
                <OpenCalendarPopup
                  submissionId={selectedRow?.data?.SUBMISSION_ID}
                  width="28px"
                  height="28px"
                />
              ),
            },
            {
              tooltipLabel: "Audit Information",
              icon: VerifiedUserIcon,
              onClick: () => {
                setSubmissionId(selectedRow?.data?.SUBMISSION_ID);
                setAuditDialog(true);
              },
              color: "#0e9439",
            },
            {
              tooltipLabel: "Dashboard",
              icon: DashboardCustomize,
              linkTo: `dashboard-view/${selectedRow?.data?.SUBMISSION_ID}/${selectedRow?.data?.REGULATION_ID}`,
              color: "orange",
            },
          ]}
        />
      )}
      <DialogBox
        maxWidth={"lg"}
        title={"RFI Details"}
        open={dialogOpen}
        handleClose={toggleDialogFalse}
      >
        {rfiRows && (
          <RfiFilterTable
            rows={rfiRows}
            columns={rfiColumns}
            className="data-table"
          />
        )}
      </DialogBox>
      <Box mb={2} mt={3}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} md={10}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  id="searchText"
                  label=""
                  name="searchText"
                  placeholder="Filter Table"
                  value={searchText}
                  onChange={(e) => onSearch(e)}
                  InputProps={{
                    autoComplete: "off",
                    startAdornment: (
                      <InputAdornment position="start">
                        {" "}
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                {createFilterDropdown("DOMAIN_NAME", "Domain Name")}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("ASSIGNED_BY", "Assigned By")}
              </Grid>
              <Grid item xs={12} md={2}>
                {createFilterDropdown("STATUS", "Status")}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box mx={1}>
              {(searchText ||
                filterBy["DOMAIN_NAME"] ||
                filterBy["ASSIGNED_BY"] ||
                filterBy["STATUS"]) && (
                <span onClick={resetFilter} className="linkIcon">
                  Reset All
                </span>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper}>
        <Table className={props.className} aria-label="Regulation List">
          <TableHead>
            <TableRow>
              {columns.map((item, index) => (
                <TableCell
                  component="th"
                  scope="row"
                  key={"column_" + index + item.field}
                  className={item.sticky && classes.stickyColl}
                  style={{ minWidth: item.minWidth }}
                >
                  {item.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row, index) => (
              <TableRow key={index + row[columns[0].field]}>
                {columns.map((column, index) => {
                  //const Comp = column.component && column.component
                  const value = row[column.field];
                  return (
                    <TableCell
                      component="td"
                      scope="row"
                      key={"column_" + index + row[columns[0].field]}
                      className={column.sticky && classes.stickyColl}
                    >
                      {column.format ? column.format(value, row) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}

            {filteredRows.length == 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell
                  style={{ textAlign: "center" }}
                  colSpan={columns.length}
                >
                  No record found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={2}>
        <Grid container justify="flex-end">
          {filteredRows.length > 5 && (
            <SelectPageRange
              selectedRange={rowsPerPage}
              handleChange={handleSelectPageRange}
            />
          )}
          {pageCount > 1 && (
            <AppPagination
              colSpan={3}
              count={pageCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Box>
      {auditDialog && submissionId && (
        <AuditDialog
          show={auditDialog}
          setShow={setAuditDialog}
          submissionId={submissionId}
        />
      )}
    </div>
  );
};

AppDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default AppDataTable;
