import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button, Box, TextField, makeStyles, Tooltip, IconButton, Divider } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import StorageIcon from "@material-ui/icons/Storage";
import moment from "moment";

import langConstant from "_lang";
import SelectAnswer from "../SelectAnswer";
import RfiFilterTable from "_components/RfiFilterTable";
import FilterByStatus from "_components/FilterByStatusTab";
import CommentsComponent from "_components/CommentsComponent";
import AssessmentNavigator from "../AssessmentNavigator";
import constants from "../../constants";
import DialogBox from "_components/DialogBox";
import AttachEvidence from "_components/AttachEvidence/index";
import EvidenceLocker from "_components/EvidenceLocker/index";
import OpenCalendarPopup from "_components/OpenCalendarPopup/OpenCalendarPopup";
import ProgressBarLarge from "_components/ProgressBarLarge";
import { getCommentsList } from "_components/MasterAssessmentComp/utils/actions";
import CommentPanel from "_components/MasterAssessmentComp/component/CommentPanel";
import SearchBar from "_components/MasterAssessmentComp/component/SearchBar";

const useStyles = makeStyles(() => ({
  drawerPaper: {
    overflow: "visible",
  },
  marginTop: {
    marginTop: "10px"
  },
}));


const ReviewAssessmet = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {questionList, completedPercentage, page, setPage, setPageCount} = props
  const rfiRows = useSelector((state) => state.vendorRiskManagementRfiDetails.rfiItems);
  const commentsList = useSelector((state) => state.MasterAssessment.AssessmentComments);

  questionList?.length &&
    questionList.forEach((question, index) => {
      return (question.PAGE_NUMBER = index);
    });

  // const [page, setPage] = useState(0);

  const [dialogOpen, setDialogOpen] = useState(false);

  let rfiDisabled = true;
  if (rfiRows && rfiRows.length != 0) {
    rfiDisabled = false;
  } else {
    rfiDisabled = true;
  }

  const initialTabs = [
    { name: langConstant.ALL, count: 0 },
    { name: langConstant.SENT_BACK, count: 0 },
    { name: langConstant.SUBMITTED, count: 0 },
    { name: langConstant.ACCEPTED, count: 0 },
  ];

  const [drawerState, setDrawerState] = useState(false);
  const [tabs, setTabs] = useState(initialTabs);
  const [selectedTab, setSelectedTab] = useState({ name: "ALL", id: 0 });
  const [selectedList, setSelectedList] = useState(questionList || []);
  const [searchList, setSearchList] = useState([]);
  // const [pageCount, setPageCount] = useState(selectedList && selectedList.length);

  const columns = [
    {
      field: "REGULATORS_QUESTION_REF",
      headerName: "QUESTION REF.",
      minWidth: 135,
    },
    { field: "Status_label", headerName: "STATUS" },
    {
      field: "RFI_DESCRIPTION",
      headerName: "COVER NOTE",
      minWidth: 350,
    },
    {
      field: "RFI_DUE_DATE",
      headerName: "DUE DATE",
      format: (value) => (value ? moment(value).format("DD-MMM-YY") : ""),
      minWidth: 120,
    },
  ];

  // const onChangePage = (value) => {
  //   setPage(value);
  // };

  useEffect(() => {
    let tempList = [];

   if( selectedList) 
   { selectedList.forEach((question) => {
      let newQuestion = Object.assign({}, question);
      newQuestion.search = question.question;
      tempList.push(newQuestion);
    });

    selectedList.forEach((question) => {
      let idQuestion = Object.assign({}, question);
      idQuestion.search = question.refId;
      tempList.push(idQuestion);
    });}

    setSearchList(tempList);
  }, [selectedList]);

  const onSearch = (e, obj) => {
    if (obj && obj.id) {
      const index = _.findIndex(selectedList, (e) => {
        return e.id == obj.id;
      });
      setPage(index);
    }
  };

  // * Fires when tabs are changed
  const onChange = (e, newValue) => {
    switch (newValue) {
      case 1:
        setSelectedTab({ name: "SENT_BACK", id: 1 });
        break;
      case 2:
        setSelectedTab({ name: "SUBMITTED", id: 2 });
        break;
      case 3:
        setSelectedTab({ name: "ACCEPTED", id: 3 });
        break;
      default:
        setSelectedTab({ name: "ALL", id: 0 });
    }
    setPage(0);
  };

  // * Fires when tab or question list changes
  const changeSelectedList = (filteredList) => {
    if (selectedTab.name == "ALL") {
      setSelectedList(questionList?.length && questionList);
      setPageCount(questionList?.length && questionList.length);
    } else {
      setSelectedList(filteredList?.length && filteredList);
      setPageCount(filteredList?.length && filteredList.length);
    }
  };

  //* This changes filtered list of questions
  useEffect(() => {
   if(questionList?.length){
      let filteredList = questionList.filter((question) => {
      return question.QUESTION_STATUS == selectedTab.name;
    });
    changeSelectedList(filteredList);}
  }, [selectedTab, questionList]);

  //* This changes count of count of tabs
  useEffect(() => {
    const filterTabs = _.cloneDeep(initialTabs);
    if (questionList?.length) {
      const SENT_BACK = _.filter(questionList, { QUESTION_STATUS: "SENT_BACK" });
      const SUBMITTED = _.filter(questionList, { QUESTION_STATUS: "SUBMITTED" });
      const ACCEPTED = _.filter(questionList, {
        QUESTION_STATUS: "ACCEPTED",
      });
      filterTabs[0].count = questionList.length;
      filterTabs[1].count = SENT_BACK.length;
      filterTabs[2].count = SUBMITTED.length;
      filterTabs[3].count = ACCEPTED.length;
    }

    var matched = filterTabs.filter(
      (e) => _.filter(tabs, { name: e.name, count: e.count }).length == 0
    );

    if (matched.length > 0) {
      setTabs(filterTabs);
    }
  }, [questionList]);

  useEffect(() => {
    if(selectedList.length > 0){
      dispatch(getCommentsList(selectedList[page]?.QUESTION_ID, selectedList[page]?.SUBMISSION_ID, true));
    }
  },[page, selectedList])

  //* Adjusts current tab when RFI is cancelled
  const onRfiCancellation = () => {
    if (selectedTab.name != "ALL") {
      if (selectedList.length > 1) {
        if (page > 0) {
          setPage(page - 1);
          // Moves to previous tab
        }
      } else if (selectedList.length == 1) {
        setSelectedTab({ name: "ALL", id: 0 });
        //  Changes tab to all if there are no questions in a tab
      }
    } else {
      // Nothing
    }
  };

  const updateAssesmentRemarksCount = (QUESTION_ID) => {
    dispatch({
      type: constants.UPDATE_REMARK,
      data: QUESTION_ID,
    });
  };

  // * Toggles the RFI Filter Dialog box
  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  // * Toggles Assessment Navigator on the right
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(open);
  };

  // * Navigates to page and closes assessment navigator
  const setPageNumber = (pageNumber) => {
    setDrawerState(false);
    setSelectedTab({ name: "ALL", id: 0 });
    setPage(pageNumber);
  };

  return (
    <>
      <Box>
        <Button
          onClick={toggleDrawer(true)}
          style={{
            position: "fixed",
            right: "0",
            top: "50vh",
            backgroundColor: "rgba(240, 20, 47, 1)",
            color: "white",
            borderRadius: "6px 0px 0px 6px",
            zIndex: "100",
            minWidth: "50px ",
          }}
        >
          <ExploreOutlinedIcon />
        </Button>
        <Drawer
          anchor={"right"}
          open={drawerState}
          onClose={toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AssessmentNavigator
            questionList={questionList}
            onClose={() => setDrawerState(false)}
            setPageNumber={(pageNumber) => {
              setPageNumber(pageNumber);
            }}
            style={{ position: "relative", overflow: "visible" }}
          />
        </Drawer>
      </Box>
      

      {/* Icons and pagination  */}

      <Box style={{ height: "80vh", display: "flex", justifyContent: "space-between" }} >
        <Box style={{ width: "72%" }}>
          {/* <Box display="flex" justifyContent="space-between">
            <Pagination
              shape="rounded"
              siblingCount={1}
              className="custom-pagination"
              color="primary"
              count={pageCount}
              page={page + 1}
              showFirstButton={false}
              showLastButton={false}
              onChange={(event, value) => {
                onChangePage(value - 1);
              }}
            />
          </Box> */}

          {/* search question input field  */}

          {/* <Box my={1}>
            <Autocomplete
              id="combo-box-demo"
              size="small"
              options={searchList}
              getOptionLabel={(option) => option.search}
              onChange={(e, newValue) => {
                onSearch(e, newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Questions"
                  label=""
                  variant="outlined"
                />
              )}
            />
          </Box> */}
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <FilterByStatus
              selectedTab={selectedTab}
              onChange={onChange}
              tabs={tabs}
            />
            <SearchBar QuestionList={selectedList} setPage={setPage} />
          </Box>
          {selectedList?.length && selectedList[page] != null ? (
            <SelectAnswer
              questionData={selectedList[page]}
              onRfiCancellation={onRfiCancellation}
            />
          ) : (
            ""
          )}
        </Box>
        <Divider style={{ height: "calc(100vh - 197px)" }} orientation="vertical" flexItem />
        <Box style={{ width: "25%" }}>
            {/* comment section*/}
            <Box className={classes.marginTop}>
              <ProgressBarLarge percentage={completedPercentage} size="small" />
            </Box>
            <Box display="flex" alignItems="center" className={classes.marginTop}>
              <Box ml={1}>
                <Tooltip title={langConstant.RFI_FILTER} placement="top">
                  <IconButton
                    style={{
                      backgroundColor: "#007BFF",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      color: "white",
                      fontSize: "18px",
                    }}
                    onClick={toggleDialog}
                    disabled={rfiDisabled}
                  >
                    <FilterListIcon color="inherit" fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
              <DialogBox
                maxWidth={"lg"}
                title={langConstant.RFI_DETAILS}
                open={dialogOpen}
                handleClose={toggleDialog}
              >
                <RfiFilterTable
                  rows={rfiRows}
                  columns={columns}
                  className="data-table"
                />
              </DialogBox>
              <Box ml={1}>
                <Tooltip title={langConstant.DATA_TRACE} placement="top">
                  <IconButton
                    style={{
                      backgroundColor: "#007BFF",
                      borderRadius: "50%",
                      width: "25px",
                      height: "25px",
                      color: "white",
                      fontSize: "18px",
                    }}
                  >
                    <StorageIcon color="inherit" fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
              <AttachEvidence
                questionId={
                  selectedList?.length && selectedList[page] ? selectedList[page].QUESTION_ID : ""
                }
                submissionId={
                  selectedList?.length && selectedList[page] ? selectedList[page].SUBMISSION_ID : ""
                }
              />
              <EvidenceLocker
                questionId={
                  selectedList?.length && selectedList[page] ? selectedList[page].QUESTION_ID : ""
                }
                submissionId={
                  selectedList?.length && selectedList[page] ? selectedList[page].SUBMISSION_ID : ""
                }
              />
              <OpenCalendarPopup submissionId={
              selectedList?.length && selectedList[page] ? selectedList[page].SUBMISSION_ID : ""
              } width='25px' height='25px' />
            </Box>
            <Box style={{ height: "calc(100vh - 304px)" }} className={classes.marginTop}>
              <CommentPanel commentsList={commentsList} submissionID={selectedList[page]?.SUBMISSION_ID} questionID={selectedList[page]?.QUESTION_ID} page={page} />
            </Box>
            {/* {selectedList?.length && selectedList[page] != null ? (
              <CommentsComponent
                direction={"column"}
                question_ID={selectedList[page].QUESTION_ID}
                submission_ID={selectedList[page].SUBMISSION_ID}
                remarksCount={() =>
                  updateAssesmentRemarksCount(selectedList[page].QUESTION_ID)
                }
              />
            ) : (
              ""
            )} */}
        </Box>
      </Box>

      <Box mt={1}>
        
      </Box>
    </>
  );
};

ReviewAssessmet.propTypes = {
  questionList: PropTypes.array,
  completedPercentage: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  setPageCount: PropTypes.func
};
export default ReviewAssessmet;
